// React
import React from 'react';

// Styles
import styles from './ErrorPage.module.scss';

const ErrorPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.container__title}>404</div>
      <div className={styles.container__subtitle}>opps! page not found</div>
      <div className={styles.container__message}>
        Something went wrong, the page you're looking for doesn't exist.
      </div>
    </div>
  );
};

export default ErrorPage;
