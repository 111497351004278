import React from 'react';
import { makeStyles } from '@material-ui/core';

const Step1Style = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  text: {
    fontSize: '1.15rem',
  },
  image: {
    width: 100,
    height: 100,
    background: `url('/assets/treedom/plant.png') no-repeat center`,
    backgroundSize: 'contain',
  },
}));

export default function Step1() {
  const classes = Step1Style();
  return (
    <div className={classes.root}>
      <div className={classes.text}>
        <div>HEY! I'M YOUR TREE!</div>
        <div>WILL YOU TAKE CARE OF ME?</div>
      </div>
      <div className={classes.image}></div>
    </div>
  );
}
