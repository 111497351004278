import React, { useEffect, useState } from 'react';
import '../../Navbar.scss';

const SingleTransaction = (props) => {

    const [sign, setSign] = useState(null);
    const [iconPath, setIconPath] = useState(null);
    const [recipient, setRecipient] = useState(null);
    const [dropdown, setDropdown] = useState(false);
    const [description, setDescription] = useState(null);
    const [date, setDate] = useState(null);
    const [isRotated, setIsRotated] = useState(false);

    const showDropdown = () => {
        setIsRotated(!isRotated);
        setDropdown(!dropdown)
    }

    useEffect(() => {
        if(props.accountOwner === props.tranOwner){
            setSign('-');
            setRecipient(props.reciverName);
            setIconPath(`/assets/miniPicks/arrow-down-left.png`)
        } else {
            setSign('+');
            setRecipient(props.tranOwnerName);
            setIconPath(`/assets/miniPicks/arrow-up-right.png`)
        }
        const dateObject = new Date(props.date);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1;
        const day = dateObject.getDate();
        const resultString = year + "-" + month + "-" + day;
        setDate(resultString);
        setDescription(props.description);
    }, [props]);

    return (
        <>
        <div className='picks-tran-container'>
            <div className='picks-tran-icon'>
                <img
                    src={iconPath} 
                    alt="Arrow Left Down"
                />
            </div>
            <label className='picks-tran-owner'>{recipient}</label>
            <span>
                <label style={{ fontWeight: 'bold'}}> {sign+props.picks} </label>
                <label> PCK </label>
            </span>
            <img
                src={`/assets/miniPicks/vector.png`} 
                alt="Vector"
                style={{ cursor: 'pointer' }}
                className={isRotated ? 'rotate-90' : ''}
                onClick={showDropdown}
            />
        </div>
        <div className='picks-tran-info'>
            {dropdown && (
                <>
                    <div className="picks-tran-line">
                        <label>
                            Date:
                        </label>
                        <span className="">{date}</span>
                    </div>
                    <div className="picks-tran-line">
                        <label>
                            Description:
                        </label>
                        <span className="">{description}</span>
                    </div>
                    <hr className="" />
                </>
            )}
        </div> </>
    );
};

export default SingleTransaction;