import React from 'react';
import { AppBar, CssBaseline, Toolbar, Typography } from '@material-ui/core';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { TabMobileDashboard, DrawerItems } from './components';
import {
  DashboardLandingPage,
  DoodleArea,
  LeftMenuArea,
  ToolArea,
  TreedomArea,
} from './pages/index';
import App from '../../App';
import { AccountMenu } from '../../components/Navbar/components';
import { useStyles } from './style';

function DashboardHomeClann({ keycloak, savatarURL }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap className={classes.appBarTypography}>
            Dashboard
          </Typography>
          <AccountMenu
            edge="end"
            fullKeycloak={keycloak}
            savatarURL={savatarURL}
            isDashboard={true}
          />
        </Toolbar>
      </AppBar>

      <BrowserRouter>
        <DrawerItems />
        <main className={classes.content}>
          <div className={classes.toolbar} />

          <Switch>
            <Route exact path="/" render={() => <App />} />
            <Route exact path="/dashboard" render={() => <DashboardLandingPage />} />
            <Route exact path="/dashboard/doodle" render={() => <DoodleArea />} />
            <Route path="/dashboard/left-menu" render={() => <LeftMenuArea />} />
            <Route path="/dashboard/tools" render={() => <ToolArea />} />
            <Route path="/dashboard/treedom" render={() => <TreedomArea />} />
          </Switch>
        </main>
        <TabMobileDashboard />
      </BrowserRouter>
    </div>
  );
}

export default DashboardHomeClann;
