// React
import React from 'react';

// Material UI
import { createTheme, ThemeProvider, FormControl, Select, MenuItem } from '@material-ui/core';

// Components
import CategoryItem from '../../Categories/components/CategoryItem';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Utils
import { DEFAULT_CATEGORY, setCategory  } from '../../../../../../../store/suggestEventSlice';

const CategoriesPicker = () => {
  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          cursor: 'pointer',
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
      },
      MuiSelect: {
        icon: {
          top: 'calc(50% - 17px)',
        },
        root: {
          color: '#5A6972',
        },
        select: {
          paddingRight: '40px !important',
          marginLeft: '39px',
          marginBottom: '15px',
          padding: '13px 16px',
          '@media (max-width:940px)': {
            marginLeft: '22px',
          },
        },
      },
    },
  });

  const category = useSelector((state) => state.suggestEvent.category);
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.calendar.categories);

  return (
    <ThemeProvider theme={theme}>
      <FormControl style={{ width: 'fit-content' }}>
        <Select
          defaultValue={DEFAULT_CATEGORY}
          value={category}
          onChange={(e) => dispatch(setCategory(e.target.value))}
          IconComponent={ExpandMoreIcon}
        >
          <MenuItem value={DEFAULT_CATEGORY} key={DEFAULT_CATEGORY}>
            <span>{DEFAULT_CATEGORY}*</span>
          </MenuItem>
          {categories.map((c) => (
            <MenuItem value={c.calendarName} key={c.calendarName}>
              <CategoryItem category={c.calendarName} color={c.color}></CategoryItem>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

export default CategoriesPicker;
