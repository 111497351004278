// React
import React from 'react';

// Material UI
import { Box, alpha } from '@material-ui/core';

// Styles
import styles from './CategoryItem.module.scss';

const CategoryItem = ({ category, color }) => {
  return (
    <Box className={styles.container}>
      <div className={styles.container__cue} style={{ backgroundColor: alpha(color, 0.9) }}></div>
      {category}
    </Box>
  );
};

export default CategoryItem;
