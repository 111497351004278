import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Toolbar, IconButton, Typography } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { adminAllDoodleAPI } from '../../../../apis/DoodleAPI';
import AppLoader from '../../../../components/AppLoader';
import { DashboardPlaceholder } from '../../components';
import DoodleCard from './DoodleCard';
import DoodleHistory from './DoodleHistory';
import useStyles from './DoodleAreaStyle.js';
import {UseCustomSnackbar} from '../../../../utils/CustomSnackbar';

class DoodleModel {
  id = Date.now();
  title = '';
  url = '';
  tooltip = '';
  publicationDate = new Date().toISOString();
  desktopImage = null;
  mobileImage = null;
  dedicatedForNewUser = false;
  isNew = true;
}

export default function DoodleArea() {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const uiClasses = useStyles();

  // Load the list data
  const loadList = async (enqueueSnackbar, closeSnackbar) => {
    setLoading(true);

    try {
      const response = await adminAllDoodleAPI();
      setList(response.data);
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }

    setLoading(false);
  };

  useEffect(() => {
    loadList(enqueueSnackbar, closeSnackbar);
  }, [enqueueSnackbar, closeSnackbar]); 

  // Handle the new element adding
  const onAdd = () => {
    const cloned = [ ...list ];
    cloned.push(new DoodleModel());
    setList(cloned);
  };

  // Handle new item removing after the cancel action
  const onRemoveUnsaved = id => {
    const filtered = list.filter(item => item.id !== id);
    setList(filtered);
  };

  const cardList = list.filter(d => !d.deleted).map((item, i) => <DoodleCard
    key={item.id}
    doodle={item}
    counter={i + 1}
    onReload={() => loadList(enqueueSnackbar)}
    onRemoveUnsaved={() => onRemoveUnsaved(item.id)}
  />);

  const doodleHistory = list.filter(d => d.deleted);

  return (
    <div className={uiClasses.root}>
      <Toolbar variant="dense" className={uiClasses.toolbar}>
        <Typography variant="h5" className={uiClasses.title}>
          Doodle List
        </Typography>

        <IconButton onClick={onAdd}>
          <AddIcon />
        </IconButton>
      </Toolbar>

      { cardList.length ? cardList : <DashboardPlaceholder /> }

      <Typography variant="h6" className={uiClasses.title}>
        History
      </Typography>

      { doodleHistory.length ? <DoodleHistory doodleHistory={doodleHistory} /> : <DashboardPlaceholder /> + '&' }

      {loading && <AppLoader />}
    </div>
  );

}
