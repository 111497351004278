import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 300,
    paddingBottom: 50,
    position: 'relative',
  },

  toolbar: {
    marginBottom: 20,
  },

  title: {
    flexGrow: 1,
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  autocomplete: {
    marginTop: 20,
    marginBottom: 20,
    width: 300,
  }
}));

export default useStyles;