import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './DashboardCardActionsStyle.js';

export default function DashboardCardActions(props) {
  const uiClasses = useStyles();

  const buttons = props.editMode
    ? <>
      <IconButton
        disabled={!props.isFormValid}
        onClick={() => props.onSave()}
      >
        <CheckIcon />
      </IconButton>

      <IconButton
        onClick={() => props.onCancel()}
      >
        <CloseIcon />
      </IconButton>
    </> : <>
      <IconButton
        onClick={() => props.onEdit()}
      >
        <EditIcon />
      </IconButton>

      <IconButton
        onClick={() => props.onDelete()}
      >
        <DeleteIcon />
      </IconButton>
    </>

  return (
    <div className={uiClasses.root}>
      {buttons}
    </div>
  );
};
