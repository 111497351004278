import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Icon } from '@material-ui/core';
import '../Navbar.scss';

const AccountMenuItems = ({ title, icon, url, username }) => {
  let urlMod;
  url?.search('USERNAME') > 0 ? (urlMod = url.replace('USERNAME', username)) : (urlMod = url);

  return (
    <ListItem button component="a" href={urlMod} target="_blank">
      <ListItemIcon>
        <Icon>{icon} </Icon>
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
};

export default AccountMenuItems;
