import React, { useEffect, useState } from 'react';
import '../../Navbar.scss';

const SingleInvestment = (props) => {

    const [amount, setAmount] = useState(null);
    const [recipient, setRecipient] = useState(null);

    useEffect(() => {
        setAmount(props.amount);
        setRecipient(props.recipient);
    }, [props]);

    return (
        <div className="picks-invest-container">
            <label className='picks-invest-line'>
                <span>
                    {recipient}
                </span>
                <span>
                    <h3 className='picks-invest-amount'>{amount}</h3>
                    PCK
                </span>
            </label>
        </div>
    );
};

export default SingleInvestment;