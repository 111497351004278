import React from 'react';
import { useStyles } from "../style";

const DashboardLandingPage = () => {
  const classes = useStyles();

  return <div className={classes.welcome}>
  <h1>Welcome to the dashboard</h1>
</div>;
};

export default DashboardLandingPage;