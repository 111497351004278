import React, { useState, useEffect } from 'react';
import '../../Navbar.scss';
import PicksInvestments from './PicksInvestments';
import PicksTransactions from './PicksTransactions';
import PicksDisinvest from './PicksDisinvest';
import PicksSettings from './PicksSettings';
import PicksConvert from './PicksConvert';
import { BottomNavigation, BottomNavigationAction, styled, SvgIcon } from '@material-ui/core';

import { BarChart2 } from './icons/BarChart2';
import { TrendingUp } from '@material-ui/icons';
import { TrendingDown } from '@material-ui/icons';
import { Settings } from '@material-ui/icons';
import { PresentToAll } from '@material-ui/icons';
import { Present } from './icons/Present';

const CustomBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  backgroundColor: 'transparent',
}));

const CustomBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
  padding: '6px 4px 8px',
  minWidth: 'unset',
  '&.Mui-selected': {
    color: '#6e3dc9',
  },
}));

const PicksActivated = ({ yourBalance, updateBalance }) => {
  const [balance, setBalance] = useState(yourBalance);
  const [selection, setSelection] = useState(0);

  useEffect(() => {
    setBalance(yourBalance);
  }, [yourBalance]);

  const renderSection = () => {
    switch (selection) {
      case 0:
        return balance && <PicksTransactions yourBalance={balance} />;
      case 1:
        return balance && <PicksInvestments yourBalance={balance} />;
      case 2:
        return balance && <PicksDisinvest yourBalance={balance} updateBalance={updateBalance} />;
      case 3:
        return balance && <PicksConvert yourBalance={balance} updateBalance={updateBalance} />;
      case 4:
        return <PicksSettings transactionSelected={() => setSelection(4)} />;
      default:
        return <div> Error </div>;
    }
  };

  const sectionTitles = ['Transactions', 'Investments', 'Divest', 'Convert', 'Settings'];

  return (
    <>
      <div className="grid grid-rows-[40px_1fr_56px] h-full grid-cols-[350px]">
        <h3>{sectionTitles[selection]}</h3>
        {renderSection(selection)}

        <CustomBottomNavigation
          showLabels
          value={selection}
          onChange={(event, newValue) => {
            setSelection(newValue);
          }}
        >
          <CustomBottomNavigationAction label="Transactions" icon={<BarChart2 />} />
          <CustomBottomNavigationAction label="Investments" icon={<TrendingUp />} />
          <CustomBottomNavigationAction label="Divest" icon={<TrendingDown />} />
          <CustomBottomNavigationAction label="Convert" icon={<Present></Present>} />
          <CustomBottomNavigationAction label="Settings" icon={<Settings></Settings>} />
        </CustomBottomNavigation>
      </div>
    </>
  );
};

export default PicksActivated;
