// React
import React from 'react';

// Components
import SuggestEventFooter from './SuggestEventFooter';

// Styles
import styles from './ConfirmDialog.module.scss';

const ConfirmDialog = ({ suggestEvent, closeConfirmDialog }) => {
  return (
    <div className={styles.container}>
      <img src="/assets/calendar/confirm_img_1.png" alt="" className={styles.container__img}></img>
      <div className={styles.container__text}>
        <p>Are you sure?</p>
      </div>
      <SuggestEventFooter
        cancelFn={() => {
          setTimeout(() => closeConfirmDialog(), 500);
        }}
        event={suggestEvent}
      ></SuggestEventFooter>
    </div>
  );
};

export default ConfirmDialog;
