import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import TextsmsIcon from "@material-ui/icons/Textsms";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import useStyles from "./DoodleHistoryStyle.js";

export default function DoodleHistory(props) {
  const uiClasses = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <colgroup>
          <col width="50%" />
          <col width="20%" />
          <col width="20%" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell align="center">Elimination Date</TableCell>
            <TableCell align="center">Tooltip and Images</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.doodleHistory.map((doodle) => (
            <TableRow key={doodle.id}>
              <TableCell>
                <a href={doodle.url} target="_blank" rel="noreferrer">
                  {doodle.title}
                </a>

                {doodle.dedicatedForNewUser && (
                  <Tooltip title="Dedicated to the new users">
                    <SupervisedUserCircleIcon className={uiClasses.newUsersIcon} />
                  </Tooltip>
                )}
              </TableCell>

              <TableCell align="center">
                {new Date(doodle.eliminationDate).toLocaleDateString()}
              </TableCell>

              <TableCell align="center" className={uiClasses.iconColumn}>
                <Tooltip title={doodle.tooltip}>
                  <TextsmsIcon color="primary" className={uiClasses.icon} />
                </Tooltip>

                <a
                  className={uiClasses.icon}
                  href={doodle.desktopImage}
                  target="_blank"
                  rel="noreferrer"
                >
                  <DesktopMacIcon />
                </a>

                <a
                  className={uiClasses.icon}
                  href={doodle.mobileImage}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SmartphoneIcon />
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
