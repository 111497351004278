import { AxiosError } from 'axios';
import { picksAxios as axios } from '../utils/axiosInstances';

export function getTransaction(accountId) {
  return axios.get(`api/transaction/${accountId}`, {
    params: {
      pageSize: 200,
    },
  });
}

export function getInvestments(accountId) {
  return axios.get(`api/transaction/invested/${accountId}`);
}

export function getPicks() {
  return axios.get(`accounts/picks`);
}

export function addAccount(passphrase) {
  const data = {
    passphrase: passphrase,
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return axios.post(`accounts`, data, config);
}

export function accountReset(passphrase) {
  const data = {
    passphrase: passphrase,
  };
  return axios.post(`accounts/reset`, data);
}

export function getDivestments(accountId) {
  console.log(`retrieve divestments for account ${accountId}`);
  return axios.get(`api/transaction/divested/${accountId}`).then((response) => response.data);
}

export function claimDivestment(ventureId, senderId, amount) {
  return axios
    .post(`api/transaction/divest`, {
      senderId,
      ventureId,
      amount,
    })
    .catch((error) => {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error('An error occurred while claiming divestment');
      }
    });
}

export function loadConvertibleSum(accountId) {
  return axios
    .get(`api/transaction/convertible/${accountId}`)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error('An error occurred while loading convertible picks');
      }
    });
}
