import { Typography } from "@material-ui/core";
import useStyles from './DashboardPlaceholderStyle.js';

export default function DashboardPlaceholder(props) {
  const uiClasses = useStyles();

  return (
    <Typography
      variant="body1"
      className={uiClasses.root}
    >
      {props.message || 'No content available'}
    </Typography>
  );
};
