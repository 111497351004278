export const ACCOUNT_MENU_ITEMS = [
  {
    title: 'Savatar',
    icon: 'party_mode',
    url: 'https://savatar.sorint.it/',
  }
];

export const SAVATAR = {
  title: 'Take a selfie',
  icon: 'party_mode',
  url: 'https://savatar.sorint.it/',
};