import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "../Navbar.scss";

function DesktopMenu({ classes, navItems }) {

  return (
    <div className={classes?.navbar} data-testid="desktop-menu">
      <ul>
        {navItems?.map((item) => {
          return <Item item={item} keys={[item.id]} key={`item-${item?.id}`} />
        })}
      </ul>
    </div>
  );
}

function Item({ item, keys }) {
  return (
    <li>
      <a href={item?.url}>
        {item?.title}
        {item.itemList?.length !== 0 && <ArrowDropDownIcon />}
      </a>
      {item.itemList?.length !== 0 && (
        <ul>
          {item.itemList?.map((item) => {
            return <Item item={item} keys={[...keys, item.id]} key={`subitem-${keys.join('-')}-${item?.id}`}></Item>
          })}
        </ul>
      )}
    </li>
  )
}

export default DesktopMenu;
