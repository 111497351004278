import { useState } from 'react';
import AddRoomPopUp from '../AddRoomPopUp';
import EditRoomPopUp from '../EditRoomPopUp';
import EditIcon from '@material-ui/icons/Edit';
import './showInRooms.scss';

export default function Room({ rooms, addRoom, deleteRoom, editRoom, roomNumber, roomIcon }) {
  const [addRoomPopUp, setAddRoomPopUp] = useState(false);
  const [editRoomPopUp, setEditRoomPopUp] = useState(false);

  if (rooms.myRooms) {
    const room = rooms.myRooms.filter((item) => {
      return item.number === roomNumber;
    });
    if (room.length !== 0) {
      return (
        <div className="roomContainer">
          <a id="roomButton" href={room[0].link} target="_blank" rel="noreferrer">
            <img src={roomIcon} alt="room-icon" width="60px" height="50px" />
            <div className="underline">
              <span>{room[0].name}</span>
            </div>
          </a>
          <EditIcon className="editIcon" fontSize="small" onClick={() => setEditRoomPopUp(true)} />
          <EditRoomPopUp
            editRoom={editRoom}
            deleteRoom={deleteRoom}
            setPopUp={() => setEditRoomPopUp(false)}
            room={room[0]}
            roomNumber={roomNumber}
            isOpen={editRoomPopUp}
          ></EditRoomPopUp>
        </div>
      );
    } else {
      return (
        <div className="roomContainer">
          <button id="addRoomButton" onClick={() => setAddRoomPopUp(true)}>
            <img src={roomIcon} alt="room-icon" width="60px" height="50px" />
            <span className="underline">+ Add a Room</span>
          </button>
          <AddRoomPopUp
            addRoom={addRoom}
            setPopUp={() => setAddRoomPopUp(false)}
            roomNumber={roomNumber}
            isOpen={addRoomPopUp}
          ></AddRoomPopUp>
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}
