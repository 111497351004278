// React
import React from 'react';

// Material UI
import { TextField, createTheme, ThemeProvider } from '@material-ui/core';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setTitle } from '../../../../../../../store/suggestEventSlice';

const TitleField = () => {
  const theme = createTheme({
    overrides: {
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: '1px solid #FB8D4B',
          },
          '&:after': {
            borderBottom: '1px solid #FB8D4B',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid #FB8D4B',
          },
        },
      },
      MuiFormHelperText: {
        root: {
          marginLeft: '10px',
          '&.Mui-error': {
            color: 'red',
          },
        },
      },
    },
  });
  const title = useSelector((state) => state.suggestEvent.title);
  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={theme}>
      <TextField
        inputProps={{ style: { fontSize: 32 } }}
        placeholder="Title*"
        value={title}
        onChange={(e) => dispatch(setTitle(e.target.value))}
      />
    </ThemeProvider>
  );
};

export default TitleField;
