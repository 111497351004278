import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },

  rootActive: {
    cursor: 'pointer',
    opacity: 1,
    transition: 'opacity 0.4s ease',

    '&:hover': {
      opacity: 0.4,
    },
  },

  preview: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
}));

export default useStyles;
