// React
import React, { Fragment } from 'react';

// Components
import TitleField from '../components/TitleField';
import TimeSection from './TimeSection';
import CategoriesPicker from '../components/CategoriesPicker';
import DetailsSection from './DetailsSection';

// Styles
import styles from './SuggestEventBody.module.scss';

const SuggestEventBody = () => {
  return (
    <Fragment>
      <TitleField></TitleField>
      <div className={styles.scrollable}>
        <CategoriesPicker></CategoriesPicker>
        <TimeSection></TimeSection>
        <DetailsSection></DetailsSection>
      </div>
    </Fragment>
  );
};

export default SuggestEventBody;
