import { useRef } from "react";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import useStyles from './ImageFieldStyle.js';

export default function ImageField(props) {
  const uiClasses = useStyles();
  const fileInputRef = useRef(null);
  let trigger;

  if (props.preview) {
    // Case with existent image
    trigger = <>
      <img
        className={uiClasses.preview}
        alt="Preview not available"
        src={props.preview}
      />
    </>
  } else if (props.disabled) {
    // Case with disabled field
    trigger = <WallpaperIcon />
  } else {
    // Case with active image selection
    trigger = <AddAPhotoIcon />
  }

  return (
    <div
      className={`${uiClasses.root} ${props.disabled ? '' : uiClasses.rootActive}`}
      onClick={() => fileInputRef.current.click()}
    >
      <input
        type="file"
        accept="image/*"
        hidden
        ref={fileInputRef}
        disabled={props.disabled}
        onChange={ev => props.onChange(ev.target.files[0])}
      />

      {trigger}
    </div>
  );
};