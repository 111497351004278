import { createSlice } from '@reduxjs/toolkit';

// Utils
import { getMonth, getYear, compareAsc, parseISO } from 'date-fns';

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    isCalendarVisible: false,
    categories: [],
    visibleCategories: [],
    events: [],
    agendaEvents: [],
    visibleEvents: [],
    selectedDate: new Date(new Date().setHours(0, 0, 0, 0)),
    showMoreOptions: false,
  },
  reducers: {
    setIsCalendarVisible: (state, action) => {
      state.isCalendarVisible = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = [...action.payload];
    },
    setVisibleCategories: (state, action) => {
      state.visibleCategories = [...action.payload];
    },
    setEvents: (state, action) => {
      state.events = action.payload;
      state.agendaEvents = action.payload.filter((event) => {
        event.startFormatted = new Date(parseISO(event.start));
        if (event.end) event.endFormatted = new Date(parseISO(event.end));
        else if (!event.end && !event.allDay) event.endFormatted = new Date(parseISO(event.start));

        if (
          state.selectedDate.getMonth() === new Date().getMonth() &&
          state.selectedDate.getYear() === new Date().getYear()
        )
          return (
            getMonth(state.selectedDate) === getMonth(event.startFormatted) &&
            getYear(state.selectedDate) === getYear(event.startFormatted) &&
            compareAsc(new Date(new Date().setHours(0, 0, 0, 0)), event.startFormatted) !== 1
          );
        else
          return (
            getMonth(state.selectedDate) === getMonth(event.startFormatted) &&
            getYear(state.selectedDate) === getYear(event.startFormatted)
          );
      });
      state.visibleEvents = state.agendaEvents.filter((event) => {
        event.startFormatted = new Date(parseISO(event.start));
        if (event.end) event.endFormatted = new Date(parseISO(event.end));
        else if (!event.end && !event.allDay) event.endFormatted = new Date(parseISO(event.start));

        return compareAsc(state.selectedDate, event.startFormatted) !== 1;
      });
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
      calendarSlice.caseReducers.setEvents(state, { payload: state.events });
    },
    setShowMoreOptions: (state, action) => {
      state.showMoreOptions = action.payload;
    },
  },
});

export const {
  setIsCalendarVisible,
  setCategories,
  setVisibleCategories,
  setEvents,
  setSelectedDate,
  setShowMoreOptions,
} = calendarSlice.actions;

export default calendarSlice.reducer;
