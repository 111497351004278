// React
import React from 'react';

// Material UI
import { createTheme, ThemeProvider, TextField, InputAdornment } from '@material-ui/core';

// Icons
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setDescription } from '../../../../../../../store/suggestEventSlice';

const DescriptionField = () => {
  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        inputMultiline: {
          cursor: 'pointer',
          padding: '11px 13px 11px 13px',
          borderRadius: '8px',
          marginLeft: '18px',
          border: '1px solid #E8EBED',
          '&:hover': {
            border: '1px solid #98A6AE',
          },
          '@media (max-width:940px)': {
            marginLeft: '0px !important',
            marginRight: '15px',
          },
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
      },
    },
  });

  const description = useSelector((state) => state.suggestEvent.description);
  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={theme}>
      <TextField
        placeholder="Add description"
        multiline
        minRows={2}
        maxRows={10}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FormatAlignCenterIcon htmlColor="#F17023" />
            </InputAdornment>
          ),
        }}
        value={description}
        onChange={(e) => dispatch(setDescription(e.target.value))}
      />
    </ThemeProvider>
  );
};

export default DescriptionField;
