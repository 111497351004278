// React
import React from 'react';

// Material UI
import { Button } from '@material-ui/core';

// Redux
import { useDispatch } from 'react-redux';
import { saveChanges } from '../../../../../../../store/customizeRepeatSlice';

const OkButton = () => {
  const dispatch = useDispatch();

  return (
    <Button
      disableElevation
      disableRipple
      variant="contained"
      style={{
        textTransform: 'none',
        color: 'white',
        backgroundColor: '#F17023',
        borderRadius: '24px',
        height: '52px',
        width: '80px',
        padding: '12px 16px',
        fontWeight: 700,
      }}
      onClick={() => dispatch(saveChanges())}
    >
      Ok
    </Button>
  );
};

export default OkButton;
