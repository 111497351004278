import { clannAxios as axios } from '../utils/axiosInstances';

export function getCategoriesAPI() {
  return axios.get('events/calendars');
}

export function getEventsAPI(categories) {
  return axios.get(`events?params=${categories}`);
}

export function suggestEventAPI(data) {
  return axios.post('events', data);
}

export function addEventAPI(data) {
  return axios.post('events/addEvent', data);
}

export function getEventAPI(token, eventID) {
  return axios.get(`events/event?token=${token}&eventID=${eventID}`);
}

export function acceptEventAPI(data) {
  return axios.post('events/acceptEvent', data);
}

export function declineEventAPI(data) {
  return axios.post('events/declineEvent', data);
}
