import React from "react";
import { Link } from "react-router-dom";
import { Icon, BottomNavigation } from "@material-ui/core";
import { dashboardItems } from "../costants";
import { useStyles, BottomNavigationAction} from "../style";
import HomeIcon from "@material-ui/icons/Home";


export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  return (
    <div className={classes.dashboardNavTab}>
      <BottomNavigation
        value={value}
        onChange={(e, newValue) => setValue(newValue)}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction
            component={Link}
            to={"/"}
            label="Home"
            icon={<HomeIcon />}
          />
        {dashboardItems.map((item) => (
          <BottomNavigationAction
            key={item.url}
            component={Link}
            to={"/dashboard/" + item.url}
            label={item.title}
            icon={<Icon>{item.icon} </Icon>}
            style = {{borderLeft: '1px solid black',}}
          />
        ))}
      </BottomNavigation>
    </div>
    
  );
}
