import React, { useState } from 'react';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';

const Step2Style = makeStyles((theme) => ({
  root: {
    width: 400,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 5,
  },
  item1: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
}));

export default function Step2({ treeCode }) {
  const classes = Step2Style();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCodeCopy = () => {
    navigator.clipboard.writeText(treeCode);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 1000);
  };

  return (
    <div className={classes.root}>
      <div className={classes.item1}>
        1) Copy your Treecode <span>{treeCode}</span>{' '}
        <Tooltip
          title="Code copied!"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          open={tooltipOpen}
          arrow
          placement="top"
        >
          <FileCopyOutlinedIcon style={{ cursor: 'pointer' }} onClick={handleCodeCopy} />
        </Tooltip>
      </div>
      <div>
        2) Open Treedom website:{' '}
        <a href="https://www.treedom.net/" target="_blank" rel="noreferrer">
          https://www.treedom.net/
        </a>
      </div>
      <div>3) Enter the Treecode to redeem your tree</div>
    </div>
  );
}
