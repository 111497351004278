import Room from './Room.js';
import './showInRooms.scss';

export default function ShowInRooms(props) {
  const rooms = [];
  const icons = [
    'assonometria_1.png',
    'assonometria_2.png',
    'assonometria_3.png',
    'assonometria_4.png',
  ];

  for (let i = 1; i < 5; i++) {
    rooms.push(
      <div className="room" key={i}>
        <Room {...props} roomNumber={i} roomIcon={'/assets/showinRooms/' + icons[i - 1]} />
      </div>
    );
  }

  return (
    <div id="roomsContainer">
      <div className="roomsList">{rooms}</div>
    </div>
  );
}
