import React, { useState } from 'react';
import '../../Navbar.scss';
import PicksWelcome from './PicksWelcome';
import PicksPin from './PicksPin';

const PicksLogIn = ({picksActivated, updateBalance}) => {
    const [isWelcome, setIsWelcome] = useState(true);

    const refreshBalance = () => {
        updateBalance();
    }

    const changeSelected = (data) => {
        if(data === false){
            setIsWelcome(data);
        }else{
            picksActivated();
        }
    }
    return (
        <>
            { isWelcome ? (
                <PicksWelcome changeSelected={changeSelected} />
            ) : (
                <PicksPin changeSelected={changeSelected} updateBalance={refreshBalance} />
            )}
        </>
    );
};

export default PicksLogIn;