import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: '#ffb15d',
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '0 15px',

    [theme.breakpoints.up("md")]: {
      flexDirection: 'column',
    },
  },
}));

export default useStyles;
