import { clannAxios as axios } from '../utils/axiosInstances';

export function getRoomsAPI() {
  return axios.get('showin-rooms');
}

export function addRoomAPI(data) {
  return axios.post('showin-rooms', data);
}

export function deleteRoomAPI(id) {
  return axios.delete(`showin-rooms/${id}`);
}

export function editRoomAPI(id, data) {
  return axios.put(`showin-rooms/${id}`, data);
}
