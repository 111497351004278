import React, { Component } from 'react';
import Keycloak from 'keycloak-js';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import './App.scss';
import DashboardHomeClann from './pages/Dashboard/Dashboard';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Homepage from './pages/homepage/Homepage';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { initialize, updateToken } from './utils/axiosInstances';
import AcceptDeclineCalendarEvent from './pages/Scripts/Calendar/AcceptDeclineCalendarEvent';

const THEME = createTheme({
  typography: {
    fontFamily: `'Quicksand', sans-serif`,
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keycloak: null,
      searchURL: null,
      savatarURL: null,
      authenticated: false,
      userInfo: null,
      isAdmin: null,
      preferredTools: [],
    };
  }

  async componentDidMount() {
    try {
      // Load the environment data
      const environment = (await axios.get('/config/environment.json', { baseURL: '' })).data;

      // Setup the keycloak data
      const keycloak = Keycloak(environment.keycloak);
      const authenticated = await keycloak.init({
        onLoad: 'login-required',
        checkLoginIframe: false,
      });

      setInterval(() => {
        keycloak
          .updateToken(90)
          .then((refreshed) => {
            if (refreshed) {
              updateState();
              localStorage.setItem('token', keycloak.token);
            }
          })
          .catch(() => {
            console.log('Failed to refresh token!');
          });
      }, 60000);

      const updateState = async () => {
        const info = await keycloak.loadUserInfo();
        this.setState({ keycloak, userInfo: info });
      };

      await initialize(keycloak.token);
      updateToken();

      const searchURL = environment.searchUrl;
      const savatarURL = environment.savatarUrl;
      const SRAMUrl = environment.SRAMUrl;

      // Load the keycloak user details
      const userInfo = await keycloak.loadUserInfo();
      // If is an external user, redirect to SRAM
      if (userInfo.email.includes('external')) {
        window.location.replace(SRAMUrl);
      }
      // Trigger the highlands user sync and set if it is admin
      const isAdmin = userInfo?.groups?.includes('highlands-admins');

      // Set the state and start the application
      this.setState({
        keycloak,
        authenticated,
        userInfo,
        searchURL,
        savatarURL,
        isAdmin,
      });
    } catch (error) {
      this.props.enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
    }
  }

  render() {
    if (this.state.keycloak) {
      if (this.state.authenticated)
        return (
          <MuiThemeProvider theme={THEME}>
            <Router>
              <Switch>
                <Route exact path="/">
                  <Homepage
                    userInfo={this.state.userInfo}
                    keycloak={this.state.keycloak}
                    isAdmin={this.state.isAdmin}
                    searchURL={this.state.searchURL}
                    savatarURL={this.state.savatarURL}
                  />
                </Route>
                {this.state.isAdmin && (
                  <Route path="/dashboard">
                    <DashboardHomeClann
                      keycloak={this.state.keycloak}
                      savatarURL={this.state.savatarURL}
                    />
                  </Route>
                )}
                <Route
                  path="/scripts/calendar/:token/:eventID"
                  render={() => <AcceptDeclineCalendarEvent></AcceptDeclineCalendarEvent>}
                />
                ;
                <Route path="/*" render={() => <Redirect to={{ pathname: '/' }} />} />
              </Switch>
            </Router>
          </MuiThemeProvider>
        );
      else return <div>Unable to authenticate!</div>;
    }
    return <div></div>;
  }
}

export default withSnackbar(App);
