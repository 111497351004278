import React, {useState} from 'react';
import '../../Navbar.scss';
import { addAccount } from '../../../../apis/PicksAPI';
import { useSnackbar } from 'notistack';
import { UseCustomSnackbar } from '../../../../utils/CustomSnackbar';

const PicksPin = ({changeSelected, updateBalance}) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);

    const refreshBalance = () => {
        updateBalance();
    }

    const testRegex = (passphrase) => {
        const regex = /^\d{6}$/;
        return regex.test(passphrase);
    }

    const setBtnStatus = (passphrase) => {
        setIsBtnDisabled(!testRegex(passphrase));
    }

    const enrollUser = async () => {
        try {
            let passphrase = document.getElementById('passphrase').value;
            if (testRegex(passphrase)) {
                setIsBtnDisabled(true)
                await addAccount(passphrase);
                refreshBalance();
                changeSelected(false);
            } else {
                setIsBtnDisabled(false)
                UseCustomSnackbar(enqueueSnackbar, closeSnackbar, 'The passphrase does not meet the specified criteria' , 'error');
            }
        }
        catch (error) {
            console.error("Error in user registration", error);
            UseCustomSnackbar(enqueueSnackbar, closeSnackbar, 'Error in user registration' , 'error');
        }
    }

    return (
        <div className="grid h-full grid-cols-[350px]">
            <h1>How to write a PIN</h1>
            <ul className='picks-list'>
                <li>
                    6 digits are required
                </li>
                <li>
                    Letters or special characters are not allowed
                </li>
                <li>
                    Choose something known only to you
                </li>
            </ul>
            <div className='picks-footer'>
                <div className='picks-pin-wrapper'>
                    <img 
                        className='picks-pin-icon'
                        src={`/assets/miniPicks/lock.png`} 
                        alt="Mini Picks Lock"
                    />
                    <div className='picks-pin-container'>
                        <label>Pin</label>
                        <input 
                            placeholder='******' 
                            id='passphrase' 
                            className='picks-pin-input' 
                            type='password'
                            onChange={ev => setBtnStatus(ev.target.value)}
                        />
                    </div>
                    <img
                        className='picks-pin-icon'
                        src={`/assets/miniPicks/x.png`} 
                        alt="Mini Picks X"
                    />
                </div>
                <button 
                    className={isBtnDisabled ? 'picks-btn-7' : 'picks-btn-2'} 
                    onClick={enrollUser} 
                    disabled={isBtnDisabled}
                >
                    Get Started!
                </button>
            </div>
        </div>
    );
};

export default PicksPin;