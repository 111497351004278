// React
import React from 'react';

// Material UI
import { Box, Tooltip, IconButton, createTheme, ThemeProvider } from '@material-ui/core';

// Icons
import { CalendarTodayOutlined } from '@material-ui/icons';

// API
import { addEventAPI } from '../../../../../../../apis/CalendarAPI';

// Utils
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { UseCustomSnackbar } from '../../../../../../../utils/CustomSnackbar';

// Styles
import styles from './Event.module.scss';

const Event = ({ color, event, openModal }) => {
  const themeEvent = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          background: '#7B878E !important',
        },
      },
    },
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const hours = event.allDay
    ? 'all day'
    : `${format(event.startFormatted, 'HH:mm')} - ${format(event.endFormatted, 'HH:mm')}`;

  const handleEventClick = () => {
    openModal();
  };

  const handleCalendarIconClick = async (e, event) => {
    e.stopPropagation();
    try {
      await addEventAPI({
        ...event,
        calendarName: event.calendar.calendarName,
      });
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, 'Succcess', 'success');
    } catch (_) {    
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, _, 'error');
    }
  };

  return (
    <Box
      className={styles.container}
      style={{
        cursor: 'pointer',
      }}
      onClick={handleEventClick}
    >
      {event.new && (
        <Box className={styles.container__new}>
          <Box className={styles.container__new__cues}></Box>
        </Box>
      )}
      <Box className={styles.container__left}>
        <Box className={styles.container__left__title}>{event.title}</Box>
        <Box className={styles.container__left__title__hours}>{hours}</Box>
      </Box>
      <Box className={styles.container__right}>
        <ThemeProvider theme={themeEvent}>
          <Tooltip title="Add to your calendar" placement="left">
            <IconButton onClick={(e) => handleCalendarIconClick(e, event)}>
              <CalendarTodayOutlined htmlColor="black"></CalendarTodayOutlined>
            </IconButton>
          </Tooltip>
        </ThemeProvider>
        <Box
          style={{
            backgroundColor: color,
            right: 0,
            height: '76px',
            width: '21px',
            borderRadius: '0px 12px 12px 0px',
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default Event;
