import { configureStore } from '@reduxjs/toolkit';
// reducers
import suggestEventReducer from './store/suggestEventSlice';
import customizeRepeatReducer from './store/customizeRepeatSlice';
import calendarReducer from './store/calendarSlice';

export default configureStore({
  reducer: {
    suggestEvent: suggestEventReducer,
    customizeRepeat: customizeRepeatReducer,
    calendar: calendarReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
