// React
import React, { useEffect, useState } from 'react';

// Material UI
import { IconButton, Badge, createTheme, ThemeProvider } from '@material-ui/core';
import { CalendarTodayOutlined } from '@material-ui/icons';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setIsCalendarVisible } from '../../../store/calendarSlice';

// APIs
import { getEventsAPI } from '../../../apis/CalendarAPI';

// Utils
import { getVisibleCategoriesFromCookies } from '../../../utils/calendarUtils';

const CalendarButton = () => {
  const theme = createTheme({
    overrides: {
      MuiBadge: {
        colorSecondary: {
          backgroundColor: '#F17023',
          fontSize: '11px',
          borderRadius: '50%',
          height: '18px',
          width: '18px',
        },
        anchorOriginTopRightCircular: {
          top: '72%',
          right: '25%',
        },
      },
      MuiSvgIcon: {
        root:{
        fontSize: '32px',
        }
      }
    },
  });

  const dispatch = useDispatch();
  const isCalendarVisible = useSelector((state) => state.calendar.isCalendarVisible);
  const [news, setNews] = useState(0);

  const loadNews = async () => {
    const visible_categories = getVisibleCategoriesFromCookies();
    if (visible_categories.length > 0) {
      try {
        const response = await getEventsAPI(visible_categories.join(','));
        setNews(response.data.filter((event) => event.new).length);
      }
      catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    loadNews();
  }, [isCalendarVisible]);

  const element = () => {
    return (
      <IconButton
        onClick={() => {
          dispatch(setIsCalendarVisible(!isCalendarVisible));
        }}
      >
        <CalendarTodayOutlined htmlColor="white"></CalendarTodayOutlined>
      </IconButton>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      {news > 0 ? (
        <Badge badgeContent={news} color="secondary" overlap="circular">
          {element()}
        </Badge>
      ) : (
        element()
      )}
    </ThemeProvider>
  );
};

export default CalendarButton;
