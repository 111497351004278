// React
import React from 'react';

// Material UI
import { Badge } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { grey, lightBlue } from '@material-ui/core/colors';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedDate } from '../../../../../store/calendarSlice';

// Utils
import DateFnsUtils from '@date-io/date-fns';
import { compareAsc } from 'date-fns';

const AgendaPicker = () => {
  const calendarTheme = createTheme({
    typography: {
      fontFamily: ['Quicksand', 'sans-serif'],
    },
    overrides: {
      MuiTypography: {
        h4: {
          fontSize: '2rem',
        },
        body1: {
          fontSize: '20px',
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          display: 'none',
        },
      },
      MuiPickersDay: {
        day: {
          color: grey['900'],
        },
        daySelected: {
          backgroundColor: '#ef6c00',
          '&:hover': {
            backgroundColor: '#ff9d4d',
          },
          '&>span p': {
            fontWeight: '700 !important',
          },
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: lightBlue['500'],
        },
      },
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          width: '100%',
          maxWidth: '350px',
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          maxWidth: 'none',
          minHeight: 'none',
          overflowY: 'hidden',
        },
      },
      MuiPickersCalendarHeader: {
        daysHeader: {
          justifyContent: 'space-between',
          '&>*:nth-child(1)': {
            color: '#FAB97A',
          },
        },
        dayLabel: {
          fontWeight: 700,
          color: '#C6CED2',
          fontSize: '16px',
        },
        iconButton: {
          padding: 0,
          '&>span svg path': {
            color: '#F17023',
          },
        },
        switchHeader: {
          marginTop: 'none',
          marginBottom: '24px',
          justifyContent: 'center',
          columnGap: '10px',
        },
        transitionContainer: {
          height: '30px',
          maxWidth: '160px',
          '&>p': {
            fontWeight: 'bold',
          },
        },
      },
      MuiPickersCalendar: {
        week: {
          justifyContent: 'space-between',
          '&>*:nth-child(1) span button span p': {
            color: '#FAB97A !important',
          },
          '&>div span button span p': {
            fontWeight: 500,
            fontSize: '16px',
          },
        },
      },
      MuiBadge: {
        colorSecondary: {
          backgroundColor: 'rgba(90, 105, 114, 0.5)',
        },
      },
    },
  });

  const dispatch = useDispatch();

  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const agendaEvents = useSelector((state) => state.calendar.agendaEvents);

  const changeDate = (value) => {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    if (compareAsc(today, value) !== 1) {
      dispatch(setSelectedDate(value));
    } else {
      dispatch(setSelectedDate(today));
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={calendarTheme}>
        <DatePicker
          variant="static"
          orientation="portrait"
          value={selectedDate}
          minDate={new Date()}
          onChange={(value) => changeDate(value)}
          onMonthChange={(value) => changeDate(value)}
          renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
            const isSelected =
              isInCurrentMonth &&
              agendaEvents.some((event) => event.startFormatted.getDate() === day.getDate());
            return (
              <Badge
                color="secondary"
                variant="dot"
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                invisible={!isSelected}
              >
                {dayComponent}
              </Badge>
            );
          }}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default AgendaPicker;
