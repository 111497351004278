import { getDivestments } from '@/apis/PicksAPI';
import { useEffect, useMemo, useState } from 'react';

const PicksDisinvestList = ({ picks, onDisinvest, yourBalance }) => {
  const [status, setStatus] = useState('loading');
  const [data, setData] = useState([]);

  const totalPicks = useMemo(() => {
    return data.reduce((acc, curr) => acc + curr.picks, 0);
  });

  useEffect(() => {
    const fetchDivestments = async () => {
      try {
        const divestments = await getDivestments(yourBalance.id);
        setStatus(divestments.length > 0 ? 'data' : 'no-data');
        setData(divestments);
      } catch (error) {
        console.error('Error while fetching divestments', error);
        setStatus('error');
      }
    };

    fetchDivestments();
  }, []);

  return (
    <div className="picks-divest-list-container text-left">
      {status === 'loading' ? <p className="mt-5">Loading...</p> : null}
      {status === 'no-data' ? (
        <p className="mt-5">No investments found... come back later!</p>
      ) : null}
      {status === 'error' ? <p className="mt-5">Something went wrong... try again later!</p> : null}
      {status === 'data' ? (
        <>
          <div className="picks-divest-total">
            Picks you can divest: <h2 className="picks-invest-amount">{totalPicks}</h2> PCK
          </div>
          <div className="picks-divest-list h-full">
            <h3>Withdraw an investment:</h3>
            <div className="flex flex-column w-full overflow-y-auto max-h-[290px] custom-scrollbar">
              <table className="w-full text-left mt-[5px]">
                <tbody>
                  {data.map((devestable, index) => (
                    <tr key={index}>
                      <td className="py-[5px]">{devestable.description}</td>
                      <td className="py-[5px] text-right">
                        <span className="picks-invest-amount h3">{devestable.picks}</span>
                        PCK
                      </td>
                      <td className="py-[5px]">
                        <button className="flex" onClick={() => onDisinvest(devestable.id)}>
                          <img
                            src="/assets/miniPicks/withdrawal-by-anggara.png"
                            alt="Withdrawal image by Anggara"
                            className="h-6 w-6"
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div>
              You will be able to withdraw your investment only after a minimum of 1 year from the
              date of the investment. 
            </div>
        </>
      ) : null}
    </div>
  );
};

export default PicksDisinvestList;
