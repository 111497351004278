import { getDivestments } from '@/apis/PicksAPI';
import { useEffect, useMemo, useState } from 'react';
import PicksDisinvestList from './PicksDisinvestList';
import { PicksDisinvestForm } from './PicksDisinvestForm';

const PicksDisinvest = ({ yourBalance, updateBalance }) => {
  const [view, setView] = useState('list');

  const [ventureId, setVentureId] = useState(null);

  
  const onDisinvest = async (id) => {
    console.log('Disinvesting', id);
    setVentureId(id);
    setView('form');
  }


  return (
    <>
      {view === 'list' ? (
        <PicksDisinvestList yourBalance={yourBalance} onDisinvest={onDisinvest} />
      ) : (
        <PicksDisinvestForm yourBalance={yourBalance} setView={setView} ventureId={ventureId} updateBalance={updateBalance} />
      )}
    </>
  );
};

export default PicksDisinvest;
