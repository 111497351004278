// React
import React from 'react';

// Material UI
import { Button } from '@material-ui/core';

// Icons
import { CalendarTodayOutlined } from '@material-ui/icons';

// APIs
import { addEventAPI } from '../../../../../../../apis/CalendarAPI';

// Utils
import { useSnackbar } from 'notistack';
import { UseCustomSnackbar } from '../../../../../../../utils/CustomSnackbar';

const AddToMyCalendarButton = ({ event }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClick = async () => {
    try {
      await addEventAPI({
        ...event,
        calendarName: event.calendar.calendarName,
      });
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, 'Succcess', 'success');
    } catch (_) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, _, 'error');
    }
  };
  return (
    <Button
      disableElevation
      disableRipple
      variant="contained"
      startIcon={<CalendarTodayOutlined style={{ width: '27px', height: '27px' }} />}
      style={{
        textTransform: 'none',
        color: 'white',
        backgroundColor: '#F17023',
        borderRadius: '24px',
        height: '52px',
        width: '244px',
        padding: '12px 16px',
        fontWeight: 700,
        fontSize: '16px',
      }}
      onClick={handleClick}
    >
      Add to my calendar
    </Button>
  );
};

export default AddToMyCalendarButton;
