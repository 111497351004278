import React from 'react';
import 'instantsearch.css/themes/algolia-min.css';
import {Fab} from '@material-ui/core';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import AddIcon from '@material-ui/icons/Add';
import {connectInfiniteHits} from 'react-instantsearch-dom';
import '../style.scss';
import {DocumentsDrive, FloatingPoint, Leanvas, Person, Project42, Sircle} from './index';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.min.css';

const Hits = ({hits, refineNext, hasMore, savatarURL}) => {
    const personHits = hits.filter((item) => item?.category === 'person');
    const otherHits = hits.filter((item) => item?.category !== 'person');

    const snippetHit = (hit) => {
        const hitJSON = JSON.stringify(hit);
        let hitCopy = JSON.parse(hitJSON);
        for (let prop in hitCopy._highlightResult) {
            if (Object.prototype.hasOwnProperty.call(hitCopy._highlightResult, prop)) {
                switch (prop) {
                    case 'details':
                    case 'customer_needs_problems':
                    case 'accountabilities':
                    case 'content':
                    case 'unique_value_proposition':
                        let i1 =
                            hitCopy._highlightResult[prop]?.value?.indexOf('<ais-highlight-0000000000>') || -1;
                        let i2 =
                            hitCopy._highlightResult[prop]?.value?.indexOf('</ais-highlight-0000000000>') || -1;
                        if (i1 === -1 && i2 === -1) {
                            i1 = 0;
                            i2 = 100;
                        } else {
                            i1 = i1 - 100 >= 0 ? i1 - 100 : 0;
                            i2 =
                                i2 + 100 >= hitCopy._highlightResult[prop]?.value?.length
                                    ? hitCopy._highlightResult[prop]?.value?.length + 1
                                    : i2 + 100;
                        }
                        let snippet = hitCopy._highlightResult[prop]?.value?.slice(i1, i2);
                        if (i1 > 0) snippet = '... '.concat(snippet);
                        if (i2 < hitCopy._highlightResult[prop]?.value?.length)
                            snippet = snippet.concat(' ...');
                        hitCopy._highlightResult[prop].value = snippet;
                        break;
                    default:
                        break;
                }
            }
        }
        return JSON.parse(JSON.stringify(hitCopy));
    };

    return (
        <>
            {personHits.length === 0 ? (
                <></>
            ) : (
                <Swiper
                    slidesPerView={1.5}
                    spaceBetween={20}
                    style={{padding: 20}}
                    freeMode={true}
                    breakpoints={{
                        0: {
                            slidesPerView: 1.5,
                        },
                        500: {
                            slidesPerView: 2.5,
                        },
                        800: {
                            slidesPerView: 3.5,
                        },
                        1400: {
                            slidesPerView: 4.5,
                        },
                        1600: {
                            slidesPerView: 5.5,
                        },
                    }}
                >
                    {personHits.map((hit) => (
                        <SwiperSlide key={hit.meili_id}>
                            <Person hit={hit} savatarURL={savatarURL}/>
                        </SwiperSlide>
                    ))}
                    <SwiperSlide style={{height: 290, display: 'flex'}}>
                        {hasMore ? (
                            <Fab color="primary" className="fabPerson" onClick={refineNext}>
                                <AddIcon/>
                            </Fab>
                        ) : (
                            <h4 className="textNoMoreResults">No more results to display</h4>
                        )}
                    </SwiperSlide>
                </Swiper>
            )}

            {otherHits.map((hit) => {
                switch (hit?.category) {
                    case 'sircles':
                        return <Sircle hit={snippetHit(hit)} key={hit.meili_id}/>;
                    case 'value-proposition':
                        return <Leanvas hit={snippetHit(hit)} key={hit.meili_id}/>;
                    case 'floatingpoint':
                        return <FloatingPoint hit={snippetHit(hit)} key={hit.meili_id}/>;
                    case 'project42':
                        return <Project42 hit={snippetHit(hit)} key={hit.meili_id}/>;
                    case 'documents':
                        return <DocumentsDrive hit={snippetHit(hit)} key={hit.meili_id}/>;
                    default:
                        return <div></div>;
                }
            })}
            {hasMore ? (
                <div style={{maxWidth: 'calc(100vw - 135px)'}}>
                    <button className="loadMoreButton" onClick={refineNext} disabled={!hasMore}>
                        Load more results
                    </button>
                </div>
            ) : (
                <h4 className="textNoMoreResults">No more results to display</h4>
            )}
            <Fab
                color="primary"
                className="fab"
                onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
            >
                <UpIcon/>
            </Fab>
        </>
    );
};

export default connectInfiniteHits(Hits);
