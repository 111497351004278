import { clannAxios as axios } from '../utils/axiosInstances';

export function homepageAllDoodleAPI() {
  return axios.get('doodle/all');
}

export function adminAllDoodleAPI() {
  return axios.get('doodle/admin/all');
}

export function addDoodleAPI(data) {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const body = new FormData();
  Object.keys(data).forEach((key) => body.append(key, data[key]));
  return axios.post('doodle', body, { headers });
}

export function updateDoodleAPI(data) {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const body = new FormData();
  Object.keys(data).forEach((key) => body.append(key, data[key]));
  return axios.put(`doodle/${data.id}`, body, { headers });
}

export function deleteDoodleAPI(data) {
  return axios.put(`doodle/delete/${data.id}`);
}
