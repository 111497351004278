import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export default function ConfirmDialog(props) {
  const { open, title, message, onClose, onConfirm } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle id="simple-dialog-title">
        { title || 'Confirm' }
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          { message || 'Do you confirm the operation?' }
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={onConfirm} color="primary" autoFocus>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
