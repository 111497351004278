import { makeStyles } from '@material-ui/core/styles';

const navbarStyle = makeStyles((theme) => ({
  grow: { flexGrow: 1, height: '70px' },
  appBar: {
    background: '#313539',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: { display: 'flex' },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: { display: 'none' },
  },
}));

const leftMenuStyle = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  imgSorintLeftMenu: {
    display: 'block',
    width: '40vw',
    margin: '1.5rem auto',
    maxwidth: '200px',
  },
  navbar: {
    textTransform: 'uppercase',

    '& > ul': {
      float: 'left',
      listStyle: 'none',
      position: 'relative',

      '& > li': {
        float: 'left',
        background: '#313539',
        margin: '0 5px',

        '&:hover': {
          '& > ul': {
            opacity: '1',
            visibility: 'visible',
            top: '70px',
          },
        },

        '& > a': {
          color: 'white',
          textDecoration: 'none',
          lineHeight: '70px',
          padding: '8px 10px 8px 15px',
          border: '1px solid transparent',
          borderRadius: '8px',

          '& > svg': {
            margin: '0 0 5px 5px',
            verticalAlign: 'middle',
          },

          '&:hover': {
            border: '1px solid #ffff',
          },
        },
      },

      '& ul': {
        position: 'absolute',
        top: '90px',
        opacity: '0',
        visibility: 'hidden',
        transition: 'all .3s ease',
        listStyle: 'none',

        '& > li': {
          position: 'relative',
          background: '#f5f5f5',
          margin: '0px',
          float: 'none',
          display: 'list-item',

          '&:hover': {
            '& > ul': {
              opacity: '1',
              visibility: 'visible',
              left: '100%',
            },
          },

          '& > a': {
            color: 'black',
            textDecoration: 'none',
            textTransform: 'capitalize',
            fontSize: '15px',
            lineHeight: '45px',
            padding: '8px 15px',

            '& > svg': {
              margin: '0 0 2px 5px',
              verticalAlign: 'middle',
              transform: 'rotate(270deg)',
            },
          },
        },

        '& ul': {
          top: '0',
          left: '105%',
          width: 'max-content',
          transition: 'all .3s ease',

          '& > li': {
            position: 'relative',
            float: 'none',
            margin: '0px',
            display: 'list-item',
          },
        },
      },
    },
  },
});

const accountMenuStyle = makeStyles((theme) => ({
  drawer: {
    width: 250,
  },
  drawerUnderAppBar: {
    zIndex: `${theme.zIndex.drawer - 150} !important`,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100%',
    maxwidth: 360,
  },
}));

const accountNotificationsStyle = makeStyles((theme) => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: { display: 'flex' },
  },
}));

const tooltipStyle = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: '#ff0000',
    top: '-7px',
  },
  customArrow: {
    color: '#ff0000',
    top: '-7px',
  },
}));

export { navbarStyle, leftMenuStyle, accountMenuStyle, accountNotificationsStyle, tooltipStyle };
