import React from 'react';
import { makeStyles } from '@material-ui/core';

const Step4Style = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: 5,
  },
  image: {
    height: 110,
  },
}));

export default function Step4({ treeName, treeCode, treeImg }) {
  const classes = Step4Style();
  return (
    <div className={classes.root}>
      <div>
        <div>My tree's name: {treeName}</div>
        <div>My Treecode: {treeCode}</div>
        <div>
          <a href="https://www.treedom.net/" target="_blank" rel="noreferrer">
          https://www.treedom.net/
          </a>
        </div>
      </div>
      <img src={`/assets/treedom/${treeImg}.png`} alt="My tree" className={classes.image} />
    </div>
  );
}
