import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxDoodleSearch: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: 52,
  },
  doodleImage: {
    height: 240,
    width: 360,
    [theme.breakpoints.down('xs')]: { height: 160 },
  }
}));

export { useStyles };
