// React
import React from 'react';

// Styles
import styles from './SuccessDialog.module.scss';

const SuccessDialog = () => {
  return (
    <div className={styles.container}>
      <img src="/assets/calendar/confirm_img_2.png" alt="" className={styles.container__img}></img>
      <div className={styles.container__text}>
        <p>Your request</p>
        <p>
          <b>has been submitted!</b>
        </p>
      </div>
    </div>
  );
};

export default SuccessDialog;
