import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core"
import "../components/ShowInRooms/showInRooms.scss";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

export default function EditRoomPopUp({editRoom, deleteRoom, setPopUp, room, roomNumber, isOpen}) {

  const [name, setName] = useState(room.name)
  const [url, setUrl] = useState(room.link)
  const orangeTheme = createTheme({ 
    palette: { primary: { main: '#ef6c00' } }, 
    typography: { fontFamily: `'Quicksand', sans-serif`} })

  function isFormInvalid() {
    return(
      name.trim().length === 0 || url.trim().length === 0
    )
  }

  return (
    <div className={`editRoomsContainer ${isOpen ? "popUpOpen" : "popUpClose"}`}>
      <div className="editRoomsPopUp">
      <MuiThemeProvider theme={orangeTheme}>
        <form onSubmit={(e) => { e.preventDefault(); editRoom(roomNumber, name, url); setPopUp() }}>
            <div className="input">
              <TextField id="outlined-basic" color="primary" value={name} label="Room Name" variant="outlined" onChange={(e) => setName(e.target.value)}/>
              <TextField id="outlined-basic" color="primary" value={url} label="Room URL" variant="outlined" onChange={(e) => setUrl(e.target.value)} />
            </div>
            <div className="buttons">
              <Button onClick={() => setPopUp()}>Close</Button>
              <Button color="primary" 
                      variant="contained" 
                      type="submit"
                      disabled={isFormInvalid()}>Edit</Button>
            </div>  
            <Button className="closeButton" 
                    onClick={() => { deleteRoom(roomNumber); setPopUp() }}>Clean room</Button> 
          </form>
      </MuiThemeProvider>
      </div>
      </div>
  ) 
}
