import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { getCheckAvailability } from '../../../../../../apis/AccountMenuAPI';

export default function ListWhitSubItem({ listName, items, setAvailability, username, setLoading, setError}) {
  const [open, setOpen] = React.useState(false);
  
  const checkAvailability = async (username) => {
    setOpen(!open);
    setLoading(true);
    setError('');
    if (!open && listName === "Today's Availability") {
      setAvailability({});
    }
    try {
      if (!open && listName === "Today's Availability") {
        await getCheckAvailability(username).then(function (data) {
          setAvailability(data.data);
        });
        setLoading(false);
      }
      else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  return (
    <>
      <ListItem
        button
        onClick={() => checkAvailability(username)}
        style={{ backgroundColor: open ? 'rgba(0, 0, 0, 0.08)' : 'rgba(0, 0, 0, 0)' }}
      >
        <ListItemText primary={listName} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)', padding: '10px 0' }}
      >
        <List component="div" disablePadding>
          {items.map((item, i) => (
            <ListItem key={`${listName}${item.name}`} style={{ flexDirection: 'column' }}>
              {item.name && <h5 style={{ width: '100%' }}>{item.name}:</h5>}
              <p
                style={{
                  fontSize: 14,
                  textTransform: 'lowercase',
                  width: '100%',
                  whiteSpace: 'pre-line',
                  maxHeight: '92px',
                  overflowY: 'auto',
                }}
              >
                {item.data}
              </p>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}
