import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { GridStack } from "gridstack";
import "gridstack/dist/gridstack.min.css";
import "gridstack/dist/gridstack-extra.css";
import "gridstack/dist/h5/gridstack-dd-native";
import "./SorintTools.scss";
import Grid2SwiperMode from "./components/Grid2SwiperMode";
import { insertItemsTools, setGridOptions, resizeGrid, saveUserListTools } from "./utils";
import { preferredToolsAPI } from "../../../../apis/ToolAPI";
import AppLoader from "../../../../components/AppLoader";
import { useMatomo } from '@datapunt/matomo-tracker-react';


const styles = (theme) => ({
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: { display: "block" },
  },
});

const GridTools = (props) => {
  const { classes } = props
  const [nFavorite, setnFavorite] = useState(null)
  const [toolsInBottomGrid, setToolsInBottomGrid] = useState([])
  const [isGrid1Full, setIsGrid1Full] = useState(false)
  const [loading, setLoading] = useState(false)

  const numFavorite = 6
  /* const [numFavorite, setNumFavorite] = useState(6) */
  /* const [favoriteTools, setFavoriteTools] = useState([]) */
  /* const [logoSearchHeight, setLogoSearchHeight] = useState(200) */

  const { trackEvent } = useMatomo();
  
  useEffect(() => {
    
    // Load the list data
    const loadList = async () => {
      setLoading(true);
      try {
        const response = await preferredToolsAPI();
        const favoriteTools = response.data.slice(0, 6).filter(Boolean)
        const bottomGridTools = response.data.slice(6)
        setToolsInBottomGrid(bottomGridTools)
  
        insertItemsTools([...Array(numFavorite).keys()], grid_empty);
        insertItemsTools(favoriteTools, grid1);
        insertItemsTools(bottomGridTools, grid2);
        favoriteTools.forEach((el) => {
          const hel = document.getElementById(el.toolName);
          hel.addEventListener('click', () => trackEvent({ category: el.toolName, action: 'click-event' }));
        })
      } catch (error) {
        console.log(`Error: ${error.message}`, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
  
    loadList();
  
    
    //SET GRIDS options
    const heightLogoSearch = document.getElementById("logo-search").offsetHeight;
    const grid_empty = GridStack.init(
      setGridOptions(numFavorite, heightLogoSearch).opts_empty,
      document.getElementById("grid_empty")
    );
    const grid1 = GridStack.init(
      setGridOptions(numFavorite, heightLogoSearch).opts1,
      document.getElementById("grid1")
    );
    const grid2 = GridStack.init(setGridOptions().opts2, document.getElementById("grid2"));
  
    //RESIZE AND COMPACT items in grids if the window is rezised
    window.addEventListener("resize", () =>
      resizeGrid(grid_empty, grid1, grid2)
    );
  
    window.ondrop = function(e) {
      e.preventDefault()
    }
  
    document.getElementById("grid1").ondragenter = function(e) {
      if (nFavorite === numFavorite && e.target.className.includes("grid-stack-item"))
        setIsGrid1Full(true);
    }
  
    //Set class if grid1 is full
    grid1.on("added removed", (e) => {
      if (e.type === "added") setnFavorite(nFavorite + 1);
      if (e.type === "removed") setnFavorite(nFavorite - 1);
      grid2.compact();
    });
  
    grid2.on("dragstart", () => {
  
      grid2.on("dragstop", () => {
        setIsGrid1Full(false)
        grid2.compact();
        saveUserListTools(grid1, grid2)
      });
    });
  
    //SAVE DATA AND COMPACT items in grids
    grid1.on("dropped dragstop", () => saveUserListTools(grid1, grid2));
    grid2.on("dropped dragstop", () => saveUserListTools(grid1, grid2));
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 
  
    return (
      <div id="tools-box">
        <Box flexGrow={1}>
          <Box position="relative" className="boxFavoriteGrid">
            <Box className="grid-stack" id="grid_empty" />
            <Box className="grid-stack" id="grid1" position="absolute">
              <div className={`${isGrid1Full ? "grid1-full" : ""}`} />
            </Box>
          </Box>
          <Box className={`${classes.sectionDesktop} grid-stack`} id="grid2" />
        </Box>
        <Grid2SwiperMode items={toolsInBottomGrid} id="tools-mobile-grid" />
        {loading && <AppLoader />}
      </div>
    );
};

export default withStyles(styles)(GridTools);
