import { claimDivestment, getDivestments } from '@/apis/PicksAPI';
import { useEffect, useMemo, useState } from 'react';

export const PicksDisinvestForm = ({ ventureId, yourBalance, setView, updateBalance }) => {
  const [data, setData] = useState([]);
  const [status, setStatus] = useState('loading');
  const [amount, setAmount] = useState(1);

  const picksInvestedOnVenture = () => {
    return data
      .filter((devestable) => devestable.id === ventureId)
      .reduce((acc, curr) => acc + curr.picks, 0);
  };

  useEffect(() => {
    const fetchDivestments = async () => {
      try {
        const divestments = await getDivestments(yourBalance.id);
        if (divestments.length === 0) {
          throw new Error('No divestments found');
        }
        setStatus('idle');
        setData(divestments);
      } catch (error) {
        console.error('Error while fetching divestments', error);
        setStatus('error');
      }
    };

    fetchDivestments();
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    setStatus('confirmation');
  };

  const onCancel = () => {
    setView('list');
  };

  const confirmClaim = async () => {
    console.log('Claiming divestment', ventureId, yourBalance.id, amount);

    try {
      await claimDivestment(ventureId, yourBalance.id, amount);
      setView('list');
      await updateBalance();
    } catch (error) {
      console.error('Error while claiming divestment', error);
      setStatus('error');
      return;
    }
  };

  const cancelConfirmation = () => {
    setStatus('idle');
  };

  return (
    <>
      {status === 'loading' ? <p className="mt-5">Loading...</p> : null}
      {status === 'error' ? <p className="mt-5">Something went wrong... try again later!</p> : null}
      {status === 'idle' ? (
        <div className="picks-invest-container">
          <div className="picks-invest-total">
            Picks you can divest:{' '}
            <h2 className="picks-invest-amount">{picksInvestedOnVenture()}</h2> PCK
          </div>
          <div className="mt-5 w-full">
            <form onSubmit={onSubmit}>
              <div className="flex items-center">
                <div className="basis-1/2 text-right">
                  <label htmlFor="amount">Amount:</label>
                </div>
                <div className="basis-1/2 text-right">
                  <input
                    type="number"
                    id="amount"
                    name="amount"
                    min="1"
                    max={picksInvestedOnVenture()}
                    required
                    value={amount} // ...force the input's value to match the state variable...
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex mt-5 gap-2 justify-end w-full">
                <button
                  type="submit"
                  disabled={amount <= 0 || amount > picksInvestedOnVenture()}
                  className="btn"
                >
                  Divest
                </button>
                <button type="button" className="btn btn-secondary" onClick={onCancel}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
      {status === 'confirmation' ? (
        <div className="picks-invest-container">
          <h2 className="py-5">Divesting {amount} PCK</h2>
          <p>Are you sure you want to divest {amount} PCK?</p>
          <div className="flex mt-5 gap-2 justify-end w-full">
            <button type="button" className="btn" onClick={confirmClaim}>
              Yes
            </button>
            <button type="button" className="btn btn-secondary" onClick={cancelConfirmation}>
              No
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};
