import {connectSearchBox} from 'react-instantsearch-dom';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import debounce from 'lodash/debounce';

function DebouncedSearchBox({currentRefinement, refine, indexContextValue, isSearchStalled, createURL, ...restProps}) {
    const [value, setValue] = useState(currentRefinement);

    useEffect(() => {
        setValue(currentRefinement);
    }, [currentRefinement]);

    const refineRef = useRef(refine);
    useEffect(() => {
        refineRef.current = refine;
    }, [refine]);

    const debouncedRefine = useRef(
        debounce((q) => {
            refineRef.current(q);
        }, 300)
    ).current;

    useEffect(() => {
        return () => {
            debouncedRefine.cancel();
        };
    }, [debouncedRefine]);

    const onChange = useCallback(
        (e) => {
            const newValue = e.currentTarget.value;
            setValue(newValue);
            debouncedRefine(newValue);
        },
        [debouncedRefine]
    );

    const onReset = useCallback(
        (e) => {
            e.preventDefault();
            debouncedRefine.cancel();
            setValue('');
            refineRef.current('');
        },
        [debouncedRefine]
    );

    return (
        <div className="ais-SearchBox">
            <form
                className="ais-SearchBox-form"
                noValidate
                onSubmit={(e) => e.preventDefault()}
            >
                <input
                    className="ais-SearchBox-input"
                    type="search"
                    placeholder="Search contacts, documents, procedures ..."
                    autoComplete="off"
                    value={value}
                    onChange={onChange}
                    {...restProps}  // Spread remaining props, excluding indexContextValue
                />
                <button
                    type="submit"
                    title="Submit your search query."
                    className="ais-SearchBox-submit"
                >
                    <svg
                        className="ais-SearchBox-submitIcon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 40 40"
                        aria-hidden="true"
                    >
                        <path
                            d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"
                        ></path>
                    </svg>
                </button>
                {value && (
                    <button
                        className="ais-SearchBox-reset"
                        type="button"
                        title="Clear the search query."
                        onClick={onReset}
                    >
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M10 8.586L15.95 2.636l1.414 1.414L11.414 10l5.95 5.95-1.414 1.414L10 11.414l-5.95 5.95-1.414-1.414L8.586 10 2.636 4.05l1.414-1.414L10 8.586z"
                            />
                        </svg>
                    </button>
                )}
            </form>
        </div>
    );
}

export default connectSearchBox(DebouncedSearchBox);
