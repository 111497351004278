// React
import React from 'react';

// Components
import RepeatEvery from '../components/RepeatEvery';
import RepeatOn from '../components/RepeatOn';
import EndingOn from '../components/EndingOn';

// Styles
import styles from './CustomizeRepeatBody.module.scss';

const CustomizeRepeatBody = () => {
  return (
    <div className={styles.container}>
      <div className={styles.container__box}>
        <div className={styles.container__box__title}>Repeat every</div>
        <RepeatEvery></RepeatEvery>
      </div>
      <div className={styles.container__box}>
        <div className={styles.container__box__title}>Repeat on</div>
        <RepeatOn></RepeatOn>
      </div>
      <div className={styles.container__box}>
        <div className={styles.container__box__title}>Ending on</div>
        <EndingOn></EndingOn>
      </div>
    </div>
  );
};

export default CustomizeRepeatBody;
