import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
    border: "2px solid #ffb15d",
    borderRadius: 10,
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: 25,
    margin: 15,
    overflow: "hidden",
    position: "relative",
    width: "calc((100% / 4) - 30px)",
    height: "fit-content",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.between("sm", "md")]: {
     width: "calc((100% / 2) - 30px)",
   },
  },

  body: {
    flexGrow: 1,
    padding: 15,
    paddingTop: 5,
  },
}));

export default useStyles;
