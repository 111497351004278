import { clannAxios as axios } from '../utils/axiosInstances';

export function listToolAPI() {
  return axios.get('tool/list');
}

export function addToolAPI(data) {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const body = new FormData();
  Object.keys(data).forEach((key) => body.append(key, data[key]));
  return axios.post('tool/add', body, { headers });
}

export function updateToolAPI(data) {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const body = new FormData();
  Object.keys(data).forEach((key) => body.append(key, data[key]));
  return axios.put(`tool/${data.id}`, body, { headers });
}

export function deleteToolAPI(data) {
  return axios.delete(`tool/${data.id}`);
}

export function preferredToolsAPI() {
  return axios.get('tool/preferred-tools');
}

export function updateListToolsAPI(data) {
  return axios.put('update-tool-list', { listTools: data });
}
