import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFF',
    border: '2px solid #ffb15d',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 20,
    overflow: 'hidden',
    position: 'relative',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  body: {
    flexGrow: 1,
    padding: 15,
  },

  fileArea: {
    alignItems: 'flex-start',
    backgroundColor: '#F2F2F2',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    justifyContent: 'space-evenly',
    overflow: 'hidden',
    padding: '10px 15px',

    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      flexDirection: 'row',
      maxwidth: '50%',
    },
  },

  fileItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '10px 15px',

    [theme.breakpoints.up('md')]: {
      height: 50,
      width: 170,
    },

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },

  fileIcon: {
    marginRight: 10,

    [theme.breakpoints.up('lg')]: {
      marginBottom: 10,
      marginRight: 0,
    },
  },
}));

export default useStyles;
