import useStyles from './DashboardCardCounterStyle.js';

export default function DashboardCardCounter(props) {
  const uiClasses = useStyles();

  return (
    <span className={uiClasses.root}>
      #{props.counter}
    </span>
  );
};
