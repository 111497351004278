import React, { useState} from "react";
import { Box, Paper, Divider } from "@material-ui/core";
import { Highlight } from "react-instantsearch-dom";
import { useStyles } from "../components/person/style";

export default function Sircle({ hit }) {
  const cls = useStyles();
  const [elevationPaper, setElevationPaper] = useState(0);

  return (
    <Paper
      elevation={elevationPaper}
      onMouseOver={() => setElevationPaper(6)}
      onMouseOut={() => setElevationPaper(0)}
      className={cls.resultPaper}
    >
      <Box display="flex" flexDirection="column">
        <div className="hit-name">
          <a href={hit.url} target="_blank" rel="noreferrer">
            <Highlight attribute="title" hit={hit} />
          </a>
        </div>
        <div className="hit-info">
          Category: <strong>{hit.category}</strong>
        </div>
        <Divider
          style={{ height: 0.9, margin: "10px 0", backgroundColor: "rgba(0, 0, 0, 0.12)" }}
        />

        <div className="hit-info">
          <strong>Purpose: </strong>
          <Highlight attribute="purpose" hit={hit} />
        </div>
        <div className="hit-info" style={{marginTop: 15}}>
          <strong>Accountabilities:</strong>
          <Highlight attribute="accountabilities" hit={hit} />
        </div>
      </Box>
    </Paper>
  );
}
