// React
import React, { useState } from 'react';

// Material UI
import { createTheme, ThemeProvider, FormControl, Select, MenuItem } from '@material-ui/core';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  setRepeatNumber,
  setRepeatPeriod,
  DEFAULT_PERIOD_OPTIONS,
  DAYS,
  WEEKS,
  MONTHS,
  YEARS,
} from '../../../../../../../store/customizeRepeatSlice';

const RepeatEvery = () => {
  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          cursor: 'pointer',
        },
      },
      MuiInput: {
        root: {
          border: '1px solid #C6CED2',
          borderRadius: '12px',
          padding: '5px 16px 5px 16px',
          '&:hover': {
            border: '1px solid #7B878E',
          },
        },
        underline: {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
      },
      MuiSvgIcon: {
        root: {
          width: '3rem',
        },
      },
      PrivateSwitchBase: {
        root: {
          padding: '4px 0px 4px 0px',
        },
      },
    },
  });

  const getNumbersFromPeriod = (period) => {
    let numbers = [];
    if (period.key === DAYS.key) numbers = Array.from(Array(31).keys()).map((k) => k + 1);
    else if (period.key === WEEKS.key) numbers = Array.from(Array(5).keys()).map((k) => k + 1);
    else if (period.key === MONTHS.key) numbers = Array.from(Array(12).keys()).map((k) => k + 1);
    else if (period.key === YEARS.key) numbers = Array.from(Array(100).keys()).map((k) => k + 1);

    return numbers;
  };

  const repeatNumber = useSelector((state) => state.customizeRepeat.repeatNumber);
  const repeatPeriod = useSelector((state) => state.customizeRepeat.repeatPeriod);
  const dispatch = useDispatch();

  const [repeatNumbers, setRepeatNumbers] = useState(getNumbersFromPeriod(repeatPeriod));

  const handleChangePeriod = (value) => {
    let numbers = getNumbersFromPeriod(value);

    if (!numbers.includes(repeatNumber)) dispatch(setRepeatNumber('1'));

    setRepeatNumbers([...numbers]);

    dispatch(setRepeatPeriod(value));
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: 'flex', flexDirection: 'row', columnGap: '8px' }}>
        <FormControl style={{ width: 'fit-content' }}>
          <Select
            defaultValue={'1'}
            value={repeatNumber}
            onChange={(e) => dispatch(setRepeatNumber(e.target.value))}
            IconComponent={ExpandMoreIcon}
          >
            {repeatNumbers.map((v) => (
              <MenuItem value={v} key={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: 'fit-content' }}>
          <Select
            defaultValue="days"
            value={repeatPeriod}
            onChange={(e) => handleChangePeriod(e.target.value)}
            IconComponent={ExpandMoreIcon}
          >
            {DEFAULT_PERIOD_OPTIONS.map((v) => (
              <MenuItem value={v} key={v.key}>
                {v.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </ThemeProvider>
  );
};

export default RepeatEvery;
