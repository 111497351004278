import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { UseCustomSnackbar } from '../../../../utils/CustomSnackbar';
import { accountReset } from '../../../../apis/PicksAPI';

const PicksSettings = ({transactionSelected}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [successMsg, setSuccessMsg] = useState(false);

    const testRegex = (passphrase) => {
        const regex = /^\d{6}$/;
        return regex.test(passphrase);
    }

    const setBtnStatus = (passphrase) => {
        setIsBtnDisabled(!testRegex(passphrase));
    }

    const resetPassphrase = async () => {
        try {
            let passphrase = document.getElementById('passphrase').value;
            if (testRegex(passphrase)) {
                setIsBtnDisabled(true);
                await accountReset(passphrase);
                setSuccessMsg(true);
            } else {
                UseCustomSnackbar(enqueueSnackbar, closeSnackbar, 'The passphrase does not meet the specified criteria' , 'error');
            }
        }
        catch (error) {
            console.error("Error in account reset", error);
            UseCustomSnackbar(enqueueSnackbar, closeSnackbar, 'Error in account reset', 'error');
        }
    }
    const goBack = () => {
        transactionSelected()
    }

    return (
        <div className="">
            <div className='picks-footer'>
                <div className='picks-set-container'>
                    <h2>Reset PIN</h2>
                    <p className='picks-text' style={{marginTop: '20px'}}>
                        Don't worry, happens to the best of us. 
                    </p>
                    <p className='picks-text'>
                        Enter the new PIN below. 
                    </p>
                    <p className='picks-text'>
                        Remember, the PIN must consist of 6 digits
                    </p>
                </div>
                
                <div className='picks-pin-wrapper'>
                    <img 
                        className='picks-pin-icon'
                        src={`/assets/miniPicks/lock.png`} 
                        alt="Mini Picks Lock"
                    />
                    <div className='picks-pin-container'>
                        <label>Pin</label>
                        <input 
                            placeholder='******' 
                            id='passphrase' 
                            className='picks-pin-input' 
                            type='password'
                            onChange={ev => setBtnStatus(ev.target.value)}
                        />
                    </div>
                    <img
                        className='picks-pin-icon'
                        src={`/assets/miniPicks/x.png`} 
                        alt="Mini Picks X"
                    />
                </div>
                <button 
                    className={isBtnDisabled ? 'picks-btn-7' : 'picks-btn-2'} 
                    onClick={resetPassphrase} 
                    disabled={isBtnDisabled}
                >
                    Reset PIN
                </button>
                {successMsg && <span className="picks-set-success">Your PIN has been successfully reset!</span>}
            </div>
        </div>
    );
};

export default PicksSettings;