import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Toolbar, Typography, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useStyles from './TreedomAreaStyle';
import AppLoader from '../../../../components/AppLoader';
import { getFreeCodes, assignTree } from '../../../../apis/TreedomAPI';
import { UseCustomSnackbar } from '../../../../utils/CustomSnackbar';

export default function TreedomArea() {
  const [loading, setLoading] = useState(false);

  const [numberOfTrees, setNumberOfTrees] = useState(0);
  const [usersList, setList] = useState([]);

  const [user, setUser] = useState(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const uiClasses = useStyles();

  const loadList = async (enqueueSnackbar, closeSnackbar) => {
    setLoading(true);
    try {
      const response = await getFreeCodes();
      setNumberOfTrees(response.data.freeCodesNum);
      setList(response.data.usersWithoutTree);
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }
    setLoading(false);
  };

  const assignTreeToUser = async (enqueueSnackbar) => {
    setLoading(true);
    try {
      await assignTree({ username: user });
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, `Tree assigned to: ${user}`, 'success');
      setUser(null);
      loadList(enqueueSnackbar, closeSnackbar);
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }
    setLoading(false);
  };

  useEffect(() => {
    loadList(enqueueSnackbar, closeSnackbar);
  }, [enqueueSnackbar, closeSnackbar]);

  return (
    <div className={uiClasses.root}>
      <Toolbar variant="dense" className={uiClasses.toolbar}>
        <Typography variant="h5" className={uiClasses.title}>
          Treedom
        </Typography>
      </Toolbar>
      {numberOfTrees !== 0 && <div className={uiClasses.container}>
        <Typography variant="h6">Select a user to assign a tree to</Typography>
        <Typography variant="h6">({numberOfTrees} available trees)</Typography>
        <Autocomplete
          autoComplete={true}
          className={uiClasses.autocomplete}
          value={user}
          options={usersList}
          onChange={(_, value) => setUser(value)}
          renderInput={(params) => <TextField {...params} label="User" variant="outlined" />}
        />
        <Button
          disabled={!user}
          variant="outlined"
          onClick={() => assignTreeToUser(enqueueSnackbar)}
        >
          Assign Tree
        </Button>
      </div>}
      {numberOfTrees === 0 && <div className={uiClasses.container}>
        <Typography variant="h6">All Treedom codes have been assigned!</Typography>
      </div>}
      {loading && <AppLoader />}
    </div>
  );
}
