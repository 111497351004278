import React, { useState } from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";
import "../Navbar.scss";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";

export const ListItemSimple = ({ item }) => (
  <ListItem button component="a" href={item?.url}>
    <ListItemText style={{ textAlign: "left" }} primary={item?.title} />
  </ListItem>
);

export const ListItemWithSubList = ({ item }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText style={{ textAlign: "left" }} primary={item?.title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List style={{ marginLeft: 20 }} component="div" disablePadding>
          {item?.itemList?.map((subitem, index) => (
            <ListItem button key={`sublist-mobile-${subitem.id}`} component="a" href={subitem?.url}>
              <ListItemText style={{ textAlign: "left" }} primary={subitem?.title} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};