// React
import React, { useEffect } from 'react';

// Material UI
import { createTheme, ThemeProvider, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Utils
import momentTZ from 'moment-timezone';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setTimeZone } from '../../../../../../../store/suggestEventSlice';

const TimeZonePicker = () => {
  const theme = createTheme({
    overrides: {
      MuiFormControl: {
        root: {
          minWidth: '180px',
          padding: '12px 0px 11px 0px',
        },
      },
      MuiInputBase: {
        root: {},
        input: {
          cursor: 'pointer',
          textAlign: 'start',
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
      },
      MuiSvgIcon: {
        root: {
          width: '30px',
        },
      },
      MuiAutocomplete: {
        clearIndicator: {
          padding: '0px',
        },
      },
    },
  });

  const defaultProps = {
    options: momentTZ.tz.names(),
  };

  const timeZone = useSelector((state) => state.suggestEvent.timeZone);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!timeZone || timeZone === '') dispatch(setTimeZone(momentTZ.tz.guess()));
  }, [timeZone, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        {...defaultProps}
        value={timeZone}
        onChange={(e, value) => dispatch(setTimeZone(value))}
        autoComplete
        includeInputInList
        renderInput={(params) => <TextField {...params} margin="normal" size="small" />}
        popupIcon={<ExpandMoreIcon />}
      />
    </ThemeProvider>
  );
};

export default TimeZonePicker;
