import { createSvgIcon, SvgIcon } from '@material-ui/core';

export const BarChart2 = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 6.3499998 6.3499999"
    version="1.1"
    id="svg1"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs id="defs1" />
    <g id="layer2">
      <path
        d="M 1.7053223,3.3868734 C 1.5232701,3.3871569 1.3756785,3.5345175 1.3751099,3.716569 V 5.3572957 C 1.3742515,5.5403558 1.5222604,5.6892913 1.7053223,5.6895752 1.88899,5.6901513 2.0379469,5.5409624 2.037085,5.3572957 V 3.716569 C 2.0365151,3.5339119 1.8879793,3.3863016 1.7053223,3.3868734 Z"
        strokeLinecap='round'
        strokeLinejoin='round'
        id="path4"
      />
      <path
        d="M 3.2302938,0.76171061 C 3.0482422,0.76227894 2.9008813,0.90987076 2.9005981,1.091923 V 5.3283569 C 2.9011652,5.5102074 3.0484433,5.6574855 3.2302938,5.6580526 3.4131525,5.658911 3.5620042,5.5112168 3.5625732,5.3283569 V 1.091923 C 3.5622896,0.90886108 3.4133539,0.76085192 3.2302938,0.76171061 Z"
        strokeLinecap='round'
        strokeLinejoin='round'
        id="path3"
      />
      <path
        d="M 4.7557821,2.1425049 A 0.3307285,0.3307285 0 0 0 4.4255697,2.4747843 V 5.3888184 A 0.3307285,0.3307285 0 0 0 4.7557821,5.718514 0.3307285,0.3307285 0 0 0 5.0880615,5.3888184 V 2.4747843 A 0.3307285,0.3307285 0 0 0 4.7557821,2.1425049 Z"
        strokeLinecap='round'
        strokeLinejoin='round'
        id="path2"
      />
    </g>
  </svg>,
  'BarChart2'
);
