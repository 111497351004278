// React
import React from 'react';

// Material UI
import { createTheme, ThemeProvider, FormControl, Select, MenuItem } from '@material-ui/core';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  setShowCustomizeRepeat,
  setRepeated,
} from '../../../../../../../store/customizeRepeatSlice';

const RepeatedPicker = () => {
  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          cursor: 'pointer',
          padding: '14px 25px 14px 0',
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
      },
      MuiInputLabel: {
        root: {
          marginTop: '2px',
        },
      },
      MuiFormControl: {
        root: {
          margin: `7px 0 0 0`,
        },
      },
    },
  });

  const repeated = useSelector((state) => state.customizeRepeat.repeated);
  const repeatedOptions = useSelector((state) => state.customizeRepeat.repeatedOptions);
  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={theme}>
      <FormControl>
        <Select
          defaultValue="Is not repeated"
          value={repeated}
          onChange={(e) => {
            dispatch(setRepeated(e.target.value));
            dispatch(setShowCustomizeRepeat(e.target.value === 'Customize'));
          }}
          IconComponent={ExpandMoreIcon}
        >
          {repeatedOptions.map((v) => (
            <MenuItem value={v} key={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

export default RepeatedPicker;
