import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core"
import "../components/ShowInRooms/showInRooms.scss";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

export default function AddRoomPopUp({addRoom, setPopUp, roomNumber, isOpen}) {

  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const orangeTheme = createTheme({ 
    palette: { primary: { main: '#ef6c00' } }, 
    typography: { fontFamily: `'Quicksand', sans-serif`} })
  
  function isFormInvalid() {
    return(
      name.trim().length === 0 || url.trim().length === 0
    )
  }

  return (
    <div className={`addRoomContainer ${isOpen ? "popUpOpen" : "popUpClose"}`}>
      <div className="addRoomPopUp">
      <MuiThemeProvider theme={orangeTheme}>
        <form onSubmit={(e) => { e.preventDefault(); addRoom(roomNumber, name, url); setPopUp() }}>
          <div className="input">
            <TextField id="outlined-basic" color="primary" value={name} label="Room Name" variant="outlined" onChange={(e) => setName(e.target.value)}/>
            <TextField id="outlined-basic" color="primary" value={url} label="Room URL" variant="outlined" onChange={(e) => setUrl(e.target.value)} />
          </div>
          <div className="buttons">
            <Button onClick={() => setPopUp()}>Close</Button>
            <Button color="primary" 
                    variant="contained" 
                    type="submit"
                    disabled={isFormInvalid()}>Add</Button>
          </div>   
        </form>
      </MuiThemeProvider>
      </div> 
    </div>
    
  ) 
}
