import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ClickAwayListener,
  Drawer,
  Button,
  IconButton,
  Box,
  Avatar,
  Tooltip,
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Icon
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountMenuItems from './AccountMenuItems';
import { getHasAvatar } from '../../../apis/AccountMenuAPI';
import { getPicks } from '../../../apis/PicksAPI';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { ACCOUNT_MENU_ITEMS, SAVATAR } from '../costants';
import '../Navbar.scss';
import { accountMenuStyle, tooltipStyle } from '../style';
import MD5 from 'crypto-js/md5';
import { useSnackbar } from 'notistack';
import TreedomModal from './TreedomModal/TreedomModal';
import PicksModal from './MiniPicksModal/PicksModal';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CalendarButton from './CalendarButton';

import { getTreedomInfo, setMyTreedomInfo } from '../../../apis/TreedomAPI';
import { UseCustomSnackbar } from '../../../utils/CustomSnackbar';

export default function AccountMenu({
  fullKeycloak,
  isAdmin,
  savatarURL,
  rooms,
  isDashboard,
  onLoad,
}) {
  const { userInfo, logout } = fullKeycloak || {};
  const classes = accountMenuStyle();
  const classesTooltip = tooltipStyle();
  const [isOpen, setIsOpen] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [hasAvatar, setHasAvatar] = useState(true);
  const [savatarPopUpOpen, setSavatarIsOpen] = useState(false);
  const [treedomModalOpen, setTreedomModalOpen] = useState(false);
  const [treedomInfo, setTreedomInfo] = useState({});
  const [picksModalIsOpen, setPicksModalIsOpen] = useState(false);
  const [isPicksActive, setIsPicksActive] = useState(false);
  const [balance, setBalance] = useState(null);

  const openPicksModal = () => setPicksModalIsOpen(true);

  const closePicksModal = useCallback (() => {
    setPicksModalIsOpen(false)
  },[]);

  const loadEmployee = useCallback(
    async () => {
      try {
        const response = await getHasAvatar(userInfo.preferred_username);
        setHasAvatar(response.data);
      } catch (error) {
        setHasAvatar(true);
      }
    },
    [userInfo.preferred_username]
  );

  const loadTreedomInfo = useCallback(async (enqueueSnackbar) => {
    try {
      const response = await getTreedomInfo();
      setTreedomInfo({
        treeCode: response?.data?.treedomCode,
        treeName: response?.data?.treeName,
        treeType: response?.data?.treeTypeID,
        imgUrl: response?.data?.imgUrl ? response?.data?.imgUrl : 'plant',
      });
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }
  }, [closeSnackbar]);

  const updateTreedomInfo = async (treeName, treeTypeID) => {
    try {
      await setMyTreedomInfo({ treeName, treeTypeID });
      await loadTreedomInfo(enqueueSnackbar);
      return Promise.resolve(true);
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }
    return Promise.resolve(false);
  };

  const picksFetchData = async () => {
    try {
      const picksResponse = await getPicks();
      setIsPicksActive(true);
      setBalance(picksResponse.data);
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        console.error("Il bilancio richiesto non è stato trovato.", error);
        setIsPicksActive(false);
      } else {
        console.error("Errore durante il recupero del bilancio", error);
      }
    }
  };

  useEffect(() => {
    const employee = loadEmployee(enqueueSnackbar, closeSnackbar);
    const treedomInfo = loadTreedomInfo(enqueueSnackbar, closeSnackbar);

  picksFetchData();

  let picksButton = document.getElementById('miniPicksButton');
  let myDiv = document.getElementById('picksModal');
  let closeCounter = 0;

  window.addEventListener('click', function (event) {
    closeCounter++;
    if (event.target !== picksButton && event.target !== myDiv && closeCounter!==1) {
      closeCounter = 0;
      closePicksModal()
    }
  });
    Promise.all([employee, treedomInfo]).then(() => {
      if (onLoad) onLoad();
    });
  }, [enqueueSnackbar,closeSnackbar, loadEmployee, loadTreedomInfo, onLoad, closePicksModal]);

  // SELECT OPENING DIRECTION (top,left,bottom,right)
  const anchor = 'right';
  const [direction, setDirection] = useState({ [anchor]: false });
  const toggleDrawer = (anchor, open) => (event) => {
    setDirection({ [anchor]: open });
    setIsOpen(!open);
  };
  const savatarIconUrl = savatarURL + '/' + MD5(fullKeycloak?.userInfo?.email);

  return (
    <div>
      {/* <AccountNotifications /> */}
      <ClickAwayListener onClickAway={toggleDrawer(anchor, false)}>
        <Box display="flex" alignItems="center">
          <div className='miniPicks-container'>
            <div id="miniPicksButton" onClick={openPicksModal}>
              <img
                src={`/assets/miniPicks/button.png`} 
                alt="Mini Picks button"
                width={'100%'}
                height={'100%'}
              />
            <div>
                <span>
                  <label className='picks-balance'>
                    {balance !== null ? `${balance.picks}` : '???' }    
                  </label>
                  <label className='picks-pck'>
                    PCK
                  </label>
                </span>
            </div>
            </div>
            <PicksModal 
              id='picksModal' 
              isOpen={picksModalIsOpen} 
              onClose={closePicksModal} 
              isPicksActive={isPicksActive} 
              yourBalance={balance}
              updateBalance={picksFetchData}
            />
          </div>
          {!isDashboard && (
            <div id="itemsContainer">
              {treedomInfo?.treeCode && (
                <Tooltip
                  open={!treedomInfo?.treeName}
                  title="1"
                  arrow={true}
                  classes={{
                    tooltip: classesTooltip.customTooltip,
                    arrow: classesTooltip.customArrow,
                  }}
                  placement="bottom"
                >
                  <div id="treedomIcon" onClick={() => setTreedomModalOpen(true)}>
                    <img
                      src={`/assets/treedom/${treedomInfo.imgUrl}.png`}
                      alt="Your plant"
                      width={'auto'}
                      maxwidth={'45px'}
                      height={'45px'}
                    />
                  </div>
                </Tooltip>
              )}
              <TreedomModal
                open={treedomModalOpen}
                handleClose={() => setTreedomModalOpen(false)}
                treedomInfo={treedomInfo}
                handleUpdate={(name, type) => updateTreedomInfo(name, type)}
              />
              {!hasAvatar && treedomInfo?.treeCode && <div id="vBar">|</div>}
              {!hasAvatar && (
                <Tooltip
                  open={true}
                  title="1"
                  arrow={true}
                  classes={{
                    tooltip: classesTooltip.customTooltip,
                    arrow: classesTooltip.customArrow,
                  }}
                >
                  <div className="savatarNavbarButton">
                    <button onClick={() => setSavatarIsOpen(true)}>{SAVATAR.title}</button>
                    {savatarPopUpOpen && (
                      <SavatarPopUp
                        url={SAVATAR.url}
                        isOpen={savatarPopUpOpen}
                        close={() => setSavatarIsOpen(false)}
                      ></SavatarPopUp>
                    )}
                  </div>
                </Tooltip>
              )}
            </div>
          )}
          {!isDashboard && <CalendarButton></CalendarButton>}
          <IconButton onClick={toggleDrawer(anchor, isOpen)}>
            <Avatar src={savatarIconUrl} variant="rounded" />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={direction[anchor]}
            onClose={toggleDrawer(anchor, false)}
            classes={{ paper: classes.drawer }}
            className={`drawer-navbar ${classes.drawerUnderAppBar}`}
          >
            <MenuItems
              username={userInfo?.preferred_username}
              classes={classes}
              isAdmin={isAdmin}
              logout={logout}
              rooms={rooms}
              hasAvatar={hasAvatar}
            />
          </Drawer>
        </Box>
      </ClickAwayListener>
    </div>
  );
}

const MenuItems = ({ username, classes, logout, isAdmin, rooms, hasAvatar }) => {
  return (
    <div className={classes.root}>
      <List
        className={hasAvatar ? 'navbarListAvatar' : 'navbarListNoAvatar'}
        component="nav"
        aria-label="main mailbox folders"
      >
        {ACCOUNT_MENU_ITEMS.map((item) => {
          return <AccountMenuItems key={item.title} {...item} username={username} />;
        })}

        {/* Dashboard Button */}
        {isAdmin && (
          <ListItem button component={Link} to="/dashboard">
            <ListItemIcon>
              <Icon>dashboard_customize </Icon>
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        )}

        {/* Logout Button */}
        <ListItem button component="a" onClick={() => logout()}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
      {rooms?.myRooms && (
        <div className="showInFavList">
          <p>ShowIn Rooms</p>
          <ShowInRoomSidebar rooms={rooms} roomNumber={1}></ShowInRoomSidebar>
          <ShowInRoomSidebar rooms={rooms} roomNumber={2}></ShowInRoomSidebar>
          <ShowInRoomSidebar rooms={rooms} roomNumber={3}></ShowInRoomSidebar>
          <ShowInRoomSidebar rooms={rooms} roomNumber={4}></ShowInRoomSidebar>
        </div>
      )}
    </div>
  );
};

const ShowInRoomSidebar = ({ rooms, roomNumber }) => {
  const room = rooms.myRooms.filter((item) => {
    return item.number === roomNumber;
  });
  if (room.length !== 0) {
    return (
      <div>
        <a href={room[0].link} target="_blank" rel="noreferrer">
          <Button>{room[0].name}</Button>
        </a>
      </div>
    );
  } else {
    return <div></div>;
  }
};

const SavatarPopUp = ({ url, isOpen, close }) => {
  const orangeTheme = createTheme({
    palette: { primary: { main: '#ef6c00' } },
    typography: { fontFamily: `'Quicksand', sans-serif` },
  });

  return (
    <div className={`savatarContainer ${isOpen ? 'openPopUp' : 'closePopUp'}`}>
      <div className="savatarPopUp">
        <div className="message">
          😞 your Sorint account isn't complete yet: upload your profile photo here!
        </div>
        <div className="buttons">
          <MuiThemeProvider theme={orangeTheme}>
            <Button onClick={close} style={{ color: '#000000' }}>
              Close
            </Button>
            <Button color="primary" variant="contained" href={url} target="_blank">
              <span>Go to Savatar</span>
              <ArrowForwardIcon></ArrowForwardIcon>
            </Button>
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};
