// React
import React from 'react';

// Material UI
import { createTheme, ThemeProvider, TextField, InputAdornment } from '@material-ui/core';

// Icons
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setPlace } from '../../../../../../../store/suggestEventSlice';

const PlaceField = () => {
  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          cursor: 'pointer',
          padding: '11px 13px 11px 13px',
          borderRadius: '8px',
          marginLeft: '18px',
          border: '1px solid #E8EBED',
          '&:hover': {
            border: '1px solid #98A6AE',
          },
          '@media (max-width:940px)': {
            marginLeft: '0px !important',
            marginRight: '15px',
          },
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
      },
      MuiFormHelperText: {
        root: {
          marginLeft: '46px',
          '&.Mui-error': {
            color: 'red',
          },
        },
      },
    },
  });

  const place = useSelector((state) => state.suggestEvent.place);
  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={theme}>
      <TextField
        placeholder="Add place*"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnOutlinedIcon htmlColor="#F17023" />
            </InputAdornment>
          ),
        }}
        value={place}
        onChange={(e) => dispatch(setPlace(e.target.value))}
      />
    </ThemeProvider>
  );
};

export default PlaceField;
