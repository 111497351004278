// Utils
import Cookies from 'js-cookie';
import pluralize from 'pluralize';
import { format, parseISO } from 'date-fns';

const getVisibleCategoriesFromCookies = () => {
  let visible_categories_cookie = Cookies.get('visible_categories');
  if (visible_categories_cookie === undefined) {
    visible_categories_cookie = '';
    Cookies.set('visible_categories', visible_categories_cookie, { expires: 365 });
  }
  return visible_categories_cookie.split(';').filter((cat) => cat.trim().length > 0);
};

const formatDateForAPI = (dateString, timezoneString, allday, isEndDate) => {
  let date = new Date(new Date(dateString).toLocaleString('en-US', { timeZone: timezoneString }));

  if (allday) {
    if (isEndDate) {
      date = new Date(
        new Date(date.setDate(date.getDate() + 1)).toLocaleString('en-US', {
          timeZone: timezoneString,
        })
      );
      return 'VALUE=DATE:' + date.toISOString().split('.')[0].replaceAll('-', '').split('T')[0];
    } else {
      return 'VALUE=DATE:' + date.toISOString().split('.')[0].replaceAll('-', '').split('T')[0];
    }
  }
  return date.toISOString().split('.')[0].replaceAll('-', '').replaceAll(':', '') + 'Z';
};

const fromRruleToNaturalLanguage = (rrule) => {
  const extractINTERVAL = (values) => {
    const value = values.find((v) => v.includes('INTERVAL='));
    if (value === undefined) return 1;
    else return value.split('=')[1];
  };

  const extractFREQ = (values) => {
    const value = values.find((v) => v.includes('FREQ='));
    const map = {
      DAILY: 'day',
      WEEKLY: 'week',
      MONTHLY: 'month',
      YEARLY: 'year',
    };
    return map[value.split('=')[1]];
  };

  const extractBYDAY = (values) => {
    const value = values.find((v) => v.includes('BYDAY='));
    if (value === undefined) return '';
    const map = {
      MO: 'monday',
      TU: 'tuesday',
      WE: 'wednesday',
      TH: 'thursday',
      FR: 'friday',
      SA: 'saturday',
      SU: 'sunday',
    };
    const splitted = value.split('=')[1].split(',');
    const result = [];
    splitted.map((v) => result.push(map[v]));
    return result.join(', ');
  };

  const extractUNTIL = (values) => {
    const value = values.find((v) => v.includes('UNTIL='));
    if (value === undefined) return '';
    return format(parseISO(value.split('=')[1]), 'EEE dd MMM yyyy');
  };

  try {
    if (!rrule || rrule.trim().length === 0) return null;
    else {
      const splitted = rrule.split(';');
      const interval = extractINTERVAL(splitted);
      const frequency = pluralize(extractFREQ(splitted), interval);
      const days = extractBYDAY(splitted);
      const until = extractUNTIL(splitted);

      return `The event is repeated every ${interval} ${frequency} ${
        days !== undefined && days.length > 0 ? 'on ' + days : ''
      } ${until !== undefined && until.length > 0 ? 'until ' + until : ''}`;
    }
  } catch (_) {
    console.log(_);
    return 'Error during RRULE decoding!';
  }
};

const createRrule = (repeated, repeatInterval, repeatFrequency, repeatOn, repeatUntil) => {
  if (!repeated) return null;
  let RRULE = `FREQ=${repeatFrequency};INTERVAL=${repeatInterval}`;
  if (repeatOn) RRULE += `;BYDAY=${repeatOn}`;
  if (repeatUntil) RRULE += `;UNTIL=${repeatUntil}`;
  return RRULE;
};

export {
  getVisibleCategoriesFromCookies,
  formatDateForAPI,
  fromRruleToNaturalLanguage,
  createRrule,
};
