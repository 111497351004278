import React, { useEffect, useState } from 'react';
import { Modal, Stepper, Step, StepLabel, Button } from '@material-ui/core';
import Step1 from './Stepper/Step1';
import Step2 from './Stepper/Step2';
import Step3 from './Stepper/Step3';
import Step4 from './Stepper/Step4';
import CloseIcon from '@material-ui/icons/Close';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { stepperTheme, greenTheme, ModalStyle, StepperStyle } from './TreedomModalStyle';

export default function TreedomModal({ open, handleClose, treedomInfo, handleUpdate }) {
  const classesModal = ModalStyle();
  const classesStepper = StepperStyle();

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ['Introduction', 'Choose your tree', 'Give it a name', 'Watch it grow'];
  const nextStepButtonText = ['Of course', 'Next', 'Next', 'Close'];

  const [treeName, setTreeName] = useState('');
  const [treeTypeID, setTreeTypeID] = useState(-1);

  useEffect(() => {
    if (treedomInfo?.treeName) setActiveStep(3);
    else setActiveStep(0);
  }, [treedomInfo, open]);

  useEffect(() => {
    // reset treeName and treetypeID to disable next button on Step3 (activeStep = 2)
    if (activeStep === 2) {
      setTreeName('');
      setTreeTypeID(-1);
    }
  }, [activeStep]);

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <Step1 />;
      case 1:
        return <Step2 treeCode={treedomInfo?.treeCode} />;
      case 2:
        return (
          <Step3
            onChangeName={(value) => setTreeName(value)}
            onChangeType={(value) => setTreeTypeID(value)}
          />
        );
      case 3:
        return <Step4 treeName={treedomInfo?.treeName} treeCode={treedomInfo?.treeCode} treeImg={treedomInfo?.imgUrl} />;
      default:
        return <div></div>;
    }
  }

  const handleNext = async () => {
    if (activeStep === 2) {
      const success = await handleUpdate(treeName, treeTypeID);
      if (success) setActiveStep(3);
    } else if (activeStep === steps.length - 1) handleClose();
    else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function isNextStepEnabled() {
    return (
      activeStep !== 2 || (activeStep === 2 && treeName?.trim().length !== 0 && treeTypeID !== -1)
    );
  }

  return (
    <Modal open={open} onClose={handleClose} className={classesModal.modal}>
      <div className={classesModal.paper}>
        <CloseIcon className={classesModal.close} onClick={handleClose} />
        <div className={classesStepper.root}>
          <MuiThemeProvider theme={stepperTheme}>
            <Stepper activeStep={activeStep} alternativeLabel className={classesStepper.paper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </MuiThemeProvider>
          <div className={classesStepper.content}>{getStepContent(activeStep)}</div>
          <div>
            <MuiThemeProvider theme={greenTheme}>
              <Button
                disabled={activeStep === 0 || activeStep === 3}
                onClick={handleBack}
                className={classesStepper.backButton}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={!isNextStepEnabled()}
              >
                {nextStepButtonText[activeStep]}
              </Button>
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    </Modal>
  );
}
