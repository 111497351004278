// React
import React from 'react';

// Material UI
import { Box } from '@material-ui/core';

// Utils
import { format } from 'date-fns';

// Styles
import styles from './DateElement.module.scss';

const DateElement = ({ start }) => {
  const startDay = format(start, 'iii');
  const startNumber = format(start, 'dd');

  return (
    <Box className={styles.container}>
      <Box className={styles.container__date}>
        <Box style={{ fontWeight: 400, fontSize: '17px' }}>{startDay}</Box>
        <Box style={{ fontWeight: 700, fontSize: '22px' }}>{startNumber}</Box>
        {/* <Box>{startMonth}</Box> */}
      </Box>
      <Box className={styles.container__decoration}>
        <Box className={styles.container__decoration__vl}></Box>
        <Box className={styles.container__decoration__vl}></Box>
      </Box>
    </Box>
  );
};

export default DateElement;
