// React
import React from 'react';

import styles from './AcceptedPage.module.scss';

const AcceptedPage = () => {
  return (
    <div className={styles.container}>
      <div>
        <img
          src="/assets/calendar/check_image.png"
          alt="Appointment accepted"
          className={styles.container__image}
        />
      </div>
      <div className={styles.container__message}>
        The appointment has been <span style={{ textTransform: 'uppercase' }}>accepted!</span>
      </div>
    </div>
  );
};

export default AcceptedPage;
