// React
import React, { useEffect, useState } from 'react';

// Material UI
import { createTheme, ThemeProvider } from '@material-ui/core';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedDays } from '../../../../../../../store/customizeRepeatSlice';

// Styles
import styles from './RepeatOn.module.scss';

const DaySelector = ({ day, isSelected, updateIsSelected }) => {
  return (
    <div
      className={`${styles.day} ${isSelected ? styles.day__selected : ''}`}
      onClick={() => updateIsSelected(day)}
    >
      {day.day}
    </div>
  );
};

const RepeatOn = () => {
  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          cursor: 'pointer',
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
      },
      PrivateSwitchBase: {
        root: {
          padding: '4px 0px 4px 0px',
        },
      },
    },
  });

  const [days, setDays] = useState([]);
  const selectedDays = useSelector((state) => state.customizeRepeat.selectedDays);
  const dispatch = useDispatch();

  const isIncluded = (day) => {
    return selectedDays.filter((obj) => obj.key === day.key).length > 0;
  };

  const handleChangeSelectedDays = (day) => {
    if (!isIncluded(day)) dispatch(setSelectedDays([...selectedDays, day]));
    else dispatch(setSelectedDays(selectedDays.filter((obj) => obj.key !== day.key)));
  };

  useEffect(() => {
    setDays([
      { key: 1, day: 'M', short: 'MOn' },
      { key: 2, day: 'T', short: 'TU' },
      { key: 3, day: 'W', short: 'WE' },
      { key: 4, day: 'T', short: 'TH' },
      { key: 5, day: 'F', short: 'FR' },
      { key: 6, day: 'S', short: 'SA' },
      { key: 7, day: 'S', short: 'SU' },
    ]);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.container}>
        {days.map((obj) => (
          <DaySelector
            day={obj}
            isSelected={isIncluded(obj)}
            updateIsSelected={handleChangeSelectedDays}
            key={obj.key}
          ></DaySelector>
        ))}
      </div>
    </ThemeProvider>
  );
};

export default RepeatOn;
