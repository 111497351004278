import React from 'react';
import '../../Navbar.scss';

const PicksWelcome = ({changeSelected}) => {

    const pinSelected = () => {
        changeSelected(false);
    }

    return (
        <div className="grid  h-full grid-cols-[350px] justify-between">
            <div>
                <h1>Welcome to Picks</h1>
            </div>
            <div>
                <img
                    src={`/assets/miniPicks/man.png`}
                    alt="Mini Picks button"
                    className='min-w-[250px] max-w-[250px] h-[250px] mx-auto'
                />
            </div>
            <div className='picks-text-container'>
                <h3 className='mb-5'>Let's create your PIN.</h3>
                <p className='picks-text'>
                    The PIN will be used to confirm each transaction you perform.
                    The application will not save anything. 
                </p>
                <p className='picks-text' style={{ color: '#7C3AED' }}>
                    Save your PIN somewhere.
                </p>
            </div>
            <div className="flex justify-end align-end">
                <div>
                    <button onClick={pinSelected} className='picks-btn-1 m-0'>
                        <img
                            src={`/assets/miniPicks/arrow-right.png`}
                            alt="Mini Picks button"
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PicksWelcome;

