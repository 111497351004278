import React, {useState, useEffect} from 'react';
import '../../Navbar.scss';
import SingleTransaction from './SingleTransaction';
import { getTransaction } from '../../../../apis/PicksAPI';

const PicksTransactions = ({yourBalance}) => {
const [allTransactions, setAllTransactions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const transactionResponse = await getTransaction(yourBalance.id);
                setAllTransactions(transactionResponse);
            } catch (error) {
                console.error("Errore durante il recupero delle transazioni", error);
            }
        };
        fetchData();
    }, [yourBalance]);

    return (
        <div className='picks-tran-main'>
            {allTransactions.data && allTransactions.data.totalSize > 0 ? (
                <>
                    {allTransactions.data && allTransactions.data.data.map((element, index) => (
                        <SingleTransaction 
                            key={element.createdAt} 
                            picks={element.picks}
                            reciver={element.receiverUsername}
                            reciverName={element.receiverDescription}
                            tranOwner={element.owner}
                            tranOwnerName={element.senderDescription}
                            accountOwner={yourBalance.owner}
                            date={element.createdAt}
                            description={element.senderNote}
                        />
                    ))}
                </>
            ) : (
                <p>No transactions found</p>
            )}
        </div>
    );
};

export default PicksTransactions;