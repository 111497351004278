import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 300,
    paddingBottom: 50,
    position: 'relative',
  },

  toolbar: {
    marginBottom: 20,
  },

  title: {
    flexGrow: 1,
  },

  placeholder: {
    textAlign: 'center',
  },
}));

export default useStyles;