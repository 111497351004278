import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFF',
    border: '2px solid #ffb15d',
    borderRadius: 10,
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop:15,
    overflow: 'hidden',
    position: 'relative',
    height: 'fit-content',
  },

  body: {
    flexGrow: 1,
    padding: 15,
    paddingTop: 5,
  },

}));

export default useStyles;