// React
import React from 'react';

// Material UI
import { Button } from '@material-ui/core';

// Redux
import { useSelector } from 'react-redux';

// Utils
import { DEFAULT_CATEGORY } from '../../../../../../../store/suggestEventSlice';

const SendRequestButton = ({ suggestEvent }) => {
  const title = useSelector((state) => state.suggestEvent.title);
  const category = useSelector((state) => state.suggestEvent.category);
  const timeZone = useSelector((state) => state.suggestEvent.timeZone);
  const place = useSelector((state) => state.suggestEvent.place);
  const invalidDate = useSelector((state) => state.suggestEvent.invalidDate);
  const invalidTime = useSelector((state) => state.suggestEvent.invalidTime);

  const isEnabled = () => {
    return (
      title &&
      timeZone &&
      place &&
      category &&
      title.trim().length > 0 &&
      timeZone.trim().length > 0 &&
      place.trim().length > 0 &&
      category.trim().length > 0 &&
      category !== DEFAULT_CATEGORY &&
      !invalidDate &&
      !invalidTime
    );
  };

  return (
    <Button
      disableElevation
      disableRipple
      variant="contained"
      style={{
        textTransform: 'none',
        color: 'white',
        backgroundColor: '#F17023',
        borderRadius: '24px',
        height: '52px',
        width: '145px',
        padding: '12px 16px',
        opacity: isEnabled() ? '1' : '0.5',
        fontWeight: 700,
      }}
      disabled={!isEnabled()}
      onClick={suggestEvent}
    >
      Send request
    </Button>
  );
};

export default SendRequestButton;
