import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Toolbar, IconButton, Typography } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { listToolAPI } from '../../../../apis/ToolAPI';
import AppLoader from '../../../../components/AppLoader';
import { DashboardPlaceholder } from '../../components';
import ToolCard from './ToolCard';
import useStyles from './ToolAreaStyle.js';
import {UseCustomSnackbar} from '../../../../utils/CustomSnackbar';

class ToolModel {
  id = Date.now();
  toolName = '';
  toolTooltip = '';
  url = '';
  area = '';
  srcicon = null;
  srciconcolored = null;
  position = 1; // It's required only by the API
  date = new Date().toLocaleDateString(); // It's required only by the API
  isNew = true;
}

export default function ToolArea() {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const uiClasses = useStyles();

  // Load the list data
  const loadList = async (enqueueSnackbar, closeSnackbar) => {
    setLoading(true);

    try {
      const response = await listToolAPI();
      setList(response.data);
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }

    setLoading(false);
  };

  useEffect(() => {
    loadList(enqueueSnackbar,closeSnackbar);
  }, [enqueueSnackbar, closeSnackbar]);

  // Handle the new element adding
  const onAdd = () => {
    const cloned = [ ...list ];
    cloned.push(new ToolModel());
    setList(cloned);
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
      })
  };  

  // Handle new item removing after the cancel action
  const onRemoveUnsaved = id => {
    const filtered = list.filter(item => item.id !== id);
    setList(filtered);
  };

  const cardList = list.map((item, i) => <ToolCard
    key={item.id}
    tool={item}
    counter={i + 1}
    onReload={() => loadList(enqueueSnackbar, closeSnackbar)}
    onRemoveUnsaved={() => onRemoveUnsaved(item.id)}
  />);

  return (
    <div className={uiClasses.root}>
      <Toolbar variant="dense" className={uiClasses.toolbar}>
        <Typography variant="h5" className={uiClasses.title}>
          Tool List
        </Typography>

        <IconButton onClick={ onAdd }>
          <AddIcon />
        </IconButton>
      </Toolbar>

      { cardList.length ? cardList : <DashboardPlaceholder /> }

      {loading && <AppLoader />}
    </div>
  );

}
