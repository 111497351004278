// React
import React from 'react';

// Components
import CancelButton from '../components/CancelButton';
import OkButton from '../components/OkButton';

// Redux
import { useDispatch } from 'react-redux';
import { discardChanges } from '../../../../../../../store/customizeRepeatSlice';

const CustomizeRepeatFooter = () => {
  const dispatch = useDispatch();

  return (
    <div style={{ display: 'flex', flexDirection: 'row', columnGap: '30px' }}>
      <CancelButton fn={() => dispatch(discardChanges())}></CancelButton>
      <OkButton></OkButton>
    </div>
  );
};

export default CustomizeRepeatFooter;
