import { useState } from 'react';
import AppLoader from '../../../../components/AppLoader';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import useStyles from './ItemCardStyle';
import ItemMenuCardActions from "./ItemMenuCardActions"

export default function ItemCard(props) {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const uiClasses = useStyles();

  const onConfirm = () => {
    setDeleteConfirmOpen(false);
    props.onDelete();
  };

  return (
    <div className={uiClasses.root}>
      <div className={uiClasses.body}>
        {props.body}
      </div>

      <ItemMenuCardActions
        editMode={props.editMode}
        isFormValid={props.isFormValid}
        onDelete={() => setDeleteConfirmOpen(true)}
        onEdit={props.onEdit}
        onCancel={props.onCancel}
        onSave={props.onSave}
      />

      {props.loading && <AppLoader />}

      <ConfirmDialog
        message={props.deleteConfirmMessage}
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        onConfirm={onConfirm}
      />
    </div>
  );
};
