import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./doodle.scss";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import Tooltip from "@material-ui/core/Tooltip";
import SwiperCore, { Autoplay, Pagination } from "swiper/core";
import Box from "@material-ui/core/Box";
import { homepageAllDoodleAPI } from "../../../../apis/DoodleAPI";
import { useSnackbar } from "notistack";
import { useStyles } from "../../style";
import {UseCustomSnackbar} from '../../../../utils/CustomSnackbar';

SwiperCore.use([Autoplay, Pagination]);


export default function Doodle() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [windowDimensions, setWindowDimensions] = useState(document.body.clientWidth);
  const [listDoodle, setListDoodle] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const classes = useStyles();

  function handleResize() {
    setWindowDimensions(document.body.clientWidth);
  }

  const Logo = () => <img id="doodle-logo" className="imgSorint" src="LogoSorint.png" alt="Sorint Lab" />;

  function getDoodleImage(item) {
    return windowDimensions > 960 ? item.desktopImage : item.mobileImage;
  }

  const Doodle = () => (

    <div id="doodle-slider" className="heigth-initial">
      <Swiper
        pagination={{clickable: true}}
        modules={[Pagination]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        align-items="center"
        justify-content="center"
        className="doodle-swiper"
      >
        {listDoodle.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <Tooltip title={item.tooltip}>
                <a href={item.url}>
                  <div className={classes.doodleImage} style={{ background: "url(" +  getDoodleImage(item) + ") no-repeat center", backgroundSize: "contain" }}></div>
                </a>
              </Tooltip>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );

  // Load the list data
  const loadList = async (enqueueSnackbar, closeSnackbar) => {
    try {
      const response = await homepageAllDoodleAPI();
      setListDoodle(response.data);
      setIsEmpty(response.data.length === 0 ? true : false);
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }
  };

  useEffect(() => {
    loadList(enqueueSnackbar, closeSnackbar);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [enqueueSnackbar, closeSnackbar]);

  return (
    <Box id="logo-search" height="min-content">
      {isEmpty ? <Logo /> : <Doodle />}
    </Box>
  );
}
