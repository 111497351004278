import { getDivestments, loadConvertibleSum } from '@/apis/PicksAPI';
import { Chip } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';

const DefaultAmount = 5;
const MinAmount = 5;
const ConversionStep = 5;
const RequestHeader = 'Richiesta conversione';
const RequestMessage = 'Conversione picks di "<NAME>"';

function makePicksPayload(amount, account, senderNotes, receiverNotes) {
  const payload = {
    receiverID: account,
    amount,
    url: document.location.origin,
    senderNotes,
    receiverNotes,
  };
  return btoa(JSON.stringify(payload));
}

const PicksConvert = ({ updateBalance, yourBalance }) => {
  const [status, setStatus] = useState('loading');
  const [availablePicks, setAvailablePicks] = useState(0);
  const [environment, setEnvironment] = useState({
    picksConvertAccount: '',
    picksConvertUrl: '',
  });

  useEffect(() => {
    async function loadEnvironment() {
      try {
        const response = await fetch('/config/environment.json');
        const env = await response.json();
        if (env.picksConvertAccount.trim() === '') {
          throw new Error('No account found for picks conversion');
        } else {
          return env;
        }
      } catch (error) {
        throw new Error('Error while loading environment', error);
      }
    }

    async function loadAvailablePicks() {
      try {
        const response = await loadConvertibleSum(yourBalance.id);
        return response.amount;
      } catch (error) {
        console.error('Error while loading convertible picks', error);
        throw new Error('Error while loading convertible picks');
      }
    }

    async function load() {
      try {
        let status = 'idle';
        const env = await loadEnvironment();
        const amount = await loadAvailablePicks();

        setAvailablePicks(amount);
        setEnvironment(env);

        if (amount === 0) {
          status = 'no-picks';
        }
        setStatus(status);
      } catch (error) {
        console.error('Error while loading picks conversion', error);
        setStatus('error');
      }
    }

    load();
  }, []);

  const [amount, setAmount] = useState(DefaultAmount);

  const onSubmit = async (event) => {
    event.preventDefault();
    setStatus('confirmation');
  };

  const onCancel = () => {
    setStatus('idle');
  };

  const picksUrl = useMemo(() => {
    const message = RequestMessage.replace('<NAME>', yourBalance.description);
    const payload = makePicksPayload(
      amount,
      environment.picksConvertAccount,
      RequestHeader,
      message
    );
    return `${environment.picksConvertUrl}/${payload}`;
  }, [amount, environment]);

  const maxAmount = useMemo(() => {
    return Math.floor(availablePicks / ConversionStep) * ConversionStep;
  });

  return (
    <div className="picks-divest-list-container text-left">
      {status === 'loading' ? <p className="mt-5">Loading...</p> : null}
      {status === 'no-picks' ? <p className="mt-5">You don't have any picks to convert.</p> : null}
      {status === 'error' ? <p className="mt-5">Something went wrong... try again later!</p> : null}
      {status === 'error-no-account' ? (
        <p className="mt-5">No account found for picks conversion.</p>
      ) : null}
      {status === 'idle' ? (
        <>
          <div>
            Convert your Picks into gift cards quickly and securely. After the transaction is
            complete, we will process your request and send you a gift code a code within the next day(s).
          </div>
          <div className="picks-divest-total">
            You can currently convert:
            <h2 className="picks-invest-amount">{availablePicks}</h2>
            PKC
          </div>
          <div className="mt-5 w-full">
            <form onSubmit={onSubmit}>
              <div className="">
                <div className="text-left">
                  <label htmlFor="amount">Select the amount to convert</label>
                </div>
                <div class="grid grid-cols-2  grid-rows-3 gap-2 w-full justify-center py-2">
                  {[5, 10, 20, 25, 50].map((q, index) => (
                    <Chip
                      label={q + ' PKC'}
                      onClick={() => {
                        setAmount(q);
                      }}
                    />
                  ))}
                  <div className="text-right flex gap-2 justify-end items-center">
                    Custom:
                    <input
                    className='w-6/12'
                      type="number"
                      id="amount"
                      name="amount"
                      min={MinAmount}
                      max={maxAmount}
                      step={ConversionStep}
                      required
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex mt-5 gap-2 justify-end w-full">
                <button type="submit" disabled={amount < 5 || amount > maxAmount} className="btn">
                  Convert
                </button>
              </div>
            </form>
          </div>
        </>
      ) : null}
      {status === 'confirmation' ? (
        <>
          <div>
            By pressing Yes, you will be redirected to the Picks payment gateway to finalize the conversion request.
            Once completed, we will process the conversion request and send you your gift card within the next day(s).
          </div>
          <div>
            <p className="mt-5 items-center flex">
              Are you sure you want to convert{' '}
              <span className="text-2xl mx-[5px] font-bold">{amount}</span> PKC?
            </p>
            <div className="flex mt-5 gap-2 justify-end w-full">
              <a href={picksUrl} className="btn">
                Yes
              </a>
              <button className="btn" onClick={() => setStatus('idle')}>
                No
              </button>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default PicksConvert;
