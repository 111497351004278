import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-end',
      flexDirection: 'row',
    },

    '& .MuiInputBase-root.Mui-disabled': {
      color: 'black',
    },
  },

  cardSubList: {
    display: 'flex',
    flexDirection: 'column',

    '& div': {
      border: 'none',
      backgroundColor: '#efefef',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },

    '& .MuiFab-sizeSmall': {
      minHeight: 22,
      maxHeight: 22,
      minWidth: 22,
      maxwidth: 22,
      margin: 5,
    },
  },

  name: {
    marginTop: 10,
  },

  url: {
    flexGrow: 1,
    marginTop: 10,

    [theme.breakpoints.up('lg')]: {
      width: 'auto',
    },
  },
}));

export default useStyles;
