import { makeStyles } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

const stepperTheme = createTheme({
  typography: {
    fontFamily: ['Quicksand', 'sans-serif'],
  },
  overrides: {
    MuiStepIcon: {
      root: {
        color: '#82ae87',
        '&$completed': {
          color: '#82ae87',
        },
      },
    },
  },
});

const greenTheme = createTheme({ palette: { primary: { main: '#82ae87' } } });

const ModalStyle = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 600,
    height: 350,
    backgroundImage: `url('/assets/treedom/modalBackground.png')`,
    backgroundSize: 'cover',
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
    fill: '#4f4f4f',
  },
}));

const StepperStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    padding: '24px 0 0 0',
    backgroundColor: 'transparent',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  content: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
}));

export { stepperTheme, greenTheme, ModalStyle, StepperStyle };
