import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './AppLoaderStyle.js';

export default function AppLoader(props) {
  const uiClasses = useStyles();

  const fullPage = props?.fullPage ?? true;

  return (
    <div
      className={`${uiClasses.loaderWithBlur} ${uiClasses.root} ${
        fullPage ? uiClasses.loaderFullPage : ''
      }`}
    >
      <CircularProgress
        variant="indeterminate"
        size={68}
        thickness={4}
        className={uiClasses.fabProgress}
      />
    </div>
  );
}
