export const dashboardItems = [
  {
    title: 'Doodle',
    url: 'doodle',
    icon: 'filter',
  },
  {
    title: 'Left Menu',
    url: 'left-menu',
    icon: 'clear_all',
  },
  {
    title: 'Tools',
    url: 'tools',
    icon: 'grid_on',
  },
  {
    title: 'Treedom',
    url: 'treedom',
    icon: 'park'
  }
];
