// React
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Material UI
import { Button } from '@material-ui/core';

// Components
import ErrorPage from './pages/ErrorPage';
import AcceptedPage from './pages/AcceptedPage';
import DeclinedPage from './pages/DeclinedPage';
import AppLoader from '../../../components/AppLoader';
import CategoryItem from '../../homepage/components/Calendar/components/Categories/components/CategoryItem';

// Icons
import {
  CalendarTodayOutlined,
  LocationOnOutlined,
  Repeat,
  FormatAlignCenter,
  Close,
  Done,
} from '@material-ui/icons';

// APIs
import { getEventAPI, acceptEventAPI, declineEventAPI } from '../../../apis/CalendarAPI';

// Utils
import { format, parseISO } from 'date-fns';
import { fromRruleToNaturalLanguage } from '../../../utils/calendarUtils';
import { Anchorme } from 'react-anchorme';

// Styles
import styles from './AcceptDeclineCalendarEvent.module.scss';

const AcceptDeclineCalendarEvent = (props) => {
  // url params
  const { token, eventID } = useParams();

  // loader flag
  const [isLoading, setIsLoading] = useState(true);
  // error flag
  const [isError, setIsError] = useState(false);
  // accepted flag
  const [isAccepted, setIsAccepted] = useState(false);
  // declined flag
  const [isDeclined, setIsDeclined] = useState(false);

  const [event, setEvent] = useState({});
  const [showMore, setShowMore] = useState(false);

  const loadEvent = useCallback(async (token, eventID) => {
    try {
      const { data: _event } = await getEventAPI(token, eventID);

      if (!_event.formattedEvent.allDay && !_event.formattedEvent.end)
        _event.formattedEvent.end = new Date(parseISO(_event.formattedEvent.start));

      const end = new Date(parseISO(_event.formattedEvent.end));

      setEvent({
        eventRequest: _event.eventRequest,
        ..._event.formattedEvent,
        end: _event.formattedEvent.allDay ? new Date(end).setDate(end.getDate() - 1) : end,
        start: new Date(parseISO(_event.formattedEvent.start)),
      });
    } catch (_) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!token || !eventID) setIsError(true);
    else loadEvent(token, eventID);
  }, [token, eventID, loadEvent]);

  const acceptEvent = async () => {
    try {
      setIsLoading(true);
      await acceptEventAPI({
        suggestData: {
          eventID: eventID,
          suggestToken: token,
        },
        eventRequest: { ...event.eventRequest },
      });
      setIsAccepted(true);
    } catch (_) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const declineEvent = async () => {
    try {
      setIsLoading(true);
      await declineEventAPI({
        eventID: eventID,
        suggestToken: token,
      });
      setIsDeclined(true);
    } catch (_) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const ModalTitle = () => {
    return <div style={{ lineHeight: '25px' }}>Appointment details</div>;
  };

  const EventTitle = ({ title }) => {
    return <div style={{ fontSize: '39px' }}>{title}</div>;
  };

  const EventTimeZone = ({ start }) => {
    return <div>{Intl.DateTimeFormat(start).resolvedOptions().timeZone}</div>;
  };

  const EventRepeat = ({ repeated }) => {
    return <div style={{ lineHeight: '24px', wordBreak: 'break-word' }}>{repeated}</div>;
  };

  const EventLocation = ({ location }) => {
    return (
      <div style={{ lineHeight: '24px', wordBreak: 'break-all' }}>
        <Anchorme target="_blank">{location}</Anchorme>
      </div>
    );
  };

  return (
    <div>
      {isLoading && <AppLoader></AppLoader>}
      {!isLoading && !isError && !isAccepted && !isDeclined && (
        <div className={styles.container}>
          <div className={styles.container__header}>
            <ModalTitle></ModalTitle>
          </div>
          <div className={styles.container__content}>
            <div className={styles.container__content__body}>
              <EventTitle title={event.title}></EventTitle>
              <div className={styles.category}>
                <CalendarTodayOutlined htmlColor="transparent"></CalendarTodayOutlined>
                <CategoryItem
                  category={event.calendar.calendarName}
                  color={event.calendar.color}
                ></CategoryItem>
              </div>
              <div className={styles.datetime}>
                <CalendarTodayOutlined htmlColor="#F17023"></CalendarTodayOutlined>
                <div className={styles.datetime__container}>
                  <div className={styles.datetime__container__date}>
                    {event.start && (
                      <div className={styles.datetime__container__date__box}>
                        <span className={styles.datetime__container__date__box__label}>Start</span>
                        <div className={styles.datetime__container__date__box__dates}>
                          <div>{format(event.start, 'EEEE dd')}</div>
                          <div>{format(event.start, 'MMM yyyy')}</div>
                        </div>
                        {!event.allDay && <div>{format(event.start, 'HH:mm')}</div>}
                      </div>
                    )}
                    {event.end && (
                      <div className={styles.datetime__container__date__box}>
                        <span className={styles.datetime__container__date__box__label}>End</span>
                        <div className={styles.datetime__container__date__box__dates}>
                          <div>{format(event.end, 'EEEE dd')}</div>
                          <div>{format(event.end, 'MMM yyyy')}</div>
                        </div>
                        {!event.allDay && <div>{format(event.end, 'HH:mm')}</div>}
                      </div>
                    )}
                  </div>
                  <EventTimeZone></EventTimeZone>
                </div>
              </div>
              {fromRruleToNaturalLanguage(event.rrule) && (
                <div className={styles.repeated}>
                  <Repeat htmlColor="#F17023"></Repeat>
                  <EventRepeat repeated={fromRruleToNaturalLanguage(event.rrule)}></EventRepeat>
                </div>
              )}
              {event.location && (
                <div className={styles.location}>
                  <LocationOnOutlined htmlColor="#F17023"></LocationOnOutlined>
                  <EventLocation location={event.location}></EventLocation>
                </div>
              )}
              {event.description && (
                <div className={styles.description}>
                  <FormatAlignCenter htmlColor="#F17023"></FormatAlignCenter>
                  <div>
                    {!showMore ? event.description.substring(0, 80) : event.description}
                    {event.description.substring() > event.description.substring(0, 80) && (
                      <span
                        onClick={() => setShowMore(!showMore)}
                        style={{
                          color: '#FB8D4B',
                          weight: '700',
                          cursor: 'pointer',
                        }}
                      >
                        {!showMore ? ' Show more' : ' Show less'}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={styles.container__content__footer}>
              <Button
                disableElevation
                disableRipple
                variant="contained"
                startIcon={<Close style={{ width: '27px', height: '27px' }} />}
                style={{
                  textTransform: 'none',
                  color: '#F17023',
                  backgroundColor: 'white',
                  border: '2px solid #F17023',
                  borderRadius: '24px',
                  height: '50px',
                  width: '144px',
                  padding: '12px 24px',
                  fontSize: '16px',
                  fontWeight: 700,
                }}
                onClick={declineEvent}
              >
                Decline
              </Button>
              <Button
                disableElevation
                disableRipple
                variant="contained"
                startIcon={<Done style={{ width: '27px', height: '27px' }} />}
                style={{
                  textTransform: 'none',
                  color: 'white',
                  backgroundColor: '#F17023',
                  border: '2px solid #F17023',
                  borderRadius: '24px',
                  height: '50px',
                  width: '144px',
                  padding: '12px 24px',
                  fontSize: '16px',
                  fontWeight: 700,
                }}
                onClick={acceptEvent}
              >
                Accept
              </Button>
            </div>
          </div>
        </div>
      )}
      {isError && <ErrorPage></ErrorPage>}
      {isAccepted && <AcceptedPage></AcceptedPage>}
      {isDeclined && <DeclinedPage></DeclinedPage>}
    </div>
  );
};

export default AcceptDeclineCalendarEvent;
