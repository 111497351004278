import { createSlice } from '@reduxjs/toolkit';

// Utils
import momentTZ from 'moment-timezone';

export const DEFAULT_CATEGORY = 'Add category';

export const suggestEventSlice = createSlice({
  name: 'suggestEvent',
  initialState: {
    title: '',
    startDateTime: new Date(new Date().setSeconds(0)),
    endDateTime: new Date(new Date().setSeconds(0)),
    isAllDay: false,
    timeZone: momentTZ.tz.guess(),
    category: DEFAULT_CATEGORY,
    description: '',
    place: '',
    invalidDate: false,
    invalidTime: false,
  },
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setStartDateTime: (state, action) => {
      state.startDateTime = action.payload;
    },
    setEndDateTime: (state, action) => {
      state.endDateTime = action.payload;
    },
    setIsAllDay: (state, action) => {
      state.isAllDay = action.payload;
    },
    setTimeZone: (state, action) => {
      state.timeZone = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setPlace: (state, action) => {
      state.place = action.payload;
    },
    setInvalidDate: (state, action) => {
      state.invalidDate = action.payload;
    },
    setInvalidTime: (state, action) => {
      state.invalidTime = action.payload;
    },
    resetData: (state, _) => {
      state.title = '';
      state.startDateTime = new Date(new Date().setSeconds(0));
      state.endDateTime = new Date(new Date().setSeconds(0));
      state.isAllDay = false;
      state.timeZone = momentTZ.tz.guess();
      state.category = DEFAULT_CATEGORY;
      state.description = '';
      state.place = '';
    },
  },
});

export const {
  setTitle,
  setStartDateTime,
  setEndDateTime,
  setIsAllDay,
  setTimeZone,
  setCategory,
  setDescription,
  setPlace,
  setInvalidDate,
  setInvalidTime,
  resetData,
} = suggestEventSlice.actions;

export default suggestEventSlice.reducer;
