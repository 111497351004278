import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import {List, ListItemText, Icon} from "@material-ui/core";
import { dashboardItems } from "../costants";
import { useStyles, ListItem } from "../style";

export default function DrawerListItems() {
  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = useState(null);

  // Restore menu when the page load
  const path = useLocation().pathname.replace(/\/dashboard\//g, '');

  return (
    <>
      <List style={{ flexGrow: "1" }}>
        {dashboardItems.map((item, index) => (
          <ListItem
            key={item.url}
            component={Link}
            to={"/dashboard/" + item.url}
            selected={path === item.url || selectedIndex === index}
            onClick={() => setSelectedIndex(index)}
          >
            <Icon className={classes.drawerIconItem}>{item.icon} </Icon>
            <ListItemText className={classes.drawerTextItem} primary={item.title} />
          </ListItem>
        ))}
      </List>
    </>
  );
}
