// React
import React, { useEffect, useState } from 'react';

// Material UI
import { Box, CircularProgress } from '@material-ui/core';

// Components
import EventItem from './components/EventItem';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setEvents, setSelectedDate } from '../../../../../../store/calendarSlice';

// API
import { getEventsAPI } from '../../../../../../apis/CalendarAPI';

// Utils
import { useSnackbar } from 'notistack';
import { useSwipeable } from 'react-swipeable';
import {UseCustomSnackbar} from '../../../../../../utils/CustomSnackbar';

// Styles
import styles from './EventsList.module.scss';

const EventsList = ({ month, openModal }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const visibleCategories = useSelector((state) => state.calendar.visibleCategories);
  const visibleEvents = useSelector((state) => state.calendar.visibleEvents);

  const [isLoadingEvents, setIsLoadingEvents] = useState(true);

  const loadEvents = async (enqueueSnackbar, params) => {
    try {
      setIsLoadingEvents(true);
      if (params && params.length > 0) {
        const response = await getEventsAPI(params.join(','));
        dispatch(setEvents(response.data));
      }
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    } finally {
      setIsLoadingEvents(false);
    }
  };

  // load events after every change of visible categories
  useEffect(() => {
    loadEvents(enqueueSnackbar, visibleCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleCategories, enqueueSnackbar]);

  const selectedDate = useSelector((state) => state.calendar.selectedDate);

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      dispatch(
        setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1))
      ),
    onSwipedRight: () => {
      if (
        selectedDate.getFullYear() >= new Date().getFullYear() &&
        selectedDate.getMonth() - 1 > new Date().getMonth()
      ) {
        dispatch(
          setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1, 1))
        );
      } else {
        dispatch(setSelectedDate(new Date(new Date().setHours(0, 0, 0, 0))));
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <Box className={styles.container}>
      <Box className={styles.container__header}>
        Sorint <span style={{ fontWeight: '700', lineHeight: '36px' }}>{month}</span> Appointments
      </Box>

      <Box className={styles.container__body} {...handlers}>
        {!isLoadingEvents && visibleEvents.length > 0 ? (
          visibleEvents.map((event, index) => (
            <EventItem
              color={event.calendar.color}
              openModal={() =>
                openModal({
                  ...event,
                })
              }
              event={event}
              key={`${event.calendar.calendarName}-${index}`}
            ></EventItem>
          ))
        ) : (isLoadingEvents && visibleEvents.length === 0) ||
          (isLoadingEvents && visibleEvents.length > 0) ? (
          <CircularProgress style={{ alignSelf: 'center', marginTop: '50%' }}></CircularProgress>
        ) : (
          <Box style={{ color: '#5A6972', fontWeight: '600' }}>There aren't events</Box>
        )}
      </Box>
    </Box>
  );
};

export default EventsList;
