// React
import React, { useState } from 'react';

// Components
import SuggestEventBody from './elements/SuggestEventBody';
import CustomizeRepeatBody from './elements/CustomizeRepeatBody';
import CustomizeRepeatFooter from './elements/CustomizeRepeatFooter';
import ConfirmDialog from './elements/ConfirmDialog';
import SuccessDialog from './elements/SuccessDialog';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Utils
import {
  IS_NOT_REPEATED,
  resetData as resetDataCustomizeRepeat,
  setShowCustomizeRepeat
} from '../../../../../../store/customizeRepeatSlice';
import { resetData as resetDataSuggestEvent } from '../../../../../../store/suggestEventSlice';
import { formatDateForAPI, createRrule } from '../../../../../../utils/calendarUtils';
import { useSnackbar } from 'notistack';
import { UseCustomSnackbar } from '../../../../../../utils/CustomSnackbar';

// APIs
import { suggestEventAPI } from '../../../../../../apis/CalendarAPI';
import SuggestEventFooter from './elements/SuggestEventFooter';

// Styles
import styles from './SuggestEventModal.module.scss';
import 'animate.css';

const SuggestEventModal = ({ closeSuggest }) => {
  const [animationClass, setAnimationClass] = useState('animate__fadeInLeft');
  const [confirmRequest, setConfirmRequest] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);

  const title = useSelector((state) => state.suggestEvent.title);
  const startDateTime = useSelector((state) => state.suggestEvent.startDateTime);
  const endDateTime = useSelector((state) => state.suggestEvent.endDateTime);
  const isAllDay = useSelector((state) => state.suggestEvent.isAllDay);
  const category = useSelector((state) => state.suggestEvent.category);
  const description = useSelector((state) => state.suggestEvent.description);
  const place = useSelector((state) => state.suggestEvent.place);
  const timeZone = useSelector((state) => state.suggestEvent.timeZone);

  const repeated = useSelector((state) => state.customizeRepeat.repeated);
  const repeatNumber = useSelector((state) => state.customizeRepeat.repeatNumber);
  const repeatPeriod = useSelector((state) => state.customizeRepeat.repeatPeriod);
  const ending = useSelector((state) => state.customizeRepeat.ending);
  const endingDate = useSelector((state) => state.customizeRepeat.endingDate);
  const selectedDays = useSelector((state) => state.customizeRepeat.selectedDays);

  const showCustomizeRepeat = useSelector((state) => state.customizeRepeat.showCustomizeRepeat);
  const dispatch = useDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const suggestEvent = async () => {
    try {
      const data = {
        title: title,
        calendarName: category,
        location: place,
        description: description && description.trim().length > 0 ? description : null,
        start: formatDateForAPI(startDateTime, timeZone, isAllDay, false),
        end: formatDateForAPI(endDateTime, timeZone, isAllDay, true),
        rrule: createRrule(
          repeated !== IS_NOT_REPEATED,
          repeatNumber,
          repeatPeriod.key,
          selectedDays.length > 0 ? selectedDays.map((value) => value.short).join(',') : null,
          ending === 'true' ? formatDateForAPI(endingDate, timeZone, false, false) : null
        ),
      };

      const response = await suggestEventAPI(data);
      if (response.status === 200) {
        dispatch(resetDataCustomizeRepeat());
        dispatch(resetDataSuggestEvent());
        setSuccessDialog(true);
        setTimeout(() => {
          setConfirmRequest(false);
          setSuccessDialog(false);
          closeSuggest();
        }, 3000);
      }
    } catch (error) {
      setConfirmRequest(false);
      setSuccessDialog(false);
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }
  };

  return (
    <div className={`${styles.container} animate__animated ${animationClass}`}>
      <div className={styles.container__content}>
        <div className={styles.container__content__body}>
          {!showCustomizeRepeat && !confirmRequest ? (
            <SuggestEventBody></SuggestEventBody>
          ) : showCustomizeRepeat && !confirmRequest ? (
            <CustomizeRepeatBody></CustomizeRepeatBody>
          ) : !successDialog ? (
            <ConfirmDialog
              suggestEvent={suggestEvent}
              closeConfirmDialog={() => setConfirmRequest(false)}
            ></ConfirmDialog>
          ) : (
            <SuccessDialog></SuccessDialog>
          )}
        </div>
        <div className={styles.container__content__footer}>
          {!showCustomizeRepeat && !confirmRequest ? (
            <SuggestEventFooter
              cancelFn={() => {
                setAnimationClass('animate__fadeOutLeft');
                setTimeout(() => {
                  closeSuggest();
                  dispatch(setShowCustomizeRepeat(false));
                  document.getElementById('calendar-container-body').style.position = 'relative';
                  document.getElementById('calendar-container-body-close').style.visibility =
                    'unset';
                }, 500);
              }}
              event={() => setConfirmRequest(true)}
            ></SuggestEventFooter>
          ) : showCustomizeRepeat && !confirmRequest ? (
            <CustomizeRepeatFooter></CustomizeRepeatFooter>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default SuggestEventModal;
