// React
import React from 'react';

// Components
import DescriptionField from '../components/DescriptionField';
import PlaceField from '../components/PlaceField';

// Styles
import styles from './DetailsSection.module.scss';

const DetailsSection = () => {
  return (
    <div className={styles.container}>
      <PlaceField></PlaceField>
      <DescriptionField></DescriptionField>
    </div>
  );
};

export default DetailsSection;
