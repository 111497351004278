import React, {useEffect, useState} from 'react';
import '../../Navbar.scss';
import PicksLogIn from './PicksLogIn'
import PicksActivated from './PicksActivated'

const PicksModal = (props) => {

    const [picksActive, setPickActive] = useState(props.isPicksActive);
    const [balance, setBalance] = useState(props.yourBalance);

    const refreshBalance = () => {
        props.updateBalance();
    }

    useEffect(() => {
        setPickActive(props.isPicksActive);
        setBalance(props.yourBalance);
    }, [props.isPicksActive, props.yourBalance]); 

    const picksActivated = () => {
        setPickActive(true);
    }

    if (!props.isOpen) return null;
    return (
        <div className="picks-modal-overlay" onClick={props.onClose}>
            <div id='picksModal' className="picks-modal-content" onClick={(e) => e.stopPropagation()}>
            {picksActive ? (
                <PicksActivated yourBalance={balance} updateBalance={props.updateBalance} />
            ) : (
                <PicksLogIn picksActivated={picksActivated} updateBalance={refreshBalance}/>
            )}
            </div>
        </div>
    );
};

export default PicksModal;