import React, {useEffect, useState} from 'react';
import '../../Navbar.scss';
import { getInvestments } from '../../../../apis/PicksAPI';
import SingleInvestment from './SingleInvestment';

const PicksInvestments = ({yourBalance}) => {

    const [allInvestments, setAllInvestments] = useState(null);
    const [totalPicks, setTotalPicks] = useState(0);

    useEffect(() => {
        setTotalPicks(0);
        allInvestments && allInvestments.forEach((element) => {
            setTotalPicks((prevTotalPicks) => prevTotalPicks + element.picks);
        });
    }, [allInvestments]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const investResponse = await getInvestments(yourBalance.id);
                setAllInvestments(investResponse.data);
            } catch (error) {
                console.error("Errore durante il recupero degli investimenti", error);
            }
        };
        fetchData();
    }, [yourBalance]);

    return (
        <div className='picks-invest-container'>
            <div className='picks-invest-total'>
                Total invested Picks: <h2 className='picks-invest-amount'>{totalPicks}</h2> PCK
            </div>
            <div className='picks-invest-list'>
            <h3>Your investments:</h3>
            <div className="picks-invest-list-container">
            {allInvestments && allInvestments.length > 0 ? (
                <>
                    {allInvestments && allInvestments.map((element, index) => (
                        <>
                            <SingleInvestment 
                                key={element.id} 
                                recipient={element.description} 
                                amount={element.picks}
                            />
                        </>
                    ))}
                </>
            ) : (
                <p>No investments found</p>
            )}
            </div>
        </div>
    </div>
    );
};

export default PicksInvestments;