import { Button } from '@material-ui/core';

// Alert function
// Parameters:  "enqueueSnackbar" "closeSnackbar" from notistak library, 
//              "message" for the output message, 
//              "type" for different types of notistak buttons (tried: 'error', 'success', 'info')
export function UseCustomSnackbar(enqueueSnackbar, closeSnackbar, message, type){      
  return (        
    enqueueSnackbar(`${message}`,
      { 
        variant: type,
        action: (key) => (
          <Button
            variant="outlined"
            size='small'
            style={{
              textTransform: 'none',
              color: "#fff",
              borderColor: "#fff6",
            }}
            onClick={() => closeSnackbar(key)}>
            Dismiss
          </Button>
        )
      }
    )
  );
}