import React, { useState } from 'react';
import { Box, Paper, Divider, Grid  } from '@material-ui/core';
import { Highlight } from 'react-instantsearch-dom';
import { useStyles } from './person/style';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';

export default function Project42({ hit }) {
  const cls = useStyles();
  const [elevationPaper, setElevationPaper] = useState(0);
  let lastEditTimeStamp = new Date(hit.date);
  let lastEditDate = lastEditTimeStamp.toLocaleDateString();

  return (
    <Paper
      elevation={elevationPaper}
      onMouseOver={() => setElevationPaper(6)}
      onMouseOut={() => setElevationPaper(0)}
      className={cls.resultPaper}
    >
      <Box display="flex" flexDirection="column">
        <div className="hit-name">
          <a href={hit.url} target="_blank" rel="noreferrer">
            <Highlight attribute="title" hit={hit} />
          </a>
          <Highlight
            attribute="author"
            tagName={'strong'}
            hit={hit}
            className={cls.hitDescription}
          />
        </div>

        <div className="hit-info">
          Category: <strong>{hit.category}</strong>
        </div>
        <Divider
          style={{ height: 0.9, margin: '10px 0', backgroundColor: 'rgba(0, 0, 0, 0.12)' }}
        />

        <Grid container spacing={2}>
          <Grid item sm={12} md={3}>
            <div className={cls.divIconPlay}>
              <PlayCircleFilledWhiteIcon className={cls.iconPlayOverImg} />
            </div>
            <img
              href={hit.url}
              src={`/assets/default.webp`}
              alt={hit.title}
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', maxwidth: '90vw' }}
            />
          </Grid>
          <Grid item sm={12} md={9}>
            <div className={cls.hitDescription} style={{ fontSize: '0.9rem' }}>
              Last edit: <strong>{lastEditDate}</strong>
            </div>
            <div className="hit-info">
              <Highlight attribute="description" hit={hit} />
            </div>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
