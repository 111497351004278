import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../SorintTools.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import Item from './Item';

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: { display: 'block' },
  },
  sectionMobile: {
    display: 'block',
    [theme.breakpoints.up('sm')]: { display: 'none' },
  },
}));

const Grid2SwiperMode = ({ items }) => {
  const classes = useStyles();

  return (
    <div className={classes.sectionMobile} id={"mobile-grid3"}>
      <div className="grid-stack mobile-grid" id="grid3">
        <Swiper
          slidesOffsetAfter={15}
          slidesOffsetBefore={15}
          slidesPerView={3.5}
          centeredSlides={false}
          spaceBetween={15}
          freeMode={true}
          keyboard={{ enabled: true }}
          scrollbar={true}
          className="tools-swiper"
          >
          {items.map((item, i) => {
            return (
              <SwiperSlide key={item.id} className={'grid-stack-item'}>
                <div className="grid-stack-item-content">
                  <a href={item.url}>
                  <Item {...item} />
                  </a>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Grid2SwiperMode;
