// React
import React, { useEffect, useState } from 'react';

// Material UI
import { Box } from '@material-ui/core';

// Components
import BackButton from './components/BackButton';
import AgendaPicker from './components/AgendaPicker.js';
import Categories from './components/Categories/Categories';
import SuggestEventButton from './components/SuggestEventButton';
import EventsList from './components/EventsList/EventsList';
import SuggestEventModal from './components/SuggestEventModal/SuggestEventModal';
import DetailsEventModal from './components/DetailsEventModal/DetailsEventModal';

// Utils
import { format } from 'date-fns';

// Redux
import { useSelector } from 'react-redux';

// style
import styles from './Calendar.module.scss';

const Calendar = () => {
  const selectedDate = useSelector((state) => state.calendar.selectedDate);

  const [suggestOpen, setSuggestOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [eventDetails, setEventDetails] = useState({});

  const openDetailsModal = (event) => {
    setEventDetails(event);
    setDetailsOpen(true);
  };

  useEffect(() => {
    if (!suggestOpen) {
      document.getElementById('calendar-container-body').style.position = 'relative';
      document.getElementById('calendar-container-body-close').style.visibility = 'unset';
    }
  }, [suggestOpen]);

  return (
    <Box className={styles.container}>
      {suggestOpen && <Box className={styles['suggest-event-overlay']}></Box>}
      {detailsOpen && (
        <DetailsEventModal
          isOpen={detailsOpen}
          closeModal={() => setDetailsOpen(false)}
          event={eventDetails}
        ></DetailsEventModal>
      )}
      <Box className={styles.container__body} id="calendar-container-body">
        <Box className={styles['container__body__close-button']} id="calendar-container-body-close">
          <BackButton></BackButton>
        </Box>
        <Box className={styles.container__body__left}>
          <AgendaPicker></AgendaPicker>
          <Box
            style={{
              width: '327px',
              border: '1px solid #F4F5F6',
              marginTop: '30px',
            }}
          ></Box>
          <Box style={{ width: '100%', maxWidth: '350px', marginTop: '31px' }}>
            <Categories></Categories>
          </Box>
          <Box className={styles['container__body__left__suggest-event-button']}>
            <SuggestEventButton
              isDisabled={suggestOpen}
              openSuggest={() => setSuggestOpen(true)}
            ></SuggestEventButton>
          </Box>
        </Box>
        <Box
          className={`${styles.container__body__right} ${
            suggestOpen ? styles['container__body__right-suggestmodal'] : ''
          }`}
        >
          {suggestOpen ? (
            <SuggestEventModal closeSuggest={() => setSuggestOpen(false)}></SuggestEventModal>
          ) : (
            <EventsList
              month={format(selectedDate, 'MMMM')}
              openModal={(event) => openDetailsModal(event)}
            ></EventsList>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Calendar;
