import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import DeleteIcon from "@material-ui/icons/Delete";
import useStyles from "./SubItemCardStyle";

export default function SubItemCard(props) {
  const uiClasses = useStyles();

  return (
    <div className={uiClasses.root}>
      <div className={uiClasses.body}>
        <div className={uiClasses.row}>
          <TextField
            placeholder="Title *"
            fullWidth
            className={uiClasses.name}
            error={!props.itemMenu.title}
            disabled={!props.editMode}
            value={props.itemMenu.title}
            onChange={(ev) => props.onTitleChange(ev.target.value)}
          />

          <TextField
            placeholder="Url *"
            fullWidth
            className={uiClasses.url}
            error={!props.itemMenu.url}
            disabled={!props.editMode}
            value={props.itemMenu.url}
            onChange={(ev) => props.onUrlChange(ev.target.value)}
          />
        </div>
      </div>

      {props.editMode && (<Fab
        size="small"
        color="secondary"
        onClick={() => props.onDeleteSubItem(props.itemMenu.id)}
      >
        <DeleteIcon />
      </Fab>
      )}
    </div>
  );
}
