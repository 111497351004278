// React
import React from 'react';

// Components
import RangeDateTimePicker from '../components/RangeDateTimePicker';
import AllDaySwitchToggle from '../components/AllDaySwitchToggle';
import TimeZonePicker from '../components/TimeZonePicker';
import RepeatedPicker from '../components/RepeatedPicker';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setShowMoreOptions } from '../../../../../../../store/calendarSlice';

// Styles
import styles from './TimeSection.module.scss';

const TimeSection = () => {
  const dispatch = useDispatch();

  const showMoreOptions = useSelector((state) => state.calendar.showMoreOptions);

  return (
    <div className={styles.container}>
      <div>
        <RangeDateTimePicker></RangeDateTimePicker>
      </div>
      {showMoreOptions && (
        <div className={styles.container__hideable}>
          <div className={styles.container__hideable__box}>
            <AllDaySwitchToggle></AllDaySwitchToggle>
            <TimeZonePicker></TimeZonePicker>
          </div>
          <div className={styles.container__hideable__box}>
            <RepeatedPicker></RepeatedPicker>
          </div>
        </div>
      )}
      <span
        className={styles.options}
        onClick={() => dispatch(setShowMoreOptions(!showMoreOptions))}
      >
        {showMoreOptions ? 'Less' : 'More'} options
      </span>
    </div>
  );
};

export default TimeSection;
