import React, {useCallback, useMemo, useState} from 'react';
import 'instantsearch.css/themes/algolia-min.css';
import {Configure, connectSortBy, connectStateResults, InstantSearch, RefinementList} from 'react-instantsearch-dom';
import {instantMeiliSearch} from '@meilisearch/instant-meilisearch';
import CustomInfiniteHits from './components/CustomHits';
import SvgIcon from '@material-ui/core/SvgIcon';
import AppLoader from '../../../../components/AppLoader';
import './style.scss';
import DebouncedSearchBox from "@/pages/homepage/components/Search/components/DebouncedSearchBox";

export default function MeiliSearchTest({searchURL, keycloak, savatarURL}) {
    const [isSearchEnabled, setIsSearchEnabled] = useState(false);
    const [isRefined, setIsRefined] = useState(false);

    const setViewToShow = useCallback((isVisible) => {
        setIsSearchEnabled(isVisible);
        const toolsBox = document.getElementById('tools-box');
        if (toolsBox) {
            toolsBox.style.display = isVisible ? 'none' : 'initial';
        }
    }, []);

    // Create a memoized search client so that a new instance is not created on every render.
    const searchClient = useMemo(() => {
        return instantMeiliSearch(searchURL, '', {
            primaryKey: 'meili_id',
            searchParams: {
                attributesToHighlight: ['*'],
                highlightPreTag: '<em>',
                highlightPostTag: '</em>',
            },
            requestConfig: {
                headers: {Authorization: `Bearer ${keycloak.token}`},
                credentials: 'include'
            },
        }).searchClient;
    }, [searchURL, keycloak.token]);

    const handleSearchStateChange = useCallback((searchState) => {
        const query = searchState.query || '';
        if (query.length < 3) {
            setIsRefined(false);
            setViewToShow(false);
            const boxDS = document.getElementById('boxDoodleSearch');
            const ds = document.getElementById('doodle-slider');
            const dl = document.getElementById('doodle-logo');
            const showinRooms = document.getElementById('roomsContainer');
            if (boxDS) boxDS.style.display = 'flex';
            if (ds) ds.className = 'heigth-initial';
            if (dl) dl.style.display = 'flex';
            if (document.body.clientWidth > 1029 && showinRooms) {
                showinRooms.style.display = 'flex';
            }
        } else {
            setViewToShow(true);
            setIsRefined(searchState?.refinementList?.category?.length > 0);
            const boxDS = document.getElementById('boxDoodleSearch');
            const ds = document.getElementById('doodle-slider');
            const dl = document.getElementById('doodle-logo');
            const showinRooms = document.getElementById('roomsContainer');
            if (boxDS) boxDS.style.display = 'inline-block';
            if (ds) ds.className = 'heigth-zero';
            if (dl) dl.style.display = 'none';
            if (showinRooms) showinRooms.style.display = 'none';
        }
    }, [setViewToShow]);

    const indexName = 'highlands-idx';

    return (
        <div className="ais-InstantSearch">
            <InstantSearch
                key={`${searchURL}-${keycloak.token}`}
                indexName={indexName}
                searchClient={searchClient}
                onSearchStateChange={handleSearchStateChange}>
                <Configure hitsPerPage={15} attributesToHighlight={['*']}/>
                <HiddenSortBy
                    items={[
                        {label: 'Default (Priority)', value: 'highlands-idx:priority:asc'}
                    ]}
                    defaultRefinement={`${indexName}:priority:asc`}
                />
                <DebouncedSearchBox
                    autoFocus={true}
                    placeholder="Search contacts, documents, procedures ..."
                />
                <div className="search-panel">
                    {isSearchEnabled && <LoadingIndicator/>}
                    <div className="search-panel__filters">
                        {isSearchEnabled && <RefinementListCategory isRefined={isRefined}/>}
                    </div>
                    <div className="search-panel__results">
                        {isSearchEnabled && <Content savatarURL={savatarURL}/>}
                    </div>
                </div>
            </InstantSearch>
        </div>
    );
}

const HiddenSortBy = connectSortBy(() => null);

const LoadingIndicator = connectStateResults(({isSearchStalled}) =>
    isSearchStalled ? <AppLoader fullPage={false}/> : null
);

const Content = connectStateResults(({searchState, searchResults, savatarURL}) =>
    searchResults && searchResults.nbHits !== 0 ? (
        <CustomInfiniteHits savatarURL={savatarURL}/>
    ) : (
        <div className="hit-no-results">
            No results has been found for <strong>{searchState.query}</strong>
        </div>
    )
);

const RefinementListCategory = ({isRefined}) => {
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: '5px 0'
            }}
            id="refinementlist"
        >
            <details open={true}>
                <summary>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '5px'
                        }}
                    >
                        <span style={{fontWeight: 'bold'}}>category filters</span>
                        <SvgIcon>
                            {!isRefined ? (
                                <path
                                    d="M15,19.88C15.04,20.18 14.94,20.5 14.71,20.71C14.32,21.1 13.69,21.1 13.3,20.71L9.29,16.7C9.06,16.47 8.96,16.16 9,15.87V10.75L4.21,4.62C3.87,4.19 3.95,3.56 4.38,3.22C4.57,3.08 4.78,3 5,3V3H19V3C19.22,3 19.43,3.08 19.62,3.22C20.05,3.56 20.13,4.19 19.79,4.62L15,10.75V19.88M7.04,5L11,10.06V15.58L13,17.58V10.05L16.96,5H7.04Z"/>
                            ) : (
                                <path
                                    d="M14,12V19.88C14.04,20.18 13.94,20.5 13.71,20.71C13.32,21.1 12.69,21.1 12.3,20.71L10.29,18.7C10.06,18.47 9.96,18.16 10,17.87V12H9.97L4.21,4.62C3.87,4.19 3.95,3.56 4.38,3.22C4.57,3.08 4.78,3 5,3V3H19V3C19.22,3 19.43,3.08 19.62,3.22C20.05,3.56 20.13,4.19 19.79,4.62L14.03,12H14Z"/>
                            )}
                        </SvgIcon>
                    </div>
                </summary>
                <RefinementList
                    attribute="category"
                    transformItems={(items) =>
                        items.sort((a, b) => (a.label > b.label ? -1 : 1))
                    }
                />
            </details>
        </div>
    );
};
