import { createSlice } from '@reduxjs/toolkit';

export const IS_NOT_REPEATED = 'Is not repeated';
const CUSTOMIZE = 'Customize';

export const DEFAULT_REPEATED_OPTIONS = [IS_NOT_REPEATED, CUSTOMIZE];

export const DAYS = { key: 'DAILY', value: 'days' };
export const WEEKS = { key: 'WEEKLY', value: 'weeks' };
export const MONTHS = { key: 'MONTHLY', value: 'months' };
export const YEARS = { key: 'YEARLY', value: 'years' };

export const DEFAULT_PERIOD_OPTIONS = [DAYS, WEEKS, MONTHS, YEARS];

const DEFAULT_REPEAT_NUMBER = '1';

export const customizeRepeatSlice = createSlice({
  name: 'customizeRepeat',
  initialState: {
    showCustomizeRepeat: false,
    repeated: IS_NOT_REPEATED,
    repeatedOptions: DEFAULT_REPEATED_OPTIONS,
    repeatNumber: DEFAULT_REPEAT_NUMBER,
    repeatPeriod: DAYS,
    selectedDays: [],
    ending: 'false',
    endingDate: new Date().setHours(0, 0, 0, 0),
    backup: {
      repeatNumber: DEFAULT_REPEAT_NUMBER,
      repeatPeriod: DAYS,
      selectedDays: [],
      ending: 'false',
      endingDate: new Date(),
    },
  },
  reducers: {
    setShowCustomizeRepeat: (state, action) => {
      state.showCustomizeRepeat = action.payload;

      if (action.payload === true) {
        state.backup.repeatNumber = state.repeatNumber;
        state.backup.repeatPeriod = state.repeatPeriod;
        state.backup.selectedDays = [...state.selectedDays];
        state.backup.ending = state.ending;
        state.backup.endingDate = state.endingDate;
      }
    },
    setRepeated: (state, action) => {
      state.repeated = action.payload;
    },
    setRepeatedOptions: (state, action) => {
      state.repeatedOptions = [...action.payload];
    },
    setRepeatNumber: (state, action) => {
      state.repeatNumber = action.payload;
    },
    setRepeatPeriod: (state, action) => {
      state.repeatPeriod = action.payload;
    },
    setSelectedDays: (state, action) => {
      state.selectedDays = action.payload;
    },
    setEnding: (state, action) => {
      state.ending = action.payload;
    },
    setEndingDate: (state, action) => {
      state.endingDate = action.payload;
    },
    discardChanges: (state, _) => {
      state.repeatNumber = state.backup.repeatNumber;
      state.repeatPeriod = state.backup.repeatPeriod;
      state.selectedDays = [...state.backup.selectedDays];
      state.ending = state.backup.ending;
      state.endingDate = state.backup.endingDate;

      customizeRepeatSlice.caseReducers.saveChanges(state, null);
    },
    saveChanges: (state, _) => {
      // create new string to add to RepeatedPicker component
      let NEW_REPEATED_OPTION = `Every ${state.repeatNumber} ${state.repeatPeriod.value} ${
        state.selectedDays.length > 0
          ? 'on ' + state.selectedDays.map((obj) => obj.short).join(', ')
          : ''
      } ${state.ending === 'true' ? 'ending on ' + state.endingDate : ''}`;
      if (NEW_REPEATED_OPTION.length > 20)
        NEW_REPEATED_OPTION = NEW_REPEATED_OPTION.substring(0, 20) + '...';
      customizeRepeatSlice.caseReducers.setRepeatedOptions(state, {
        payload: [...DEFAULT_REPEATED_OPTIONS, NEW_REPEATED_OPTION],
      });
      customizeRepeatSlice.caseReducers.setRepeated(state, { payload: NEW_REPEATED_OPTION });
      customizeRepeatSlice.caseReducers.setShowCustomizeRepeat(state, { payload: false });
    },
    resetData: (state, _) => {
      state.showCustomizeRepeat = false;
      state.repeated = IS_NOT_REPEATED;
      state.repeatedOptions = DEFAULT_REPEATED_OPTIONS;
      state.repeatNumber = DEFAULT_REPEAT_NUMBER;
      state.repeatPeriod = DAYS;
      state.selectedDays = [];
      state.ending = 'false';
      state.endingDate = new Date().setHours(0, 0, 0, 0);
      state.backup = {
        repeatNumber: DEFAULT_REPEAT_NUMBER,
        repeatPeriod: DAYS,
        selectedDays: [],
        ending: 'false',
        endingDate: new Date(),
      };
    },
  },
});

export const {
  setShowCustomizeRepeat,
  setRepeated,
  setRepeatedOptions,
  setRepeatNumber,
  setRepeatPeriod,
  setSelectedDays,
  setEnding,
  setEndingDate,
  discardChanges,
  saveChanges,
  resetData,
} = customizeRepeatSlice.actions;

export default customizeRepeatSlice.reducer;
