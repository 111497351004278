import React from "react";
import clsx from "clsx";
import { List, ListSubheader, Box } from "@material-ui/core";
import "../Navbar.scss";
import { ListItemSimple, ListItemWithSubList } from "./ListItemMenu"

function MobileMenu({ anchor, classes, navItems }) {
  return (
    <div data-testid="mobile-menu">
      <Box flexGrow={1}>{ListMobile(anchor, classes, navItems)}</Box>
      <Box>
        <img className={classes?.imgSorintLeftMenu} src="LogoSorint.png" alt="Sorint Lab" />
      </Box>
    </div>
  );
}

const ListMobile = (anchor, classes, navItems) => (
  <div
    className={clsx(classes?.list, {
      [classes?.fullList]: anchor === "top" || anchor === "bottom",
    })}
    role="presentation"
  >
    <List subheader={<ListSubheader style={{ textAlign: "left", backgroundColor: "white", opacity: 0.95 }}> MENU </ListSubheader>}>
      {navItems?.map((item, index) => {
        return item.itemList?.length === 0 ? (
          <ListItemSimple item={item} key={`list-mobile-${item.id || index}`} />
        ) : (
          <ListItemWithSubList item={item} key={`list-mobile-${item.id || index}`} />
        );
      })}
    </List>
  </div>
);

export default MobileMenu;
