// React
import React from 'react';

// Styles
import styles from './DeclinedPage.module.scss';

const DeclinedPage = () => {
  return (
    <div className={styles.container}>
      <div>
        <img
          src="/assets/calendar/check_image.png"
          alt="Appointment declined"
          className={styles.container__image}
        />
      </div>
      <div className={styles.container__message}>
        The appointment has been <span style={{ textTransform: 'uppercase' }}>declined!</span>
      </div>
    </div>
  );
};

export default DeclinedPage;
