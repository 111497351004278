import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Toolbar, IconButton, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { listMenuAPI } from "../../../../apis/LeftMenuAPI";
import AppLoader from "../../../../components/AppLoader";
import { DashboardPlaceholder } from "../../components";
import ItemMenuCard from "./ItemMenuCard";
import useStyles from "./LeftMenuAreaStyle";
import ItemMenuModel from "./model/itemMenuModel";
import {UseCustomSnackbar} from '../../../../utils/CustomSnackbar';

export default function LeftManuArea() {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const uiClasses = useStyles();

  // Load the list data
  const loadList = async (enqueueSnackbar, closeSnackbar) => {
    setLoading(true);
    try {
      const response = await listMenuAPI();
      const orderedList = response.data.sort((a, b) => { return b.id - a.id })
      setList(orderedList);
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }
    setLoading(false);
  };

  useEffect(() => {
    loadList(enqueueSnackbar, closeSnackbar);
  }, [enqueueSnackbar, closeSnackbar]);

  //---------------------------------------------------------------------
  // Handle the new element adding
  const onAddParentItem = () => {
    const clonedParent = [...list];
    clonedParent.unshift(new ItemMenuModel());
    setList(clonedParent);
  };

  // Handle new item removing after the cancel action
  const onRemoveUnsaved = (id) => {
    const filtered = list.filter((item) => item.id !== id);
    setList(filtered);
  };
  //---------------------------------------------------------------------

  const cardList = list.map((item, i) => (
    <ItemMenuCard
      key={item.id}
      counter={i + 1}
      itemMenu={item}
      itemSubMenu={item.itemList}
      onReload={() => loadList(enqueueSnackbar)}
      onRemoveUnsaved={() => onRemoveUnsaved(item.id)}
    />
  ));

  return (
    <div className={uiClasses.root}>
      <Toolbar variant="dense" className={uiClasses.toolbar}>
        <Typography variant="h5" className={uiClasses.title}>
          Left Menu CLANn
        </Typography>

        <IconButton onClick={onAddParentItem}>
          <AddIcon />
        </IconButton>
      </Toolbar>

      <div className={uiClasses.cardList}>
        {cardList.length ? cardList : <DashboardPlaceholder />}
      </div>
      {loading && <AppLoader />}
    </div>
  );
}
