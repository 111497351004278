import { useState, useEffect, useCallback } from "react";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal"
import { useSnackbar } from "notistack";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import ItemCard from "./ItemCard";
import useStyles from "./ItemMenuCardStyle";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import SubItemCard from "./SubItemCard";
import SubItemMenuModel from "./model/subItemMenuModel";
import { addItemMenuAPI, addSubItemMenuAPI, updateItemMenuAPI, deleteItemMenuAPI } from "../../../../apis/LeftMenuAPI";
import AppLoader from "../../../../components/AppLoader";
import { DashboardCardCounter } from '../../components';
import {UseCustomSnackbar} from '../../../../utils/CustomSnackbar';

export default function ItemMenuCard(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const uiClasses = useStyles();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(props.itemMenu.isNew === true);

  const cloned = cloneDeep(props.itemMenu);
  if (!cloned.itemList) cloned.itemList = [];
  const [editingModel, setEditingModel] = useState(cloned);
  const [editingItemList, setEditingItemList] = useState(cloned.itemList)

  const cloneModel = useCallback(() => {
    const cloned = cloneDeep(props.itemMenu);
    if (!cloned.itemList) cloned.itemList = [];
    setEditingModel(cloned);
    setEditingItemList(cloned.itemList)
  }, [props.itemMenu]);

  // Sync the editing model when the source model is reloaded
  useEffect(() => {
    cloneModel();
  }, [cloneModel, props.itemMenu]);

  // Handle the cancel action
  const onCancel = () => {
    if (props.itemMenu.isNew) {
      props.onRemoveUnsaved(props.itemMenu.id);
    } else {
      setEditMode(false);
      setEditingItemList(cloned.itemList)
      cloneModel();
    }
  };

  // Handle the save action
  const onSave = async () => {
    setLoading(true);
    let itemList = []
    const requestBody = cloneDeep(editingModel);
    requestBody.itemList.filter(item => item.isNewSubItem).forEach(item => {
      itemList.push(item)
      delete item.id;
      delete item.isNewSubItem;
    });
    
    try {
      // POST add new menu card
      if (editingModel.isNew) {
        delete requestBody.id;
        delete requestBody.isNew;
        await addItemMenuAPI(requestBody);
        // manage editing
      } else {
        const isNotNew = {...editingModel, itemList: editingModel.itemList.filter(item => !item.isNewSubItem)}
        console.log(isNotNew)
        // PUT for edited elements
        if (!isEqual(cloned, isNotNew)) {
          await updateItemMenuAPI(isNotNew)
        }
        // POST new sub-items
        if (itemList.length > 0) {
          await addSubItemMenuAPI(requestBody, itemList)
        }
      }
      setEditMode(false);
      props.onReload();
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, 'Item menu saved.', 'success');
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }
    setLoading(false);
  };

  const onAddSubItem = () => {
    const cloned = cloneDeep(editingModel);
    cloned.itemList.push(new SubItemMenuModel());
    setEditingModel(cloned);
    setEditingItemList(cloned.itemList)
    
  };

  const onDelete = async () => {
    setLoading(true);

    try {
      await deleteItemMenuAPI(editingModel);
      props.onReload();
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, 'Item menu deleted.', 'success');
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }

    setLoading(false);
  };

  const onDeleteSubItem = (id) => {
    const data = editingModel;
    data.itemList = data.itemList.filter(item => item.id !== id);
    setEditingModel(data)
    setEditingItemList(data.itemList)
  };

  const isFormValid = () => {
    return (
      editingModel.title &&
      // editingModel.url && --->the url disappears when the card has sub-items
      editingModel.itemList.every((item) => item.title && item.url)
    );
  };

  // Handle the editing model update
  const setModelProperty = (property, value) => {
    setEditingModel((oldValue) => ({ ...oldValue, [property]: value }));
  }

  const onSubItemChange = (property, value, index) => {
    const cloned = cloneDeep(editingModel);
    cloned.itemList[index][property] = value;
    setEditingModel(cloned);
    setEditingItemList(cloned.itemList)
  };

  //---------------------------------------------------------------------
  
  const cardSubList = editingItemList?.map((item, i) => {
    return (
      <SubItemCard
        key={item.id}
        itemMenu={item}
        editMode={editMode}
        onTitleChange={(value) => onSubItemChange("title", value, i)}
        onUrlChange={(value) => onSubItemChange("url", value, i)}
        onDeleteSubItem={onDeleteSubItem}
      />
    );
  })

  return (
    <ItemCard
      editMode={editMode}
      isFormValid={isFormValid()}
      deleteConfirmMessage="Do you confirm the item menu deletion?"
      onDelete={onDelete}
      onEdit={() => setEditMode(true)}
      onCancel={onCancel}
      onSave={onSave}
      body={
        <>
          {editMode && (
            <Typography variant="caption" display="block">
              Insert at least the titleand the url
            </Typography>
          )}

          <div className={uiClasses.row}>
            <DashboardCardCounter counter={props.counter} />

            <TextField
              label="Title *"
              fullWidth
              className={uiClasses.name}
              error={!editingModel.title}
              disabled={!editMode}
              value={editingModel.title}
              onChange={(ev) => setModelProperty("title", ev.target.value)}
            />
          </div>

          {editingModel.itemList.length === 0 && (
            <TextField
              label="Url *"
              fullWidth
              className={uiClasses.url}
              error={!editingModel.url}
              disabled={!editMode}
              value={editingModel.url}
              onChange={(ev) => setModelProperty("url", ev.target.value)}
            />
          )}

          <div className={uiClasses.cardSubList}>{ cardSubList }</div>

          {editMode && (
            <div style={{ width: "100%", marginTop: 15 }}>
              <IconButton
                onClick={() => onAddSubItem()}
                style={{ margin: "auto", display: "block", backgroundColor: "#efefef" }}
              >
                <AddIcon />
              </IconButton>
            </div>
          )}

          {loading && <AppLoader />}
        </>
      }
    />
  );
}
