import { clannAxios as axios } from '../utils/axiosInstances';

export function getTreeTypes() {
  return axios.get('treedom/tree-type');
}

export function getFreeCodes() {
  return axios.get('treedom/free-tree-code');
}

export function getTreedomInfo() {
  return axios.get('treedom');
}

export function setMyTreedomInfo(data) {
  return axios.post('treedom', data);
}

export function assignTree(data) {
  return axios.post('treedom/assign-tree', data);
}
