import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import "../SorintTools.scss";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: { display: "block" },
  },
}));

const Item = ({ toolName, toolTooltip, srcicon, srciconcolored, area }) => {
  const classes = useStyles();
  return (
    <Tooltip data-testid="tooltip" title={toolTooltip ? toolTooltip : "title"}>
      <Box display="flex" flexDirection="column" alignItems="center" className="main-grid">
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          justifyContent="space-around"
          alignItems="stretch"
          className="tool-grid"
        >
          <Box className="box-img" id={`srcicon${toolName}`}>
            <img src={srciconcolored} alt={toolName} className="srciconcolored" />
            <img src={srcicon} alt={toolName} className="srcicon" />
          </Box>
          <Box>
            <h4>{toolName}</h4>
          </Box>
        </Box>

        <Box className={`grid-group ${classes.sectionDesktop}`} justifyContent="center">
          <h5 style={{ textTransform: "uppercase", fontWeight: 600 }}>{area}</h5>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default Item;
