import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: '#ffb15d',
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    flexShrink: 0,
    fontSize: 20,
    height: 36,
    justifyContent: 'center',
    marginRight: 15,
    width: 36,
  },
}));

export default useStyles;