import React, { useEffect, useState } from 'react';
import { TextField, FormControl, Select, MenuItem, InputLabel, makeStyles } from '@material-ui/core';

import { getTreeTypes } from '../../../../../apis/TreedomAPI';

const Step3Style = makeStyles((theme) => ({
  root: {
    width: 220,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 7,
  },
  fields: {
    width: 220,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Step3({ onChangeName, onChangeType }) {
  const classes = Step3Style();

  const [treeTypes, setTreeTypes] = useState([]);

  useEffect(() => {
    try {
      getTreeTypes().then((response) => {
        setTreeTypes(response?.data || []);
      });
    } catch (error) {}
  }, []);

  return (
    <div className={classes.root}>
      <div>Give a name to your tree:</div>
      <div className={classes.fields}>
        <TextField
          label="My tree's name"
          variant="outlined"
          size="small"
          style={{ width: '100%' }}
          onChange={(event) => onChangeName(event.target.value)}
          required
        />
        {treeTypes?.length === 0 ? (
          <div>Loading tree types...</div>
        ) : (
          <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
            style={{ width: '100%' }}
            required
          >
            <InputLabel id="demo-simple-select-outlined-label">My tree's typology</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="My tree's typology"
              onChange={(event) => onChangeType(event.target.value)}
              defaultValue={-1}
            >
              <MenuItem value={-1}>
                <em>None</em>
              </MenuItem>
              {treeTypes.map((type) => (
                <MenuItem value={type.id} key={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
    </div>
  );
}
