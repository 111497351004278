import React from "react";
import { Drawer, Hidden, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import DrawerListItems from "./DrawerListItem";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  button: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px auto",
    width: "80%",
    backgroundColor: "lightslategrey",
    "&:hover": {
      backgroundColor: "#3b4752",
    },
  },
}));

function DrawerItems() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden xsDown implementation="css">
          <Drawer
            variant="permanent"
            classes={{ paper: classes.drawerPaper }}
            className="drawer-dashboard"
          >
            <DrawerListItems />
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<HomeIcon />}
              size="large"
              component={Link}
              to={"/"}
            >
              Back to Home
            </Button>
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

export default DrawerItems;
