import { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import AppLoader from '../../../components/AppLoader';
import ImageField from '../../../components/ImageField';
import ConfirmDialog from '../../../components/ConfirmDialog';
import DashboardCardActions from './DashboardCardActions';
import useStyles from './DashboardCardStyle.js';

export default function DashboardCard(props) {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const uiClasses = useStyles();

  // Convert the doodle files to string path
  const getImagePreview = image => {
    return image instanceof File ? URL.createObjectURL(image) : image;
  };

  const onConfirm = () => {
    setDeleteConfirmOpen(false);
    props.onDelete();
  };

  return (
    <div className={uiClasses.root}>
      <div className={uiClasses.body}>
        {props.body}
      </div>

      <div className={uiClasses.fileArea}>
        <div className={uiClasses.fileItem}>
          <Tooltip title={props.firstImageTooltip}>
            <span className={uiClasses.fileIcon}>
              {props.firstImageIcon}
            </span>
          </Tooltip>

          <ImageField
            disabled={!props.editMode}
            preview={getImagePreview(props.firstImage)}
            onChange={value => props.onFirstImageChange(value)}
          />
        </div>

        <div className={uiClasses.fileItem}>
          <Tooltip title={props.secondImageTooltip}>
            <span className={uiClasses.fileIcon}>
              {props.secondImageIcon}
            </span>
          </Tooltip>

          <ImageField
            disabled={!props.editMode}
            preview={getImagePreview(props.secondImage)}
            onChange={value => props.onSecondImageChange(value)}
          />
        </div>
      </div>

      <DashboardCardActions
        editMode={props.editMode}
        isFormValid={props.isFormValid}
        onDelete={() => setDeleteConfirmOpen(true)}
        onEdit={props.onEdit}
        onCancel={props.onCancel}
        onSave={props.onSave}
      />

      {props.loading && <AppLoader />}

      <ConfirmDialog
        message={props.deleteConfirmMessage}
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        onConfirm={onConfirm}
      />
    </div>
  );
};
