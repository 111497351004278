// React
import React, { useState } from 'react';

// Material UI
import { Modal } from '@material-ui/core';

// Components
import CategoryItem from '../Categories/components/CategoryItem';
import AddToMyCalendarButton from './components/AddToMyCalendarButton';

// Icons
import {
  CalendarTodayOutlined,
  Close,
  Repeat,
  LocationOnOutlined,
  FormatAlignCenter,
} from '@material-ui/icons';

// Utils
import { fromRruleToNaturalLanguage } from '../../../../../../utils/calendarUtils';
import { format } from 'date-fns';
import { Anchorme } from 'react-anchorme';

// Styles
import styles from './DetailsEventModal.module.scss';

const ModalTitle = () => {
  return <div style={{ lineHeight: '25px' }}>Appointment details</div>;
};

const EventTitle = ({ title }) => {
  return <div style={{ fontSize: '39px' }}>{title}</div>;
};

const EventTimeZone = ({ start }) => {
  return <div>{Intl.DateTimeFormat(start).resolvedOptions().timeZone}</div>;
};

const EventRepeat = ({ repeated }) => {
  return <div style={{ lineHeight: '24px', wordBreak: 'break-word' }}>{repeated}</div>;
};

const EventLocation = ({ location }) => {
  return (
    <div style={{ lineHeight: '24px', wordBreak: 'break-all' }}>
      <Anchorme target="_blank">{location}</Anchorme>
    </div>
  );
};

const DetailsEventModal = ({ isOpen, closeModal, event }) => {
  const end = event.allDay
    ? new Date(event.endFormatted).setDate(event.endFormatted.getDate() - 1)
    : event.endFormatted;

  const [showMore, setShowMore] = useState(false);

  return (
    <Modal open={isOpen}>
      <div className={styles.container}>
        <div className={styles.container__header}>
          <ModalTitle></ModalTitle>
          <Close htmlColor="white" style={{ cursor: 'pointer' }} onClick={closeModal}></Close>
        </div>
        <div className={styles.container__content}>
          <div className={styles.container__content__body}>
            <EventTitle title={event.title}></EventTitle>
            <div className={styles.category}>
              <CalendarTodayOutlined htmlColor="transparent"></CalendarTodayOutlined>
              <CategoryItem
                category={event.calendar.calendarName}
                color={event.calendar.color}
              ></CategoryItem>
            </div>
            <div className={styles.datetime}>
              <CalendarTodayOutlined htmlColor="#F17023"></CalendarTodayOutlined>
              <div className={styles.datetime__container}>
                <div className={styles.datetime__container__date}>
                  {event.startFormatted && (
                    <div className={styles.datetime__container__date__box}>
                      <span className={styles.datetime__container__date__box__label}>Start</span>
                      <div className={styles.datetime__container__date__box__dates}>
                        <div>{format(event.startFormatted, 'EEEE dd')}</div>
                        <div>{format(event.startFormatted, 'MMM yyyy')}</div>
                      </div>
                      {!event.allDay && <div>{format(event.startFormatted, 'HH:mm')}</div>}
                    </div>
                  )}
                  {event.endFormatted && (
                    <div className={styles.datetime__container__date__box}>
                      <span className={styles.datetime__container__date__box__label}>End</span>
                      <div className={styles.datetime__container__date__box__dates}>
                        <div>{format(end, 'EEEE dd')}</div>
                        <div>{format(end, 'MMM yyyy')}</div>
                      </div>
                      {!event.allDay && <div>{format(event.endFormatted, 'HH:mm')}</div>}
                    </div>
                  )}
                </div>
                <EventTimeZone></EventTimeZone>
              </div>
            </div>
            {fromRruleToNaturalLanguage(event.rrule) && (
              <div className={styles.repeated}>
                <Repeat htmlColor="#F17023"></Repeat>
                <EventRepeat repeated={fromRruleToNaturalLanguage(event.rrule)}></EventRepeat>
              </div>
            )}
            {event.location && (
              <div className={styles.location}>
                <LocationOnOutlined htmlColor="#F17023"></LocationOnOutlined>
                <EventLocation location={event.location}></EventLocation>
              </div>
            )}
            {event.description && (
              <div className={styles.description}>
                <FormatAlignCenter htmlColor="#F17023"></FormatAlignCenter>
                <div>
                  {!showMore ? event.description.substring(0, 80) : event.description}
                  {event.description.substring() > event.description.substring(0, 80) && (
                    <span
                      onClick={() => setShowMore(!showMore)}
                      style={{
                        color: '#FB8D4B',
                        weight: '700',
                        cursor: 'pointer',
                      }}
                    >
                      {!showMore ? ' Show more' : ' Show less'}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={styles.container__content__footer}>
            <AddToMyCalendarButton event={event}></AddToMyCalendarButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DetailsEventModal;
