import React from "react";
import { Box, Paper, Divider, Grid } from "@material-ui/core";
import { Highlight } from "react-instantsearch-dom";
import { useStyles } from "./person/style";
import { FileIcon, defaultStyles } from "react-file-icon";

export default function DocumentsDrive({ hit }) {
  const cls = useStyles();
  const [elevationPaper, setElevationPaper] = React.useState(0);
  let lastEditTimeStamp = new Date(hit.date);
  let lastEditDate = lastEditTimeStamp.toLocaleDateString();
  return (
    <Paper
      elevation={elevationPaper}
      onMouseOver={() => setElevationPaper(6)}
      onMouseOut={() => setElevationPaper(0)}
      className={cls.resultPaper}
    >
      <Box display="flex" flexDirection="column">
        <div className="hit-name">
          <a href={hit.shared_link} target="_blank" rel="noreferrer">
            <Highlight attribute="title" hit={hit} />
          </a>
        </div>
        <div className="hit-info">
          Category: <strong>{hit.category}</strong>
        </div>

        <Divider
          style={{ height: 0.9, margin: "10px 0", backgroundColor: "rgba(0, 0, 0, 0.12)" }}
        />

        <Grid container spacing={2}>
          <Grid item xs={2}>
          <FileIcon extension={hit.extension} {...defaultStyles[hit.extension]} />
          </Grid>
          <Grid item xs={10}>
            <div className="hit-info hit-date">
              Last edit: <strong>{lastEditDate}</strong>
            </div>
            <div className="hit-info hit-date">
            Extension: <strong>{hit.extension}</strong>
            </div>
            <div className="hit-info hit-date">
              Url: <strong>{hit.shared_link}</strong>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
