import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import './index.css';
import App from './App';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import axios from 'axios';
import store from './store';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react"
import Package from "../package.json"

async function configureAndRender() {
  try {
    // Load the environment data
    const environment = (await axios.get('/config/environment.json', { baseURL: '' })).data;
    if (environment?.sentry?.enabled === true) {
      Sentry.init({
        dsn: environment.sentry.dsn,
        environment: environment.sentry.environment,
        release: `${Package?.name}@${Package?.version}`,
        // Performance Monitoring
        tracesSampleRate: environment.sentry.tracesSampleRate,            
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets:
              environment.sentry.tracePropagationTargets,
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(BrowserRouter),
          }),
          new Sentry.Replay()
        ],
        // Session Replay
        replaysSessionSampleRate:
              environment.sentry.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate:
              environment.sentry.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
    const instance = createInstance({
      urlBase: environment.matomo.url,
      siteId: environment.matomo.siteId,
      heartBeat: {
        active: false,
        seconds: 15,
      },
      linkTracking: false,
      configurations: {
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST',
      },
    });

    render(
      <Provider store={store}>
        <MatomoProvider value={instance}>
          <BrowserRouter>
            <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <App />
            </SnackbarProvider>
          </BrowserRouter>
        </MatomoProvider>
      </Provider>,
      document.getElementById('root')
    );
  } catch (e) {
    // Alternatively, load app without matomo
    render(
      <Provider store={store}>
        <BrowserRouter>
          <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <App />
          </SnackbarProvider>
        </BrowserRouter>
      </Provider>,
      document.getElementById('root')
    );
  }
}

configureAndRender();
