import { useState, useEffect } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { useSnackbar } from 'notistack';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Typography } from "@material-ui/core";
import { addDoodleAPI, updateDoodleAPI, deleteDoodleAPI } from '../../../../apis/DoodleAPI';
import { DashboardCard, DashboardCardCounter } from '../../components';
import useStyles from './DoodleCardStyle.js';
import {UseCustomSnackbar} from '../../../../utils/CustomSnackbar';

export default function DoodleCard(props) {
  const [editMode, setEditMode] = useState(props.doodle.isNew === true);
  const [loading, setLoading] = useState(false);
  const [editingModel, setEditingModel] = useState(cloneDeep(props.doodle));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const uiClasses = useStyles();

  // Sync the editing model when the source model is reloaded
  useEffect(() => setEditingModel(cloneDeep(props.doodle)), [props.doodle]);

  // Handle the editing model update
  const setModelProperty = (property, value) => {
    setEditingModel(oldValue => ({ ...oldValue, [property]: value }));
  };

  // Handle the delete action
  const onDelete = async () => {
    setLoading(true);

    try {
      await deleteDoodleAPI(editingModel);
      props.onReload();
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, 'Doodle deleted.' , 'success');
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }

    setLoading(false);
  };

  // Handle the cancel action
  const onCancel = () => {
    if (props.doodle.isNew) {
      props.onRemoveUnsaved();
    } else {
      setEditMode(false);
      setEditingModel(cloneDeep(props.doodle));
    }
  };

  // Handle the save action
  const onSave = async () => {
    setLoading(true);
    const requestBody = cloneDeep(editingModel);

    try {
      if (editingModel.isNew) {
        // Create a new doodle removing the UI data
        delete requestBody.id;
        delete requestBody.isNew;
        await addDoodleAPI(requestBody);
      } else {
        // Update an existent doodle removing the images path if aren't changed
        if (typeof requestBody.desktopImage === 'string') {
          delete requestBody.desktopImage;
        }
        if (typeof requestBody.mobileImage === 'string') {
          delete requestBody.mobileImage;
        }
        await updateDoodleAPI(requestBody);
      }

      setEditMode(false);
      props.onReload();
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, 'Doodle saved.' , 'success');
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }

    setLoading(false);
  };

  // Check if form is valid
  const isFormValid = () => {
    return editingModel.title && editingModel.url && editingModel.desktopImage && editingModel.mobileImage;
  };

  return (
    <DashboardCard
      editMode={editMode}
      loading={loading}
      isFormValid={isFormValid()}

      firstImage={editingModel.desktopImage}
      firstImageIcon={<DesktopMacIcon />}
      firstImageTooltip="Desktop image"
      onFirstImageChange={value => setModelProperty('desktopImage', value)}

      secondImage={editingModel.mobileImage}
      secondImageIcon={<SmartphoneIcon />}
      secondImageTooltip="Mobile image"
      onSecondImageChange={value => setModelProperty('mobileImage', value)}

      deleteConfirmMessage="Do you confirm the doodle deletion?"
      onDelete={onDelete}
      onEdit={() => setEditMode(true)}
      onCancel={onCancel}
      onSave={onSave}

      body={<>
        {editMode && <Typography variant="caption" display="block">
          Insert at least the title, the url and the two images
        </Typography>}

        <div className={uiClasses.row}>
          <DashboardCardCounter counter={props.counter} />

          <TextField
            label="Title *"
            fullWidth
            className={uiClasses.title}
            error={!editingModel.title}
            disabled={!editMode}
            value={editingModel.title}
            onChange={ev => setModelProperty('title', ev.target.value)}
          />

          <TextField
            label="Url *"
            fullWidth
            className={uiClasses.url}
            error={!editingModel.url}
            disabled={!editMode}
            value={editingModel.url}
            onChange={ev => setModelProperty('url', ev.target.value)}
          />

          <TextField
            label="Publication Date"
            fullWidth
            className={uiClasses.date}
            disabled
            value={new Date(editingModel.publicationDate).toLocaleDateString()}
          />
        </div>

        <div className={uiClasses.row}>
          <TextField
            label="Tooltip text"
            multiline
            fullWidth
            margin="dense"
            className={uiClasses.description}
            disabled={!editMode}
            value={editingModel.tooltip}
            onChange={ev => setModelProperty('tooltip', ev.target.value)}
          />

          <FormControlLabel
            className={uiClasses.switch}
            label="For new users"
            disabled={!editMode}
            control={
              <Switch
                checked={editingModel.dedicatedForNewUser}
                onChange={ev => setModelProperty('dedicatedForNewUser', ev.target.checked)}
              />
            }
          />
        </div>
      </>}
    />
  );
};
