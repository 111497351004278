// React
import React, { useEffect, useState } from 'react';

// Material UI
import { createTheme, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker, KeyboardTimePicker } from '@material-ui/pickers';

// Icons
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  setStartDateTime,
  setEndDateTime,
  setInvalidDate,
  setInvalidTime,
} from '../../../../../../../store/suggestEventSlice';

// Utils
import DateFnsUtils from '@date-io/date-fns';
import { compareAsc } from 'date-fns';

// Styles
import styles from './RangeDateTimePicker.module.scss';

const RangeDateTimePicker = () => {
  const themeDate = createTheme({
    palette: {
      primary: {
        main: '#F17023',
      },
    },
    overrides: {
      MuiPaper: {
        rounded: {
          borderRadius: '10px',
        },
      },
      MuiInputBase: {
        input: {
          cursor: 'pointer',
          textAlign: 'center',
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
      },
      MuiPickersToolbarText: {
        toolbarTxt: {
          color: 'white',
        },
        toolbarBtnSelected: {
          color: 'white',
        },
      },
      MuiFormControl: {
        root: {
          backgroundColor: '#E8EBED',
          padding: '6px 10px 6px 10px',
          borderRadius: '8px',
          border: '1px solid #E8EBED',
          '&:hover': {
            border: '1px solid #98A6AE',
          },
        },
      },
      MuiPickersDay: {
        daySelected: {
          color: 'white',
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          display: 'none !important',
        },
      },
      MuiTypography: {
        body1: {
          fontSize: '16px',
        },
      },
      MuiPickersCalendarHeader: {
        daysHeader: {
          justifyContent: 'center',
          '&>*:nth-child(1)': {
            color: '#FAB97A',
          },
        },
        dayLabel: {
          color: '#C6CED2',
          fontSize: '14px',
        },
        iconButton: {
          padding: 0,
          '&>span svg path': {
            color: '#F17023',
          },
        },
        switchHeader: {
          marginTop: 'none',
          marginBottom: '21px',
          justifyContent: 'center',
        },
        transitionContainer: {
          height: '22px',
          maxWidth: '130px',
        },
      },
      MuiPickersCalendar: {
        week: {
          justifyContent: 'center',
          '&>*:nth-child(1) button span p': {
            color: '#FAB97A !important',
          },
          '&>div span button span p': {
            fontWeight: 500,
          },
        },
      },
    },
  });

  const themeTime = createTheme({
    palette: {
      primary: {
        main: '#F17023',
      },
    },
    overrides: {
      MuiInputBase: {
        input: {
          cursor: 'pointer',
          textAlign: 'center',
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
      },
      MuiPickersToolbarText: {
        toolbarTxt: {
          color: 'white',
        },
        toolbarBtnSelected: {
          color: 'white',
        },
      },
      MuiFormControl: {
        root: {
          width: '90px',
          maxWidth: '90px !important',
          backgroundColor: '#E8EBED',
          padding: '5px 2px 5px 2px',
          borderRadius: '8px',
          border: '1px solid #E8EBED',
          '&:hover': {
            border: '1px solid #98A6AE',
          },
        },
      },
      MuiIconButton: {
        root: {
          padding: '4px',
        },
      },
      MuiInputAdornment: {
        positionEnd: {
          marginLeft: 'unset',
        },
      },
      MuiPickersClockNumber: {
        clockNumberSelected: {
          color: 'white',
        },
      },
    },
  });

  const isAllDay = useSelector((state) => state.suggestEvent.isAllDay);
  const startDate = useSelector((state) => state.suggestEvent.startDateTime);
  const endDate = useSelector((state) => state.suggestEvent.endDateTime);
  const invalidDate = useSelector((state) => state.suggestEvent.invalidDate);
  const invalidTime = useSelector((state) => state.suggestEvent.invalidTime);
  const dispatch = useDispatch();

  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());

  useEffect(() => {
    setStartTime(new Date(startDate.getTime()));
  }, [startDate]);

  useEffect(() => {
    setEndTime(new Date(endDate.getTime()));
  }, [endDate]);

  const checkDatesValidity = (s, e) => {
    try {
      dispatch(setInvalidDate(false));
      dispatch(setInvalidTime(false));
      const sDate = new Date(s.getTime());
      const eDate = new Date(e.getTime());
      sDate.setHours(0, 0, 0, 0);
      eDate.setHours(0, 0, 0, 0);
      if (compareAsc(s, e) === 1) {
        if (compareAsc(sDate, eDate) === 0) {
          dispatch(setInvalidTime(true));
        } else {
          dispatch(setInvalidDate(true));
        }
      } else {
        dispatch(setInvalidDate(false));
        dispatch(setInvalidTime(false));
      }
    } catch (_) {}
  };

  const handleStartChange = (value) => {
    if (value && !isNaN(value.getTime())) {
      checkDatesValidity(value, endDate);
      dispatch(setStartDateTime(new Date(value.getTime())));
    }
  };

  const handleEndChange = (value) => {
    if (value && !isNaN(value.getTime())) {
      checkDatesValidity(startDate, value);
      dispatch(setEndDateTime(new Date(value.getTime())));
    }
  };

  const highlightPicker = (id, isOpen) => {
    const picker = document.getElementById(id);
    if (isOpen) picker.style.color = '#FB8D4B';
    else picker.style.color = 'unset';
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={styles.container}>
        <div className={styles.container__box}>
          <CalendarTodayOutlinedIcon htmlColor="#F17023"></CalendarTodayOutlinedIcon>
          <ThemeProvider theme={themeDate}>
            <DatePicker
              id="start-date-picker"
              autoOk
              variant="inline"
              value={startDate}
              format="EEE dd MMM yyyy"
              onChange={(value) => handleStartChange(value)}
              animateYearScrolling
              style={{ maxWidth: 170, border: `${invalidDate ? '2px solid red' : ''}` }}
              minDate={new Date()}
              onOpen={() => highlightPicker('start-date-picker', true)}
              onClose={() => highlightPicker('start-date-picker', false)}
            />
          </ThemeProvider>
          {!isAllDay && (
            <ThemeProvider theme={themeTime}>
              <div className={styles['vertical-line']}></div>
              <KeyboardTimePicker
                id="start-time-picker"
                autoOk
                variant="inline"
                ampm={false}
                value={startTime}
                style={{ maxWidth: 50, border: `${invalidTime ? '2px solid red' : ''}` }}
                onChange={(value) => handleStartChange(value)}
                onOpen={() => highlightPicker('start-time-picker', true)}
                onClose={() => highlightPicker('start-time-picker', false)}
                keyboardIcon={<AccessTimeIcon />}
              />
            </ThemeProvider>
          )}
        </div>
        <div className={styles.container__box}>
          <CalendarTodayOutlinedIcon htmlColor="transparent"></CalendarTodayOutlinedIcon>
          <ThemeProvider theme={themeDate}>
            <DatePicker
              id="end-date-picker"
              autoOk
              variant="inline"
              value={endDate}
              format="EEE dd MMM yyyy"
              onChange={(value) => handleEndChange(value)}
              animateYearScrolling
              style={{ maxWidth: 170, border: `${invalidDate ? '2px solid red' : ''}` }}
              minDate={startDate}
              onOpen={() => highlightPicker('end-date-picker', true)}
              onClose={() => highlightPicker('end-date-picker', false)}
            />
          </ThemeProvider>
          {!isAllDay && (
            <ThemeProvider theme={themeTime}>
              <div className={styles['vertical-line']}></div>
              <KeyboardTimePicker
                id="end-time-picker"
                autoOk
                variant="inline"
                ampm={false}
                value={endTime}
                style={{ maxWidth: 50, border: `${invalidTime ? '2px solid red' : ''}` }}
                onChange={(value) => handleEndChange(value)}
                minDate={startDate}
                onOpen={() => highlightPicker('end-time-picker', true)}
                onClose={() => highlightPicker('end-time-picker', false)}
                keyboardIcon={<AccessTimeIcon />}
              />
            </ThemeProvider>
          )}
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default RangeDateTimePicker;
