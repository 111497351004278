import { clannAxios as axios } from '../utils/axiosInstances';

export function listMenuAPI() {
  return axios.get('menu/list');
}

export function addItemMenuAPI(data) {
  return axios.post('menu/add', data);
}

export function addSubItemMenuAPI(data, itemList ) {
  return axios.post(`menu/subMenu/${data.id}`, itemList);
}

export function updateItemMenuAPI(data) {
  return axios.put(`menu/${data.id}`, data);
}

export function deleteItemMenuAPI(data) {
  return axios.delete(`menu/${data.id}`);
}
