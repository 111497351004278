import React from 'react';
import { Highlight } from 'react-instantsearch-dom';
import { Box, Grid, Avatar, List, ListItem, ListItemText, Icon, Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import ListWhitSubItem from './SubList';
import { useStyles, StyledBadge } from './style';
import { useSnackbar } from 'notistack';
import { UseCustomSnackbar } from '../../../../../../utils/CustomSnackbar';
import { parseISO, format } from 'date-fns';

export default function Person({ hit, savatarURL }) {
  const cls = useStyles();
  const [elevationPaper, setElevationPaper] = React.useState(2);
  const [availability, setAvailability] = React.useState({});
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const customizedHit = (hit, attribute) => {
    const hitJSON = JSON.stringify(hit);
    let hitCopy = JSON.parse(hitJSON);
    if (hitCopy?._highlightResult[attribute]?.value) {
      let value = hitCopy._highlightResult[attribute].value;
      let split = value.split(/\*|,/g);
      split = split?.map((item) => '- '.concat(item.trim()))?.filter((item) => item.length > 0);
      value = split?.join('\n');
      hitCopy._highlightResult[attribute].value = value;
    }
    return JSON.parse(JSON.stringify(hitCopy));
  };

  return (
    <Paper
      elevation={elevationPaper}
      onMouseOver={() => setElevationPaper(6)}
      onMouseOut={() => setElevationPaper(2)}
      style={{ margin: '20 0' }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <AvatarCustom hit={hit} savatarURL={savatarURL} />
        <List component="nav" dense={true} className={cls.listInfoPerson}>
          <ListItemCustom title={hit.email} icon={'email'} href={`mailto:${hit.email}`} />
          <ListItemPhone title={hit.phone} icon={'phone'} href={`tel:${hit.phone}`} />
          <ListItemCustom title={hit.sircle} icon={'filter_tilt_shift'} href={hit.url} />
          <ListItemCustom title={hit.office} icon={'apartment'} />
          <ListItemCustom title={hit.location} icon={'pin_drop'} />
        </List>

        <Divider style={{ width: '90%' }} />

        <List component="nav" dense={true} className={cls.listInfoPerson}>
          <ListWhitSubItem
            setAvailability={setAvailability}
            username={hit.username}
            setLoading={setLoading}
            setError={setError}
            listName="Today's Availability"
            items={[
              {
                data: <Vacation hit={availability} loading={loading} error={error} icon={'circle'} />,
              },
            ]}
          />
	          <ListWhitSubItem 
            listName="Roles" 
            items={[{ data: <Roles hit={hit} /> }]} 
            setLoading={setLoading}
            setError={setError}
          />
          <ListWhitSubItem
            listName="Clann CV"
            items={[
              {
                name: 'Certification',
                data: [
                  <Highlight
                    key={hit.objectID}
                    attribute="certifications"
                    tagName={'strong'}
                    hit={customizedHit(hit, 'certifications')}
                  />,
                ],
              },
              {
                name: 'Skill',
                data: [
                  <Highlight
                    key={hit.objectID}
                    attribute="skills"
                    tagName={'strong'}
                    hit={customizedHit(hit, 'skills')}
                  />,
                ],
              },
            ]}
            setLoading={setLoading}
            setError={setError}
          />
        </List>
      </Box>
    </Paper>
  );
}

const ListItemCustom = ({ title, icon, href }) => {
  const cls = useStyles();
  const component = href ? 'a' : 'div';
  const cursor = href ? 'pointer' : 'default';
  const target = title ? '_blank' : '_self';
  href = title ? href : '#';
  title = title ? title : '-';
  return (
    <ListItem button component={component} href={href} target={target} style={{ cursor: cursor }}>
      <ListItemIcon className={cls.iconList}>
        <Icon>{icon} </Icon>
      </ListItemIcon>
      <ListItemText className="wrapInfo" primary={title} />
    </ListItem>
  );
};

const ListItemPhone = ({ title, icon, href }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text);
    UseCustomSnackbar(enqueueSnackbar, closeSnackbar, 'Copied to clipboard', 'info');
  };

  const cls = useStyles();
  const target = title ? '_blank' : '_self';
  href = title ? href : '#';
  title = title ? title : '-';
  return (
    <ListItem>
      <a href={href} target={target}>
        <ListItemIcon className={cls.iconList}>
          <Icon>{icon} </Icon>
        </ListItemIcon>
      </a>
      <ListItemText
        style={{ cursor: 'copy' }}
        className="wrapInfo"
        primary={title}
        onClick={() => copyToClipBoard(title)}
      />
    </ListItem>
  );
};

const Vacation = ({ hit, icon, loading, error }) => {
  const cls = useStyles();
  const slotBusy = 'Except in the following time slot(s): ';
  const nextDayMsg = 'Next working day: ';
  const vacation = hit.vacation;
  const festivity = hit.festivity;
  const permits = hit.permits;
  const nextWorkingDay = hit.nextWorkingDay;

  const isAvailable = !vacation && !festivity;
  const hasPermit = permits && permits.length > 0;

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
        <CircularProgress
          variant="indeterminate"
          size={30}
          thickness={4}
          className={cls.fabProgress}
      /></div>
      ) : (
        <ListItem key={1} style={{ padding: 0 }}>
          <ListItemText
            primary={
              <div style={{ fontSize: 14 }}>
                  {vacation === undefined ? (
                     error === '' ? (<em>option not available for this user</em> ) : (<em>{ error }, try later</em>) 
                ) : (
                  <p style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
                    <Icon
                      className={
                        isAvailable
                          ? hasPermit
                            ? cls.iconWarning
                            : cls.iconSuccess
                          : cls.iconDanger
                      }
                    >
                      {icon}
                    </Icon>
                    <strong style={{ marginLeft: '15px' }}>
                      {isAvailable ? 'available' : 'unavailable'}
                    </strong>
                  </p>
                )}
                {hasPermit && (
                  <div>
                    <em>{slotBusy}</em>
                    <span>
                    <br />
                      {permits.map((data) => {
                        return (
                            <b>
                              {data.starts_at.slice(11, -4)}-{data.ends_at.slice(11, -4)}{' '}
                            </b>
                        );
                      })}
                    </span>
                  </div>
                )}
                {!isAvailable && (
                  <div>
                    <em>{nextDayMsg}</em>
                    <span>
                      <b>{format(parseISO(nextWorkingDay), 'dd/MM/yyyy')}</b>
                    </span>
                  </div>
                )}
              </div>
            }
          />
        </ListItem>
      )}
    </>
  );
};

const Roles = ({ hit }) => {
  return (
    <>
      {hit.roles?.map((item, i) => {
        return (
          <ListItem key={item.role} style={{ padding: 0 }}>
            <ListItemText
              primary={
                <p style={{ fontSize: 14 }}>
                  <strong>{item.role}</strong> in sircle <em>{item.sircle}</em>
                </p>
              }
            />
          </ListItem>
        );
      })}
    </>
  );
};

const AvatarCustom = ({ hit, savatarURL }) => {
  const cls = useStyles();
  const avatarImage = savatarURL + `/${hit.username}?s=64`;
  return (
    <Grid container>
      <Grid item xs={12} sm={5}>
        <StyledBadge overlap="circular">
          <Avatar alt={hit.title} className={cls.large} src={avatarImage}></Avatar>
        </StyledBadge>
      </Grid>
      <Grid item sm={12} md={7} style={{ margin: 'auto', paddingLeft: 10 }}>
        <strong>
          <Highlight attribute="title" tagName={'strong'} hit={hit} />
        </strong>
        <Highlight
          attribute="username"
          tagName={'strong'}
          hit={hit}
          className={cls.hitDescription}
        />
      </Grid>
    </Grid>
  );
};
