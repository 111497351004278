// React
import React from 'react';

// Material UI
import { Button } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';

const SuggestEventButton = ({ openSuggest, isDisabled }) => {
  const handleClick = () => {
    document.getElementById('calendar-container-body').style.position = 'unset';
    document.getElementById('calendar-container-body-close').style.visibility = 'hidden';
    openSuggest();
  };

  return (
    <Button
      disabled={isDisabled}
      disableElevation
      disableRipple
      variant="contained"
      endIcon={<AddOutlined style={{ width: '27px', height: '27px' }} />}
      style={{
        textTransform: 'none',
        color: 'white',
        backgroundColor: '#F17023',
        borderRadius: '24px',
        height: '52px',
        width: '242px',
        padding: '12px 16px',
        fontSize: '16px',
        opacity: isDisabled ? '0.5' : '1',
      }}
      onClick={handleClick}
    >
      Suggest appointment
    </Button>
  );
};

export default SuggestEventButton;
