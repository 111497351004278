import axios from 'axios';


let checkAvailability;
let clannAxios;
let savatarAxios;
let picksAxios;

async function initialize(token) {
  localStorage.setItem('token', token)
  const environment = (await axios.get('/config/environment.json', { baseURL: '' })).data;
  let headers = {
    Authorization: `Bearer ${token}`
  };
  
  checkAvailability = axios.create({
    baseURL: environment.holidayApi,
    headers,
  });
  clannAxios = axios.create({
    baseURL: environment.apiUrl,
    headers,
  });
  savatarAxios = axios.create({
    baseURL: environment.savatarApi,
    headers: {
      'X-Auth-Token': token
    }
  });
  picksAxios = axios.create({
    baseURL: environment.picksApi,
    headers
  });
  clannAxios.interceptors.response.use(
    response => response,
    async(error) => {
      await error
      const errorMessage = error.response?.data[0]?.message || error
      return Promise.reject(errorMessage)
    }
  )
}

function updateToken() {
  if (checkAvailability) {
    checkAvailability.interceptors.request.use((request) => {
      const token = localStorage.getItem('token')
      if (token) {
        request.headers.Authorization = "Bearer " + token
        request.headers["X-Auth-Token"] = token;
      }
      return request;
    });
  }
  if (clannAxios) {
    clannAxios.interceptors.request.use((request) => {
      const token = localStorage.getItem('token')
      if (token) {
        request.headers.Authorization = "Bearer " + token
        request.headers["X-Auth-Token"] = token;
      }
      return request;
    });
  }
  if (savatarAxios) {
    savatarAxios.interceptors.request.use((request) => {
      const token = localStorage.getItem('token')
      if (token) {
        request.headers.Authorization = "Bearer " + token
        request.headers["X-Auth-Token"] = token;
      }
      return request;
    });
  }
  if (picksAxios) {
    picksAxios.interceptors.request.use((request) => {
      const token = localStorage.getItem('token')
      if (token) {
        request.headers.Authorization = "Bearer " + token
      }
      return request;
    });
  }
}

export { initialize, updateToken, clannAxios, savatarAxios, checkAvailability, picksAxios };