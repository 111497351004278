import { useState, useEffect } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { useSnackbar } from 'notistack';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import TextField from '@material-ui/core/TextField';
import { Typography } from "@material-ui/core";
import { addToolAPI, updateToolAPI, deleteToolAPI } from '../../../../apis/ToolAPI';
import { DashboardCard, DashboardCardCounter } from '../../components';
import useStyles from './ToolCardStyle.js';
import { UseCustomSnackbar } from '../../../../utils/CustomSnackbar';

export default function ToolCard(props) {
  const [editMode, setEditMode] = useState(props.tool.isNew === true);
  const [loading, setLoading] = useState(false);
  const [editingModel, setEditingModel] = useState(cloneDeep(props.tool));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const uiClasses = useStyles();

  // Sync the editing model when the source model is reloaded
  useEffect(() => setEditingModel(cloneDeep(props.tool)), [props.tool]);

  // Handle the editing model update
  const setModelProperty = (property, value) => {
    setEditingModel(oldValue => ({ ...oldValue, [property]: value }));
  };

  // Handle the delete action
  const onDelete = async () => {
    setLoading(true);

    try {
      await deleteToolAPI(editingModel);
      props.onReload();
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, 'Tool deleted.', 'success');
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }

    setLoading(false);
  };

  // Handle the cancel action
  const onCancel = () => {
    if (props.tool.isNew) {
      props.onRemoveUnsaved();
    } else {
      setEditMode(false);
      setEditingModel(cloneDeep(props.tool));
    }
  };

  // Handle the save action
  const onSave = async () => {
    setLoading(true);
    const requestBody = cloneDeep(editingModel);

    try {
      if (editingModel.isNew) {
        // Create a new tool removing the UI data
        delete requestBody.id;
        delete requestBody.isNew;
        await addToolAPI(requestBody);
      } else {
        // Update an existent tool removing the images path if aren't changed
        if (typeof requestBody.srcicon === 'string') {
          delete requestBody.srcicon;
        }
        if (typeof requestBody.srciconcolored === 'string') {
          delete requestBody.srciconcolored;
        }
        delete requestBody.date; // Remove date that block the update API
        await updateToolAPI(requestBody);
      }

      setEditMode(false);
      props.onReload();
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, 'Tool saved.', 'success');
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error);
    }

    setLoading(false);
  };

  // Check if form is valid
  const isFormValid = () => {
    return editingModel.toolName && editingModel.url && editingModel.toolTooltip && editingModel.area && editingModel.srcicon && editingModel.srciconcolored;
  };

  return (
    <DashboardCard
      editMode={editMode}
      loading={loading}
      isFormValid={isFormValid()}

      firstImage={editingModel.srcicon}
      firstImageIcon={<InvertColorsIcon />}
      firstImageTooltip="Two colors logo for the bottom grid"
      onFirstImageChange={value => setModelProperty('srcicon', value)}

      secondImage={editingModel.srciconcolored}
      secondImageIcon={<ColorLensIcon />}
      secondImageTooltip="Multi colors logo for the preferred grid"
      onSecondImageChange={value => setModelProperty('srciconcolored', value)}

      deleteConfirmMessage="Do you confirm the tool deletion?"
      onDelete={onDelete}
      onEdit={() => setEditMode(true)}
      onCancel={onCancel}
      onSave={onSave}

      body={<>
        {editMode && <Typography variant="caption" display="block">
          All fields are mandatory, including the two images.
        </Typography>}

        <div className={uiClasses.row}>
          <DashboardCardCounter counter={props.counter} />

          <TextField
            label="Name *"
            fullWidth
            className={uiClasses.name}
            error={!editingModel.toolName}
            disabled={!editMode}
            value={editingModel.toolName}
            onChange={ev => setModelProperty('toolName', ev.target.value)}
          />

          <TextField
            label="Url *"
            fullWidth
            placeholder='https://sircle.sorint.it'
            className={uiClasses.url}
            error={!editingModel.url}
            disabled={!editMode}
            value={editingModel.url}
            onChange={ev => setModelProperty('url', ev.target.value)}
          />
        </div>

        <div className={uiClasses.row}>
          <TextField
            label="Tooltip text *"
            multiline
            fullWidth
            margin="dense"
            className={uiClasses.description}
            error={!editingModel.toolTooltip}
            disabled={!editMode}
            value={editingModel.toolTooltip}
            onChange={ev => setModelProperty('toolTooltip', ev.target.value)}
          />

          <TextField
            label="Area *"
            fullWidth
            margin="dense"
            className={uiClasses.area}
            error={!editingModel.area}
            disabled={!editMode}
            value={editingModel.area}
            onChange={ev => setModelProperty('area', ev.target.value)}
          />
        </div>
      </>}
    />
  );
};
