import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: '#FFFA',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },

  loaderFullPage: {
    top: 0,
    left: 0,
  },

  fabProgress: {
    color: 'rgb(255, 152, 0) !important',
  },

  loaderWithBlur: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    color: 'rgb(255, 152, 0) !important',

    zIndex: 2,
    backdropFilter: 'blur(3px)',
  },
}));

export default useStyles;
