// React
import React from 'react';

// Material UI
import { Box } from '@material-ui/core';

// Components
import DateElement from './DateElement';
import Event from './Event';

// Styles
import styles from './EventItem.module.scss';

const EventItem = ({ color, openModal, event }) => {
  return (
    <Box className={styles.container}>
      <DateElement start={event.startFormatted}></DateElement>
      <Event color={color} event={event} openModal={openModal}></Event>
    </Box>
  );
};

export default EventItem;
