import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiBottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MuiListItem from '@material-ui/core/ListItem';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: drawerWidth,
    background: '#fff',
  },

  appBarTypography: {
    flexGrow: 1,
    color: '#313539',
  },

  toolbar: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  dashboardNavTab: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  welcome: {
    display: 'flex',
    height: '80vh',
    alignItems: 'center',
    justifyContent: 'center',
  },

  drawerIconItem: {
    display: 'inline-flex',
    minWidth: 56,
    flexShrink: 0,
  },

  drawerTextItem: {
    fontFamily: '"Quicksand", sans-serif',
  },

  homeButton: {
    position: 'absolute',
    bottom: 1,
    width: '100%',
  },
}));

const BottomNavigationAction = withStyles({
  root: {
    maxwidth: 'none',
    color: 'rgba(0, 0, 0, 0.74)',
    '&$selected': {
      backgroundColor: 'rgb(255, 177, 93)',
      color: 'white',
    },
    '&$selected:hover': {
      backgroundColor: 'rgb(255, 145, 28)',
      color: 'white',
      transition: '0.2s ease-in',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
      color: 'white',
      transition: '0.2s ease-in',
    },
  },
  selected: {},
})(MuiBottomNavigationAction);

const ListItem = withStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.74)',
    '&$selected': {
      backgroundColor: 'rgb(255, 177, 93)',
      color: 'white',
    },
    '&$selected:hover': {
      backgroundColor: 'rgb(255, 145, 28)',
      color: 'white',
      transition: '0.2s ease-in',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
      color: 'white',
      transition: '0.2s ease-in',
    },
  },
  selected: {},
})(MuiListItem);

export { useStyles, BottomNavigationAction, ListItem };
