import React, { useState } from "react";
import {DesktopMenu, MobileMenu} from "../components";
import { leftMenuStyle } from "../style";
import "../Navbar.scss";
import { Box, Drawer, ClickAwayListener, Button } from "@material-ui/core";
import BurgerMenuAnimated from "../../BurgerMenuAnimated";

export const MAX_MOBILE_MENU_WIDTH = 1230;

export default function LeftItems(props) {
  const classes = leftMenuStyle();
  const navItems = props.items;

  const anchor = "left"; // SELECT OPENING DIRECTION (top,left,bottom,right)
  const [isOpen, setIsOpen] = useState(true);
  const [direction, setDirection] = useState({ [anchor]: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (open) {
      setDirection({ [anchor]: true });
      setIsOpen(false);
      document.getElementById("nav-icon3").className = "open";
    } else {
      setDirection({ [anchor]: false });
      setIsOpen(true);
      document.getElementById("nav-icon3").className = "";
    }
  };

  if (!props.mobile) {
    return <DesktopMenu classes={classes} navItems={navItems} />;
  } else {
    return (
        <ClickAwayListener onClickAway={toggleDrawer(anchor, false)}>
          <Box>
            <Button onClick={toggleDrawer(anchor, isOpen)} style={{ color: "white" }}>
              <BurgerMenuAnimated />
            </Button>
            <Drawer
              id="drawer-left"
              anchor={anchor}
              open={direction[anchor]}
              onClose={toggleDrawer(anchor, false)}
              className={`drawer-navbar`}
            >
              <MobileMenu
                anchor={anchor}
                classes={classes}
                navItems={navItems}
              />
            </Drawer>
          </Box>
        </ClickAwayListener>
    );
  }
}
