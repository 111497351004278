// React
import React from 'react';

// Material UI
import { Button } from '@material-ui/core';

const CancelButton = ({ fn }) => {
  return (
    <Button
      disableElevation
      disableRipple
      variant="contained"
      style={{
        textTransform: 'none',
        color: '#172126',
        backgroundColor: '#FFFFFF',
        borderRadius: '24px',
        border: '1px solid #E8EBED',
        height: '52px',
        width: '91px',
        padding: '12px 16px',
        fontWeight: 700,
      }}
      onClick={fn}
    >
      Cancel
    </Button>
  );
};

export default CancelButton;
