// React
import React, { useEffect } from 'react';

// Material UI
import { Box } from '@material-ui/core';

// Components
import Category from './components/Category';

// Utils
import { useSnackbar } from 'notistack';
import { getVisibleCategoriesFromCookies } from '../../../../../../utils/calendarUtils';
import {UseCustomSnackbar} from '../../../../../../utils/CustomSnackbar';

// API
import { getCategoriesAPI } from '../../../../../../apis/CalendarAPI';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setCategories, setVisibleCategories } from '../../../../../../store/calendarSlice';

// Styles
import styles from './Categories.module.scss';

const Categories = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.calendar.categories);

  const loadCategories = async (enqueueSnackbar) => {
    try {
      const response = await getCategoriesAPI();
      dispatch(setCategories(response.data));
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }
  };

  useEffect(() => {
    loadCategories(enqueueSnackbar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar]);

  // retrieve visible categories from Cookies and put it inside calendar store
  useEffect(() => {
    const array = getVisibleCategoriesFromCookies();
    categories.forEach((category) => {
      if (category.default && !array.includes(category.calendarName))
        array.push(category.calendarName);
    });
    dispatch(setVisibleCategories(array));
  }, [categories, dispatch]);

  return (
    <Box className={styles.container}>
      <Box className={styles.container__body}>
        {categories.map((el) => {
          return (
            <Category
              category={el.calendarName}
              color={el.color}
              key={el.calendarName}
              hideable={!el.default}
            ></Category>
          );
        })}
      </Box>
    </Box>
  );
};

export default Categories;
