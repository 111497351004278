// React
import React from 'react';

// Components
import CancelButton from '../components/CancelButton';
import SendRequestButton from '../components/SendRequestButton';

// Styles
import styles from './SuggestEventFooter.module.scss';

const SuggestEventFooter = ({ cancelFn, event }) => {
  return (
    <div className={styles.container}>
      <CancelButton fn={cancelFn}></CancelButton>
      <SendRequestButton suggestEvent={event}></SendRequestButton>
    </div>
  );
};

export default SuggestEventFooter;
