import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import { LeftMenu, AccountMenu } from './components';
import { listMenuAPI } from '../../apis/LeftMenuAPI';
import './Navbar.scss';
import { useSnackbar } from 'notistack';
import { navbarStyle } from './style';
import {UseCustomSnackbar} from '../../utils/CustomSnackbar';

export default function Navbar({
  keycloak,
  isAdmin,
  savatarURL,
  rooms,
  addRoom,
  deleteRoom,
  editRoom,
}) {
  const [itemsLeftMenu, setLeftMenu] = useState([]);
  const [navbarWidth, setNavbarWidth] = useState();
  const [leftMenuWidth, setLeftMenuWidth] = useState();
  const [accountMenuWidth, setAccountMenuWidth] = useState();
  const [leftMenuMobile, setLeftMenuMobile] = useState(false);
  const navbarRef = useRef();
  const leftMenuRef = useRef();
  const accountMenuRef = useRef();
  const classes = navbarStyle();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Load the list data
  const loadList = async (enqueueSnackbar, closeSnackbar) => {
    try {
      const response = await listMenuAPI();
      setLeftMenu(response.data);
      setLeftMenuWidth(leftMenuRef.current.offsetWidth);
      setNavbarWidth(navbarRef.current.offsetWidth - 50);
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }
  };

  useEffect(() => {
    loadList(enqueueSnackbar, closeSnackbar);
  }, [enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    function handleResize() {
      setNavbarWidth(navbarRef.current.offsetWidth - 50);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (leftMenuWidth + accountMenuWidth >= navbarWidth || navbarWidth <= 1350) setLeftMenuMobile(true);
    else setLeftMenuMobile(false);
  }, [accountMenuWidth, leftMenuWidth, navbarWidth]);

  const onAccountMenuLoad = useCallback(async () => {
    setAccountMenuWidth(accountMenuRef.current.offsetWidth);
  }, []);

  return (
    <div className={classes.grow}>
      <AppBar ref={navbarRef} className={classes.appBar} position="fixed" elevation={3}>
        <Toolbar>
          <div ref={leftMenuRef}>
            <LeftMenu items={itemsLeftMenu} mobile={leftMenuMobile} />
          </div>
          <div className={classes.grow} />
          <div ref={accountMenuRef}>
            <AccountMenu
              edge="end"
              fullKeycloak={keycloak}
              isAdmin={isAdmin}
              savatarURL={savatarURL}
              rooms={rooms}
              addRoom={addRoom}
              deleteRoom={deleteRoom}
              editRoom={editRoom}
              isDashboard={false}
              onLoad={onAccountMenuLoad}
            />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
