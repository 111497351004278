// React
import React from 'react';

// Material UI
import { createTheme, ThemeProvider, Radio } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

// Utils
import DateFnsUtils from '@date-io/date-fns';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setEnding, setEndingDate } from '../../../../../../../store/customizeRepeatSlice';

const EndingOn = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#F17023',
      },
    },
    overrides: {
      MuiPaper: {
        rounded: {
          borderRadius: '10px',
        },
      },
      MuiInputBase: {
        input: {
          cursor: 'pointer',
          textAlign: 'center',
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
      },
      MuiPickersToolbarText: {
        toolbarTxt: {
          color: 'white',
        },
        toolbarBtnSelected: {
          color: 'white',
        },
      },
      MuiFormControl: {
        root: {
          padding: '6px 10px 6px 10px',
          borderRadius: '8px',
          border: '1px solid #E8EBED',
          '&:hover': {
            border: '1px solid #98A6AE',
          },
        },
      },
      MuiPickersDay: {
        daySelected: {
          color: 'white',
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          display: 'none !important',
        },
      },
      MuiTypography: {
        body1: {
          fontSize: '16px',
        },
      },
      MuiPickersCalendarHeader: {
        daysHeader: {
          justifyContent: 'center',
          '&>*:nth-child(1)': {
            color: '#FAB97A',
          },
        },
        dayLabel: {
          color: '#C6CED2',
          fontSize: '14px',
        },
        iconButton: {
          padding: 0,
          '&>span svg path': {
            color: '#F17023',
          },
        },
        switchHeader: {
          marginTop: 'none',
          marginBottom: '21px',
          justifyContent: 'center',
        },
        transitionContainer: {
          height: '22px',
          maxWidth: '130px',
        },
      },
      MuiPickersCalendar: {
        week: {
          justifyContent: 'center',
          '&>*:nth-child(1) button span p': {
            color: '#FAB97A !important',
          },
          '&>div span button span p': {
            fontWeight: 500,
          },
        },
      },
      MuiRadio: {
        root: {
          color: '#C6CED2',
        },
        colorSecondary: {
          '&.Mui-checked': {
            color: '#F17023',
          },
        },
      },
    },
  });

  const ending = useSelector((state) => state.customizeRepeat.ending);
  const endingDate = useSelector((state) => state.customizeRepeat.endingDate);
  const dispatch = useDispatch();

  const handleEndingChange = (e) => {
    dispatch(setEnding(e.target.value));
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '8px',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: '8px',
          }}
        >
          <Radio checked={ending === 'false'} onChange={handleEndingChange} value="false" />
          <span>Never</span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: '8px',
          }}
        >
          <Radio checked={ending === 'true'} onChange={handleEndingChange} value="true" />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={theme}>
              <DatePicker
                disabled={ending === 'false'}
                value={endingDate}
                format="EEE dd MMM yyyy"
                onChange={(value) => dispatch(setEndingDate(value))}
                animateYearScrolling
                style={{ maxWidth: 170 }}
                minDate={new Date()}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default EndingOn;
