// React
import React from 'react';

// Material UI
import {
  createTheme,
  ThemeProvider,
  FormControlLabel,
  withStyles,
  Switch,
} from '@material-ui/core';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setIsAllDay } from '../../../../../../../store/suggestEventSlice';

const SwitchToggle = withStyles((theme) => ({
  root: {
    width: 47,
    height: 26,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#C6CED2',
    '&$checked': {
      transform: 'translateX(21px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#F17023',
        borderColor: '#F17023',
      },
    },
  },
  thumb: {
    width: 22,
    height: 22,
    boxShadow: 'none',
  },
  track: {
    border: '1px solid #E8EBED',
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const AllDaySwitchToggle = () => {
  const theme = createTheme({
    overrides: {
      MuiFormControlLabel: {
        root: {
          marginRight: 0,
          marginLeft: '0px',
          marginTop: '4px',
        },
      },
      PrivateSwitchBase: {
        root: {
          padding: '4px 2px',
        },
      },
      MuiTypography: {
        body1: {
          minWidth: '52px',
          marginLeft: '10px',
        },
      },
    },
  });

  const isAllDay = useSelector((state) => state.suggestEvent.isAllDay);
  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={theme}>
      <FormControlLabel
        control={
          <SwitchToggle
            checked={isAllDay}
            onChange={(e) => {
              dispatch(setIsAllDay(e.target.checked));
            }}
          ></SwitchToggle>
        }
        label="All day"
      />
    </ThemeProvider>
  );
};

export default AllDaySwitchToggle;
