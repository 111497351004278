import React from "react";
import ReactDOMServer from "react-dom/server";
import Item from "./components/Item";
import { updateListToolsAPI } from "../../../../apis/ToolAPI";

export function insertItemsTools(itemsTools, grid) {
  
  itemsTools.forEach((item) => {
    const elem = ReactDOMServer.renderToString(
      <a className="tag-a-tool" href={item.url} target="_blank" rel="noreferrer" id={item.toolName}>
        <Item {...item} key={item.id} grid={grid} />
      </a>
    );
    grid.addWidget({ content: elem, autoPosition: true, id: item.id });
  });
}

export const MAX_NUM_ITEMS_IN_ROW = Math.round(document.body.clientWidth / 170) > 12 ? 12 : Math.round(document.body.clientWidth / 170);

export function setGridOptions(numFavorite) {
  const widthDevice = document.body.clientWidth;
  const halfHeightGrid1 = widthDevice > 760 ? 180 : 150;

  const optsGeneral = {
    dragOut: true,
    float: false,
    dragIn: true,
    cellHeightUnit: "px",
    acceptWidgets: true,
    disableResize: true,
    disableOneColumnMode: "grid-stack-one-column-mode",
    handle: ".grid-stack-item-content",
  };

  const opts_empty = {
    ...optsGeneral,
    staticGrid: true,
    cellHeight: halfHeightGrid1,
    row: widthDevice < 1030 ? 2 : 1,
    column: widthDevice < 1030 ? numFavorite / 2 : numFavorite,
  };

  const opts1 = {
    ...optsGeneral,
    cellHeight: halfHeightGrid1,
    row: widthDevice < 1030 ? 2 : 1,
    column: widthDevice < 1030 ? numFavorite / 2 : numFavorite,
  };

  const opts2 = {
    ...optsGeneral,
    cellHeight: 100,
    column: MAX_NUM_ITEMS_IN_ROW,
  };

  return { optsGeneral, opts_empty, opts1, opts2 };
}

export function resizeGrid(grid_empty, grid1, grid2) {
  let layout = "moveScale";
  const maxNumItemsInRow = Math.round(document.body.clientWidth / 170) > 12 ? 12 : Math.round(document.body.clientWidth / 170);
  let widthDeviceResize = document.body.clientWidth;
  grid_empty.column(widthDeviceResize < 1030 ? 3 : 6, layout);
  grid1.column(widthDeviceResize < 1030 ? 3 : 6, layout);
  grid2.column(maxNumItemsInRow, layout);
  grid_empty.compact();
  grid1.compact();
  grid2.compact();
}

export function saveUserListTools(grid1, grid2) {
  try {
    const updateList = async (data) => {
      try {
        await updateListToolsAPI(data)
      } catch (err) {
        console.error("Error: ", err);
      }
    };
    let serializedData1 = [];
    let serializedData2 = [];
    grid1.save().forEach((element) => serializedData1.push(element.id));
    grid2.save().forEach((element) => serializedData2.push(element.id));
    for (let i = serializedData1.length; i < 6; i++) serializedData1.push("");
    let updateToolsList = [...serializedData1, ...serializedData2].toString();
    updateList(updateToolsList);
  } catch (e) {
    console.error("Error: ", e);
  }
}
