import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  iconColumn: {
    width: 150,
  },

  icon: {
    margin: '0 8px',
  },

  newUsersIcon: {
    fontSize: 20,
    marginLeft: 15,
    verticalAlign: 'middle',
  },
}));

export default useStyles;