// React
import React, { useState, useEffect } from 'react';

// Material UI
import Box from '@material-ui/core/Box';

// Components
import Navbar from '../../components/Navbar/Navbar';
import SorintTools from './components/SorintTools/SorintTools';
import Search from './components/Search/Search';
import Doodle from './components/Doodle/Doodle.js';
import ShowInRooms from './components/ShowInRooms/ShowInRooms.js';
import Calendar from './components/Calendar/Calendar';

// Redux
import { useSelector } from 'react-redux';

// API
import { getRoomsAPI, addRoomAPI, deleteRoomAPI, editRoomAPI } from '../../apis/ShowInAPI';

// Utils
import { useSnackbar } from 'notistack';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {UseCustomSnackbar} from '../../utils/CustomSnackbar';

// Styles
import { useStyles } from './style';

const Homepage = ({ keycloak, isAdmin, searchURL, savatarURL, userInfo }) => {
  const isCalendarVisible = useSelector((state) => state.calendar.isCalendarVisible);

  const { trackPageView, trackEvent, pushInstruction } = useMatomo();

  // MATOMO TEST
  useEffect(() => {
    trackPageView();

    // get user id
    pushInstruction('setUserId', userInfo.sub);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  const [showInRooms, setRooms] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const loadRooms = async (enqueueSnackbar, closeSnackbar) => {
    try {
      const response = await getRoomsAPI();
      setRooms(response.data);
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }
  };

  const addRoom = async (roomNumber, roomName, roomUrl) => {
    // MATOMO TEST
    trackEvent({ category: 'add-room', action: 'click-event' });
    // MATOMO TEST

    let data = {
      number: roomNumber,
      name: roomName,
      link: roomUrl,
    };
    try {
      await addRoomAPI(data);
      loadRooms(enqueueSnackbar);
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error , 'error');
    }
  };

  const deleteRoom = async (roomNumber) => {
    // MATOMO TEST
    trackEvent({ category: 'delete-room', action: 'click-event' });
    // MATOMO TEST

    try {
      await deleteRoomAPI(roomNumber);
      loadRooms(enqueueSnackbar);
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }
  };

  const editRoom = async (roomNumber, roomName, roomUrl) => {
    // MATOMO TEST
    trackEvent({ category: 'edit-room', action: 'click-event' });
    // MATOMO TEST

    let data = {
      name: roomName,
      link: roomUrl,
    };
    try {
      await editRoomAPI(roomNumber, data);
      loadRooms(enqueueSnackbar);
    } catch (error) {
      UseCustomSnackbar(enqueueSnackbar, closeSnackbar, error, 'error');
    }
  };

  useEffect(() => {
    loadRooms(enqueueSnackbar, closeSnackbar);
  }, [enqueueSnackbar, closeSnackbar]);

  return (
    <div className="app">
      <div className="container">
        <Navbar
          keycloak={keycloak}
          isAdmin={isAdmin}
          savatarURL={savatarURL}
          rooms={showInRooms}
          addRoom={(roomNumber, roomName, roomUrl) => addRoom(roomNumber, roomName, roomUrl)}
          deleteRoom={(roomNumber) => deleteRoom(roomNumber)}
          editRoom={(roomNumber, roomName, roomUrl) => editRoom(roomNumber, roomName, roomUrl)}
        />
        {isCalendarVisible ? (
          <Calendar></Calendar>
        ) : (
          <Box display="flex" flexDirection="column" minHeight="90vh">
            <Box id="boxDoodleSearch" className={classes.boxDoodleSearch}>
              <Doodle />
              <Search searchURL={searchURL} keycloak={keycloak} savatarURL={savatarURL} />
            </Box>
            <ShowInRooms
              rooms={showInRooms}
              addRoom={(roomNumber, roomName, roomUrl) => addRoom(roomNumber, roomName, roomUrl)}
              deleteRoom={(roomNumber) => deleteRoom(roomNumber)}
              editRoom={(roomNumber, roomName, roomUrl) => editRoom(roomNumber, roomName, roomUrl)}
            />
            <SorintTools />
          </Box>
        )}
      </div>
    </div>
  );
};
export default Homepage;
