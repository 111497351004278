// React
import React from 'react';

// Material UI
import { IconButton } from '@material-ui/core';

// Icons
import { Close } from '@material-ui/icons';

// Redux
import { useDispatch } from 'react-redux';
import { setIsCalendarVisible } from '../../../../../store/calendarSlice';

const BackButton = () => {
  const dispatch = useDispatch();

  return (
    <IconButton disableRipple onClick={() => dispatch(setIsCalendarVisible(false))}>
      <Close></Close>
    </IconButton>
  );
};

export default BackButton;
