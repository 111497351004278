// React
import React, { useState, useEffect } from 'react';

// Material UI
import { Box, Switch } from '@material-ui/core';

// Components
import CategoryItem from './CategoryItem';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setVisibleCategories } from '../../../../../../../store/calendarSlice';

// Utils
import Cookies from 'js-cookie';

// Styles
import styles from './Category.module.scss';
import { withStyles } from '@material-ui/styles';

const Category = ({ category, color, hideable }) => {
  const dispatch = useDispatch();

  const visibleCategories = useSelector((state) => state.calendar.visibleCategories);

  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (hideable) {
      setHidden(!visibleCategories.includes(category));
    }
  }, [category, hideable, visibleCategories, dispatch]);

  useEffect(() => {
    Cookies.set('visible_categories', visibleCategories.join(';'), { expires: 365 });
  }, [visibleCategories]);

  const handleCategoryVisibility = () => {
    if (hideable) {
      if (!hidden) {
        dispatch(setVisibleCategories(visibleCategories.filter((cat) => cat !== category)));
        setHidden(true);
      } else {
        dispatch(setVisibleCategories([...visibleCategories, category]));
        setHidden(false);
      }
    }
  };

  const SwitchToggle = withStyles((theme) => ({
    root: {
      width: 47,
      height: 26,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: '#C6CED2',
      '&$checked': {
        transform: 'translateX(21px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#F17023',
          borderColor: '#F17023',
        },
      },
    },
    thumb: {
      width: 22,
      height: 22,
      boxShadow: 'none',
    },
    track: {
      border: '1px solid #E8EBED',
      borderRadius: 26 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  return (
    <Box className={styles.container}>
      <CategoryItem category={category} color={color}></CategoryItem>
      <Box className={styles.container__right}>
        {hideable && <SwitchToggle checked={!hidden} onChange={handleCategoryVisibility} />}
      </Box>
    </Box>
  );
};

export default Category;
