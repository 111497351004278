import { makeStyles, withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: 25,
  },
  iconList: {
    minWidth: 35,
  },
  hitDescription: {
    color: grey[600],
    marginBottom: '0.5em',
    display: 'block',
    fontStyle: 'italic',
  },
  listInfoPerson: {
    width: '100%',
    padding: 0,
  },
  resultPaper: {
    display: 'flex',
    margin: 20,
    padding: 20,
    maxwidth: 960,
  },
  btnAvailability: {
    border: 'none',
    width: '100%',
    height: '100%',
    background: 'none',
  },
  fabProgress: {
    display: 'flex',
    color: 'rgb(255, 152, 0) !important',
  },
  iconSuccess: {
    color: 'green',
  },
  iconWarning: {
    color: 'rgb(240, 180, 15)',
  },
  iconDanger: {
    color: 'red',
  },
  divIconPlay: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    top: '50%',
  },
  iconPlayOverImg: {
    position: 'absolute',
    margin: 5,
    fontSize: 50,
    backgroundColor: 'rgba(255,255,255,0.3)',
    borderRadius: '50%',
    color: 'rgba(255,255,255, 0.85)',
    filter: 'drop-shadow(5px 5px 2px rgba(51, 53, 56, 0.9))',
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 35,
    top: 80,
    height: '10px !important',
    width: 10,
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}))(Badge);

export { useStyles, StyledBadge };
