import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-end',
      flexDirection: 'row',
    },

    '& .MuiInputBase-root.Mui-disabled': {
      color: 'black',
    },
  },

  title: {
    marginTop: 10,

    [theme.breakpoints.up('lg')]: {
      width: '30%',
    },
  },

  url: {
    flexGrow: 1,
    marginTop: 10,

    [theme.breakpoints.up('lg')]: {
      margin: '0 10px',
      width: 'auto',
    },
  },

  date: {
    flexShrink: 0,
    marginTop: 10,

    [theme.breakpoints.up('lg')]: {
      width: 130,
    },
  },

  description: {
    marginTop: 10,
  },

  switch: {
    flexShrink: 0,
    marginTop: 10,

    [theme.breakpoints.up('lg')]: {
      marginLeft: 10,
    },
  },
}));

export default useStyles;